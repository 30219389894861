import React, { useState, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";

const TotalSales = ({ data, containerDimension }) => {
  //container dimension
  const PaddedContainerHeight = containerDimension.height - 40;
  const PaddedContainerWidth = containerDimension.width - 40;

  // let reversedLabels = [...data.x_labels].reverse();
  // const reversedCurrentMonthData = [...data.current_month_data].reverse();
  // const reversedPreviousMonthData = [...data.previous_month_data].reverse();

  const [sortedData, setSortedData] = useState({
    x_labels: [],
    current_month_data: [],
    previous_month_data: [],
  });

  useEffect(() => {
    const combinedData = data.x_labels.map((label, index) => ({
      label,
      currentMonth: data.current_month_data[index],
      previousMonth: data.previous_month_data[index],
    }));

    combinedData.sort((a, b) => new Date(a.label) - new Date(b.label));

    setSortedData({
      x_labels: combinedData.map((item) => item.label),
      current_month_data: combinedData.map((item) => item.currentMonth),
      previous_month_data: combinedData.map((item) => item.previousMonth),
    });
  }, [data]);

  const [open, setOpen] = useState(false);
  // const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490, 4345, 4342, 3432, 2322];
  // const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300, 4345, 4342, 322, 1000];
  // const xLabels = [
  //   "Page A",
  //   "Page B",
  //   "Page C",
  //   "Page D",
  //   "Page E",
  //   "Page F",
  //   "Page G",
  //   "Page H",
  //   "Page I",
  //   "Page J",
  //   "Page K",
  // ];
  return (
    <>
      <div
        className="text-center mt-[20px]"
        onClick={() => {
          setOpen(true);
        }}
      >
        Sales
        <LineChart
          width={PaddedContainerWidth}
          height={PaddedContainerHeight}
          sx={{ padding: "10px" }}
          series={[
            {
              data: sortedData.previous_month_data,
              label: "Previous Month",
              showMark: ({ index }) => index % 1 !== 0,
              color: "#ff9900",
            },
            {
              data: sortedData.current_month_data,
              label: "Current Month",
              showMark: ({ index }) => index % 1 !== 0,
              color: "#277494",
            },
          ]}
          xAxis={[{ scaleType: "point", data: sortedData.x_labels }]}
        />
      </div>
      <CustomModal
        isOpen={open}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <LineChart
          width={580}
          height={500}
          sx={{ padding: "20px" }}
          series={[
            {
              data: sortedData.current_month_data,
              label: "Current Month",
              showMark: ({ index }) => index % 1 !== 0,
              color: "#277494",
            },
            {
              data: sortedData.previous_month_data,
              label: "Previous Month",
              showMark: ({ index }) => index % 1 !== 0,
              color: "#ff9900",
            },
          ]}
          xAxis={[{ scaleType: "point", data: sortedData.x_labels }]}
        />
      </CustomModal>
    </>
  );
};

export default TotalSales;
