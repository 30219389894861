import React, { useState, useEffect } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";

import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch } from "react-redux";
import { createProduct, searchProducts } from "../../../reducers/Slices/productSlice";
import { debounce } from "lodash";

//Micro Components
import IconWrapper from "../../MicroComponents/IconWrapper";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";

//UI Components
import { Search, X as Cross } from "react-feather";
import { useHotkeys } from "react-hotkeys-hook";
import toast from "react-hot-toast";
import DurationInput from "./DurationInput";
import BarcodeInput from "./BarcodeInput";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AddProduct = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const product_name = query.get("product_name");

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Convert days, hours and minutes into seconds
  const [totalSeconds, setTotalSeconds] = useState(0);

  useEffect(() => {
    if (product_name) {
      setSearchTerm(product_name);
      navigate("/inventory", { replace: true });
    }
  }, [product_name]);

  const [showModal, setShowModal] = useState(false);

  const [addProductFormData, setAddProductFormData] = useState({
    product_name: "",
    brand: "",
    hsn_number: "",
    package: "",
    is_service: "N", // Default to 'N'
    rate: 0,
    cost: 0,
    duration_time: totalSeconds,
    bar_code_number: null,
    tax: 0,
    mrp: 0,
    batches: [],
  });

  //Side effect to updated formdata based on totalSeconds
  useEffect(() => {
    setAddProductFormData((prevState) => {
      return { ...prevState, duration_time: totalSeconds };
    });
  }, [totalSeconds]);

  const dispatch = useDispatch();

  //function for add item click
  const handleAddItemClick = () => {
    setShowModal(true);
    setTotalSeconds(0);
  };

  //Hot key to add items in inventory
  useHotkeys("Ctrl + Alt + P, Ctrl + Option + P", () => setShowModal(!showModal));

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    // Guard - check required fields
    if (
      !addProductFormData?.product_name ||
      !addProductFormData?.brand ||
      !addProductFormData?.package ||
      !addProductFormData?.hsn_number ||
      (addProductFormData.is_service === "Y" && (!addProductFormData.rate || !addProductFormData.mrp))
    ) {
      toast.error("please fill all field values in form!");
      setDisableSubmitButton(false);
      return;
    }

    console.log(addProductFormData, "this is the form data");
    setDisableSubmitButton(false);
    dispatch(createProduct(addProductFormData))
      .then((action) => {
        if (action.payload) {
          setAddProductFormData({});
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          toast.error("Sorry, product cannot be added");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(`Error Occured: " ${err}`);
        setDisableSubmitButton(false);
      });
  };

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect for barcode.
  useEffect(() => {
    setAddProductFormData((prevState) => {
      return { ...prevState, bar_code_number: barcodeNumber };
    });
  }, [barcodeNumber]);

  //Side effect to set these fields back to empty on is Service false
  useEffect(() => {
    if (addProductFormData.is_service === "N") {
      setAddProductFormData((prevState) => {
        return { ...prevState, rate: 0, purchase_price: 0, duration_time: 0, tax: 0, mrp: 0 };
      });
    }
  }, [addProductFormData.is_service]);

  //Search functionality
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);

  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  useEffect(() => {
    dispatch(searchProducts(searchTerm));
  }, [searchTerm, dispatch]);

  const handleClose = () => {
    setAddProductFormData({});
    setShowModal(false);
  };

  const handleCloseSearch = () => {
    setSearchTerm("");
    setShowSearch(false);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center">Product description</div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="Product-name" className="text-neutral-500">
              Product / Service Name*
            </label>
            <input
              type="text"
              id="Product-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.product_name}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, product_name: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="brand-name" className="text-neutral-500">
              Brand / Company Name*
            </label>
            <input
              type="text"
              id="brand-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.brand}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, brand: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              HSN Number*
            </label>
            <input
              type="text"
              id="hsn-number"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.hsn_number}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, hsn_number: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="product-package" className="text-neutral-500">
              Product Packaging / Service Type*
            </label>
            <input
              type="text"
              id="product-package"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.package}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, package: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div>

          {/* is_service field */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="is-service" className="text-neutral-500">
              Is Service*
            </label>
            <select
              id="is-service"
              className="border-[1px] border-neutral-500 bg-white px-[10px] py-[10px]"
              value={addProductFormData.is_service}
              onChange={(e) => {
                setAddProductFormData({ ...addProductFormData, is_service: e.target.value });
              }}
              required
            >
              <option value="N">No</option>
              <option value="Y">Yes</option>
            </select>
          </div>

          {/* Conditionally render extra fields if is_service is "Y" */}
          {addProductFormData.is_service === "Y" && (
            <>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="mrp-price" className="text-neutral-500">
                  MRP Price*
                </label>
                <input
                  type="number"
                  id="mrp-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData.mrp}
                  onChange={(e) => {
                    setAddProductFormData({ ...addProductFormData, mrp: parseFloat(e.target.value) });
                  }}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="sales-price" className="text-neutral-500">
                  Rate*
                </label>
                <input
                  type="number"
                  id="sales-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData.rate}
                  onChange={(e) => {
                    setAddProductFormData({ ...addProductFormData, rate: parseFloat(e.target.value) });
                  }}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="purchase-price" className="text-neutral-500">
                  Service Cost to Business / Expense*
                </label>
                <input
                  type="number"
                  id="purchase-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData.cost}
                  onChange={(e) => {
                    setAddProductFormData({ ...addProductFormData, cost: parseFloat(e.target.value) });
                  }}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="tax-percentage" className="text-neutral-500">
                  Tax %*
                </label>
                <input
                  type="number"
                  id="tax-percentage"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData.tax}
                  onChange={(e) => {
                    setAddProductFormData({ ...addProductFormData, tax: parseFloat(e.target.value) });
                  }}
                  required
                />
              </div>
              {/* For Barcode  */}
              <div className="relative">
                <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
              </div>
              <DurationInput totalSeconds={totalSeconds} setTotalSeconds={setTotalSeconds} />
            </>
          )}

          <div className="flex justify-center gap-[20px]">
            <button
              className="save bg-black px-[20px] py-[10px] text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>

      {/* Search Bar and Add Product button  */}
      <div className="flex justify-between mb-[20px]">
        {!showSearch ? (
          <button onClick={() => setShowSearch(true)}>
            <IconWrapper>
              <Search className="text-white z-10" />
            </IconWrapper>
          </button>
        ) : (
          <form class="flex items-center justify-between w-full gap-2">
            <label for="simple-search" class="sr-only">
              Search
            </label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={(e) => debouncedSetSearchTerm(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Search product name..."
                required
              />
            </div>
            <button onClick={handleCloseSearch}>
              <IconWrapper>
                <Cross className="text-white z-10" />
              </IconWrapper>
            </button>
          </form>
        )}

        {/* When Search Bar not open show Add Product Button */}
        {!showSearch && (
          <ButtonWrapper type="submit" eventCallback={handleAddItemClick}>
            + Add Product
          </ButtonWrapper>
        )}
      </div>
    </>
  );
};

export default AddProduct;
