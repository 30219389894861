import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//Fetch Cash Register Entries
export const fetchCashRegisterEntries = createAsyncThunk(
  "cashRegister/fetchCashRegisterEntries",
  async (data, extra) => {
    const res = await http.get(`/cash_register/${data ?? ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Fetch more cash register entries - for react infinity scroll
export const fetchMoreCashRegisterEntries = createAsyncThunk(
  "cashRegister/fetchMoreCashRegisterEntries",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

// create cash registery
export const createCashRegistery = createAsyncThunk("cashRegister/createCashRegistery", async (data, extra) => {
  const response = await http.post("/cash_register/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// update cash registery
export const updateCashRegistery = createAsyncThunk("cashRegister/updateCashRegistery", async (data, extra) => {
  const response = await http.put(`/cash_register/${data.id}/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// delete cash register
export const deleteCashRegistery = createAsyncThunk("cashRegister/deleteCashRegistery", async (data, extra) => {
  const response = await http.delete(`/cash_register/${data}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.status === 204) {
    return data;
  }
});

//Get Cash Register Analytics
export const getCashRegisterAnalytics = createAsyncThunk(
  "cashRegister/getCashRegisterAnalytics",
  async (data, extra) => {
    const response = await http.get(`/cash_register/today_totals/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.data.status === "success") {
      return response.data.data;
    }
  }
);

//GET - /cash_register/<id here>/ -- get cash register by ID, currently not needed but here incase

// cash register slice
const cashRegisterSlice = createSlice({
  name: "cashRegister",
  initialState: {
    cash_register: { data: [], next: null },
    cash_analytics: { total_payments: 0, total_receipts: 0, net_value: 0 },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCashRegisterEntries.fulfilled, (state, action) => {
      return {
        ...state,
        cash_register: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreCashRegisterEntries.fulfilled, (state, action) => {
      return {
        ...state,
        cash_register: { data: [...state.cash_register.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(createCashRegistery.fulfilled, (state, action) => {
      return {
        ...state,
        cash_register: { ...state.cash_register, data: [...state.cash_register.data, action.payload] },
      };
    });
    builder.addCase(updateCashRegistery.fulfilled, (state, action) => {
      return {
        ...state,
        cash_register: {
          ...state.cash_register,
          data: state.cash_register.data.map((cash_register_entry) =>
            cash_register_entry.id === action.payload.id ? action.payload : cash_register_entry
          ),
        },
      };
    });
    builder.addCase(deleteCashRegistery.fulfilled, (state, action) => {
      return {
        ...state,
        cash_register: {
          ...state.cash_register,
          data: state.cash_register.data.filter((cash_register_entry) => cash_register_entry.id !== action.payload),
        },
      };
    });
    builder.addCase(getCashRegisterAnalytics.fulfilled, (state, action) => {
      return {
        ...state,
        cash_analytics: action.payload,
      };
    });
  },
});

export default cashRegisterSlice.reducer;
