import React from "react";
// import "./Transaction.scss";
// import TopSection from "./TransactionComponent/TopSection";
import InvoiceContainer from "./TransactionComponent/InvoiceContainer";

const PurchaseBook = () => {
  return (
    <div className="p-[10px]">
      {/* pHanvier - TODO - remove this if not needed? What was it's purpose  */}
      {/* <div className="transaction-main-top"><TopSection infoType={"vendor"}/></div> */}
      <div className="border-2 border-neutral-200 rounded-[20px] p-[20px]">
        <InvoiceContainer infoType={"vendor"} />
      </div>
    </div>
  );
};

export default PurchaseBook;
