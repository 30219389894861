import React, { useEffect, useState } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import { useDispatch } from "react-redux";
import { createExpense } from "../../../reducers/Slices/expenseSlice";
import toast from "react-hot-toast";

function AddExpense() {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Get today's date for default value of date
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [formData, setFormData] = useState({
    date: getTodayDate(),
    expense_name: "",
    cost: 0,
    paid_to: "",
    category: "Rent",
    note: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    handleAddExpense();
  };

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  const handleAddExpense = () => {
    setDisableSubmitButton(true);
    dispatch(createExpense(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Expense creation failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const resetInitialValue = () => {
    const initialValues = {
      date: "",
      expense_name: "",
      cost: 0,
      paid_to: "",
      category: "",
      note: "",
    };
    setFormData(initialValues);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
  }, [showModal]);

  const handleAddExpenseClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <div className="mb-5">
                    <label htmlFor="expense_name" className="block mb-2 text-sm font-medium text-gray-900">
                      Expense Name*
                    </label>
                    <input
                      type="text"
                      id="expense_name"
                      onChange={(e) => setFormData({ ...formData, expense_name: e.target.value })}
                      value={formData.expense_name}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter expense name"
                      required
                    />
                  </div>
                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                    Date*
                  </label>
                  <input
                    type="date"
                    id="date"
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    value={formData.date}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="cost" className="block mb-2 text-sm font-medium text-gray-900">
                    Cost*
                  </label>
                  <input
                    type="number"
                    id="cost"
                    value={formData.cost}
                    onChange={(e) => setFormData({ ...formData, cost: parseFloat(e.target.value) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter cost"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="paid_to" className="block mb-2 text-sm font-medium text-gray-900">
                    Paid To*
                  </label>
                  <input
                    type="text"
                    id="paid_to"
                    value={formData.paid_to}
                    onChange={(e) => setFormData({ ...formData, paid_to: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter payee"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900">
                    Category*
                  </label>
                  <input
                    id="category"
                    value={formData.category}
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  ></input>
                </div>
                <div className="mb-5">
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                    Note
                  </label>
                  <textarea
                    id="note"
                    rows="4"
                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.note}
                    placeholder="Enter note"
                  ></textarea>
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Expense
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <ButtonWrapper eventCallback={handleAddExpenseClick}>Add Expense</ButtonWrapper>
    </>
  );
}

export default AddExpense;
