import React, { useState, useEffect } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
// import { updateExpense } from "../../../reducers/Slices/expenseSlice";
import { getCashRegisterAnalytics, updateCashRegistery } from "../../../reducers/Slices/cashRegisterSlice";

function EditCashRegistery({ cashRegistery }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    id: cashRegistery?.id || 0,
    date: cashRegistery?.date || "",
    name: cashRegistery?.name || "",
    amount: cashRegistery?.amount || 0,
    type: cashRegistery?.type || "payment", // Default type set to "payment"
    note: cashRegistery?.note || "",
  });
  const [showModal, setShowModal] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // Reset cash registery values on change
  useEffect(() => {
    setFormData({
      id: cashRegistery?.id,
      date: cashRegistery?.date,
      name: cashRegistery?.name,
      amount: cashRegistery?.amount,
      type: cashRegistery?.type || "payment",
      note: cashRegistery?.note,
    });
  }, [cashRegistery]);

  const handleSubmit = () => {
    handleEditCashRegistery();
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleEditCashRegistery = () => {
    setDisableSubmitButton(true);
    dispatch(updateCashRegistery(formData))
      .then((action) => {
        if (action.payload) {
          dispatch(getCashRegisterAnalytics());
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Cash registery entry editing failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleEditCashRegisteryClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                    Date*
                  </label>
                  <input
                    type="date"
                    id="date"
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    value={formData.date}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                    Name*
                  </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    value={formData.name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter name"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
                    Amount*
                  </label>
                  <input
                    type="number"
                    id="amount"
                    value={formData.amount}
                    onChange={(e) => setFormData({ ...formData, amount: parseFloat(e.target.value) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter amount"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900">
                    Type*
                  </label>
                  <select
                    id="type"
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  >
                    <option value="payment">Payment</option>
                    <option value="receipt">Receipt</option>
                  </select>
                </div>
                <div className="mb-5">
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                    Note
                  </label>
                  <textarea
                    id="note"
                    rows="4"
                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.note}
                    placeholder="Enter note"
                  ></textarea>
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Cash Registery
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        className="py-4 border-2 border-black h-[44px] w-full flex items-center justify-center rounded-full"
        onClick={handleEditCashRegisteryClick}
      >
        Edit Entry
      </button>
    </>
  );
}

export default EditCashRegistery;
