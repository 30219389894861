import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import axios from "axios";

// fetchExpenses - fetch all expenses
export const fetchExpenses = createAsyncThunk("expenses/fetchExpenses", async (data, extra) => {
  const response = await http.get(`/expense${data ?? "/"}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data;
  }
});

export const fetchMoreExpenses = createAsyncThunk("expenses/fetchMoreEmployees", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// createExpense - create expense
export const createExpense = createAsyncThunk("expenses/createExpense", async (data, extra) => {
  const response = await http.post("/expense/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// updateExpense - update expense based on id
export const updateExpense = createAsyncThunk("expense/updateExpense", async (data, extra) => {
  const response = await http.put(`/expense/${data.id}/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// deleteExpense - delete expense based on id
export const deleteExpense = createAsyncThunk("expense/deleteExpense", async (data, extra) => {
  const response = await http.delete(`/expense/${data.id}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.status === 204) {
    return data;
  }
});

const expenseSlice = createSlice({
  name: "expense",
  initialState: {
    expenses: { data: [], next: "" },
  },
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(fetchEmployees.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     employees: action.payload,
    //   };
    // });
    builder.addCase(fetchExpenses.fulfilled, (state, action) => {
      return {
        ...state,
        expenses: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreExpenses.fulfilled, (state, action) => {
      return {
        ...state,
        expenses: { data: [...state.expenses.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(createExpense.fulfilled, (state, action) => {
      return {
        ...state,
        expenses: { ...state.expenses, data: [...state.expenses.data, action.payload] },
      };
    });
    builder.addCase(updateExpense.fulfilled, (state, action) => {
      return {
        ...state,
        expenses: {
          ...state.expenses,
          data: state.expenses.data.map((expense) => (expense.id === action.payload.id ? action.payload : expense)),
        },
      };
    });
    builder.addCase(deleteExpense.fulfilled, (state, action) => {
      return {
        ...state,
        expenses: {
          ...state.expenses.data,
          data: state.expenses.data.filter((expense) => expense.id !== action.payload.id),
        },
      };
    });
  },
});

export default expenseSlice.reducer;
