// import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors, fetchMoreVendors } from "../../reducers/Slices/vendorSlice";
import { createPurchaseDemand } from "../../reducers/Slices/purchaseInvoiceSlice";
// import { fetchProducts } from "../../reducers/Slices/productSlice";
// import { createInvoice } from "../../reducers/Slices/invoiceSlice";

//UI Components
import IconWrapper from "../MicroComponents/IconWrapper";
import { X as Cross } from "react-feather";
import toast from "react-hot-toast";

function CreatePurchaseDemand() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [items, setItems] = useState([{}]);
  const params = useParams();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  useEffect(() => {
    dispatch(fetchVendors());
  }, []);

  //Fetch both customer and vendor based on ID provided
  const vendorsNext = useSelector((state) => state.vendor.vendors.next);
  const vendor = useSelector((state) => state.vendor.vendors.data.find((v) => v.id === parseInt(params.id)));

  //Fetch customers till end
  useEffect(() => {
    if (vendorsNext) {
      dispatch(fetchMoreVendors(vendorsNext));
    }
  }, [vendorsNext, dispatch]);

  //   const products = useSelector((state) => state.product.products.data);
  // const [userType, setUserType] = useState((type) => (parseInt(params.type) === 1 ? "vendor" : "customer"));

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return { vendor: parseInt(params.id) };
  });

  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();

  //   //Payment Options
  //   const paymentOptions = [
  //     { label: "Cash", value: "cash" },
  //     { label: "UPI", value: "upi" },
  //     { label: "Debit", value: "debit" },
  //     { label: "Credit", value: "credit" },
  //   ];

  //   //Fetch all products
  //   useEffect(() => {
  //     if (dispatch) {
  //       dispatch(fetchProducts());
  //     }
  //   }, [dispatch]);

  //   useEffect(() => {
  //     handleCalculate();
  //   }, [items]);

  //   const handleChangeItem = (value, index) => {
  //     setItems((prevItems) => {
  //       const updatedItems = prevItems.map((item, i) => {
  //         if (i === index) {
  //           return value ? { ...value, price: value.rate, batches: value.batches } : { price: 0.0, tax: 0.0 };
  //         }
  //         return item;
  //       });
  //       return updatedItems;
  //     });
  //   };

  //   Profit Margin for user
  //   const [profitMargin, setProfitMargin] = useState(0);

  //   const handleCalculate = () => {
  //     const { total, discount, tax, profit } = items.reduce(
  //       (accumulator, currentItem) => {
  //         if (currentItem?.rate) {
  //           const quantity = parseInt(currentItem.quantity) || 1;
  //           let item_total = parseFloat(currentItem.rate || 0) * quantity;
  //           let item_discount = (parseFloat(currentItem?.discount || 0) / 100) * item_total;
  //           let item_tax = ((item_total - item_discount) * parseFloat(currentItem.tax || 0)) / 100;
  //           let item_profit = (parseFloat(currentItem.margin || 0) / 100) * (item_total - item_discount);

  //           accumulator.total += item_total;
  //           accumulator.discount += item_discount;
  //           accumulator.tax += item_tax;
  //           accumulator.profit += item_profit;
  //         }
  //         return accumulator;
  //       },
  //       { total: 0, discount: 0, tax: 0, profit: 0 }
  // );

  // setProfitMargin(profit);
  // let discounted = discount//(discount / 100) * total;
  // const gst = ((total - discounted) * tax) / 100;
  // valid_payment_types = ["pay_letter", "remain_payment", "paid"]
  //     setFormData({
  //       ...formData,
  //       sub_total: total.toFixed(2),
  //       discount: discount.toFixed(2),
  //       grand_total: (total - discount + tax).toFixed(2),
  //       tax: tax.toFixed(2),
  //       paid_amount: formData.grand_total,
  //       payment_type: "paid",
  //       remaining_total: 0.0,
  //       product_and_quantity: items.map((i) => ({
  //         productId: i.id,
  //         quantity: parseInt(i.quantity) || 1,
  //         batchId: i.batchId,
  //       })),
  //     });
  //   };

  //Set form values
  useEffect(() => {
    setFormData({
      ...formData,
      products: items.map((i) => ({
        product_name: i.product_name,
        brand: i.product_brand,
        qty: parseInt(i.quantity ?? 1),
      })),
    });
  }, [items]);

  const handleFieldChange = (value, index, field) => {
    setItems((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }
  };

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    //Guard to check for empty values
    formData?.products.forEach((product) => {
      if (!product?.brand || !product?.product_name) {
        alert("Some Details are Empty! Please fill all details");
        setDisableSubmitButton(false);
        return;
      }

      dispatch(createPurchaseDemand(formData))
        .then((action) => {
          if (action.payload) {
            if (!action.payload.pdf_url) {
              toast.error("Sorry, Purchase Demand cannot be downloaded try again!");
              setDisableSubmitButton(false);
              return;
            }

            const fileUrl = action.payload.pdf_url;

            const link = document.createElement("a");
            link.href = fileUrl;
            link.target = "_blank"; // Open in a new tab
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            setDisableSubmitButton(false);
          } else {
            toast.error("sorry, Purchase Demand cannot be created");
            setDisableSubmitButton(false);
          }
        })
        .catch((err) => {
          alert(err);
          setDisableSubmitButton(false);
        });
    });

    // if (items[0]?.id) {
    //   alert("Create Purchase Invoice was Clicked!");
    //   await dispatch(createInvoice(formData))
    //     .then((action) => {
    //       if (action.payload) {
    //         alert("Invoice Created successfully");
    //         // dispatch(toggleAlert({ message: "Invoice created successfully!", type: "success", showAlert: true }));
    //         navigate(-1);
    //       } else {
    //         alert("sorry, Invoice cannot be created");
    //         // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
    //       }
    //     })
    //     .catch((err) => {
    //       alert("error", err);
    //       // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
    //     });
    // } else {
    // dispatch(toggleAlert({ message: "Please , Add Products", type: "error", showAlert: true }));
    //   alert("Add more products");
    // }
  };

  //Find HSN number based on batch selected
  //   const getHSNNumberByBatchId = (batchId) => {
  //     const product = products.find((p) => p.batches.some((batch) => batch.id === batchId));
  //     if (product) {
  //       const batch = product.batches.find((batch) => batch.id === batchId);
  //       return batch ? batch.hsn_number : null;
  //     }
  //     return null;
  //   };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                if (window.confirm("want to add another item?")) {
                  addAnotherItemRef.current.focus(); // Focus on add another item button
                } else {
                  submitButton.current.focus(); //Focus on submit button
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  return (
    <div>
      <div className="mx-[20px] my-[20px]">
        <h1 className="text-2xl font-bold"> New Purchase Demand</h1>
        <div className="mt-8 w-full flex md:justify-start border-b-2 border-neutral-200">
          <div className="md:w-3/6 w-full">
            <h3 className="font-semibold text-xl text-black">{vendor?.vendor_name}</h3>
            <span className="font-semibold text-neutral-500">{`${vendor?.address} ${vendor?.zipcode}`}</span>
            {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-black">Invoice Number</h3>
              <span className="font-semibold text-neutral-500">{formData.invoiceId}</span>
            </div>
            <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-black">Order Number</h3>
              <h3 className="font-semibold text-neutral-500">{formData.orderId}</h3>
            </div> */}

            <div className="flex text-base gap-2 my-5">
              <h3 className="font-semibold text-black-500">GST Number: </h3>
              <span className="font-semibold text-neutral-500">
                {vendor?.gst_number ? vendor?.gst_number?.toUpperCase() : "Not Provided"}
              </span>
            </div>
            {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500">Zipcode</h3>
              <h3 className="font-semibold text-blue-500">{customer?.zipcode}</h3>
            </div> */}
            {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500">State</h3>
              <h3 className="font-semibold text-blue-500">{customer.state}</h3>
            </div>
            <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500">City</h3>
              <h3 className="font-semibold text-blue-500">{customer.city}</h3>
            </div> */}
            {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500 mr-2">Address</h3>
              <h3 className="font-semibold text-blue-500">{customer?.address}</h3>
            </div> */}
          </div>
        </div>
        <div>
          <div className="mt-[20px] overflow-y-scroll">
            <table cellPadding={5} cellSpacing={10}>
              <tr className="bg-gray-50">
                <th>Items Details</th>
                <th>Brand</th>
                {/* <th>Batch Number</th> */}
                {/* <th>HSN Number</th> */}
                <th>Quantity</th>
                {/* <th>
                  Retail <br />
                  Without Tax
                </th> */}
                {/* <th>Discount</th> */}
                {/* <th>Tax</th> */}
              </tr>
              {items.map((item, index) => (
                <tr key={index} className="mb-4">
                  {/* <td>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={products || []}
                      getOptionLabel={(option) =>
                        option?.total_remaining_quantity
                          ? `${option?.product_name} - Qty: ${option?.total_remaining_quantity}`
                          : `${option?.product_name}`
                      }
                      onChange={(event, value) => handleChangeItem(value, index)}
                      sx={{ width: 350 }}
                      renderInput={(params) => <TextField {...params} label="Products" />}
                    />
                  </td> */}
                  <td>
                    <TextField
                      id="outlined-basic"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          height: "20px",
                          paddingLeft: "10px",
                        },
                      }}
                      value={items[index]?.product_name}
                      onChange={(e) => {
                        handleFieldChange(e.target.value, index, "product_name");
                      }}
                      type="text"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          height: "20px",
                          paddingLeft: "10px",
                        },
                      }}
                      value={items[index]?.product_brand}
                      onChange={(e) => handleFieldChange(e.target.value, index, "product_brand")}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                      required
                    />
                  </td>
                  {/* <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          height: "24px",
                          paddingLeft: "10px",
                        },
                      }}
                      value={items[index]?.batch_number}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td> */}
                  {/* <td>
                    <Autocomplete
                      disablePortal
                      id="batch-number"
                      options={items[index]?.batches || []}
                      getOptionLabel={(option) =>
                        option?.batch_number
                          ? `${option?.batch_number} - Qty: ${option?.remaining_quantity}`
                          : `${option?.batch_number}`
                      }
                      onChange={(event, value) => {
                        handleFieldChange(value?.batch_number, index, "batch_number");
                        handleFieldChange(value?.id, index, "batchId"); // Store batch ID
                        handleFieldChange(getHSNNumberByBatchId(value?.id), index, "hsnNumber");
                        handleFieldChange(value?.discount, index, "discount");
                        handleFieldChange(value?.rate, index, "rate");
                        handleFieldChange(value?.tax, index, "tax");
                        handleFieldChange(value?.add_margin, index, "margin");
                        handleFieldChange(value?.remaining_quantity, index, "remaining_quantity");
                      }}
                      sx={{ width: 200 }}
                      renderInput={(params) => <TextField {...params} label="Batch Number" />}
                    />
                  </td> */}
                  {/* <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          height: "40px",
                          paddingLeft: "10px",
                        },
                      }}
                      disabled
                      value={items[index]?.hsnNumber}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td> */}
                  <td>
                    <TextField
                      id="outlined-basic"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          height: "20px",
                          paddingLeft: "10px",
                        },
                        min: 1,
                      }}
                      onChange={(e) => {
                        // if (items[index].remaining_quantity < e.target.value) {
                        //   alert("can't add more than remaining quanitity");
                        handleFieldChange(e.target.value, index, "quantity");
                        //   return;
                        // }
                        // if (e.target.value && e.target.value <= 0) {
                        //   alert("value can't be negative or 0");
                        //   handleFieldChange(1, index, "quantity");
                        //   return;
                        // }
                        // handleFieldChange(e.target.value, index, "quantity");
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          handleFieldChange(1, index, "quantity");
                        }
                      }}
                      value={items[index]?.quantity}
                      // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                      defaultValue={1}
                      type="number"
                      variant="outlined"
                    />
                  </td>
                  {/* <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          height: "40px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleFieldChange(e.target.value, index, "rate")}
                      value={items[index]?.rate}
                      // defaultValue={items[index]?.rate}
                      type="number"
                      variant="outlined"
                      disabled
                    />
                  </td> */}
                  {/* <td>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                      <FilledInput
                        id="filled-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        aria-describedby="filled-weight-helper-text"
                        inputProps={{
                          backgroundColor: "transparent",
                          "aria-label": "weight",
                        }}
                        onChange={(e) => handleFieldChange(e.target.value, index, "discount")}
                        value={items[index]?.discount}
                        disabled
                        // defaultValue={items[index]?.discount}
                      />
                    </FormControl>
                  </td> */}
                  {/* <td> */}
                  {/* <FormControl sx={{ paddingBottom: "10px", width: "25ch" }} variant="outlined">
                      <FilledInput
                        id="filled-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        aria-describedby="filled-weight-helper-text"
                        inputProps={{
                          backgroundColor: "transparent",
                          "aria-label": "weight",
                        }}
                        onChange={(e) => handleFieldChange(e.target.value, index, "tax")}
                        value={items[index]?.tax}
                        disabled
                      />
                    </FormControl> */}
                  {/* <TextField
                      id="outlined-basic"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      inputProps={{
                        style: {
                          height: "40px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleFieldChange(e.target.value, index, "tax")}
                      value={items[index]?.tax}
                      // defaultValue={items[index]?.tax}
                      type="number"
                      variant="outlined"
                      disabled
                    /> */}
                  {/* </td> */}
                  <td
                    onClick={() => {
                      handleRemoveItem(index);
                    }}
                  >
                    <IconWrapper>
                      <Cross className="text-white z-10" />
                    </IconWrapper>
                  </td>
                </tr>
              ))}
            </table>
            <button
              ref={addAnotherItemRef}
              className="text-black p-2 cursor-pointer pb-[20px]"
              onClick={() => setItems((prev) => [...prev, {}])}
            >
              + Add another item
            </button>
          </div>
          <div>
            {/* <div className="w-full flex border-t-2 border-neutral-200">
              <div className="w-full">
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SubTotal</h3>
                  <h3 className="font-semibold text-gray-500">{formData.sub_total || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Discount</h3>
                  <h3 className="font-semibold text-gray-500">{formData.discount || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(2) || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(2) || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Total</h3>
                  <h3 className="font-semibold text-gray-500">{formData.grand_total || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Profit Margin</h3>
                  <h3 className="font-semibold text-gray-500">{profitMargin.toFixed(2)}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Payment Options</h3>
                  <h3 className="font-semibold text-gray-500">
                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white sm:flex">
                      {paymentOptions.map((option, index) => (
                        <li key={option.value} className="w-full">
                          <div className="flex items-center ml-2">
                            <input
                              id="horizontal-list-radio-id"
                              type="radio"
                              value={option.value}
                              onChange={(e) => setFormData({ ...formData, payment_option: e.target.value })}
                              checked={formData.payment_option === option.value}
                              name="list-radio"
                              className="w-4 h-4 text-blue-600"
                            />
                            <label for="horizontal-list-radio-id" className="w-full ms-2 px-1 text-base font-medium">
                              {option.label}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Description</h3>
                  <h3 className="font-semibold text-gray-500">
                    <textarea
                      className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
                      placeholder=""
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      value={formData.description}
                    ></textarea>
                  </h3>
                </div>
              </div>
            </div> */}

            <div className="w-full flex items-center justify-center my-4">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={disableSubmitButton}
                ref={submitButton}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
              >
                Download Demand Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePurchaseDemand;
