import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBatchesByProductID,
  fetchMoreProducts,
  fetchProductByBarcode,
  fetchProductByID,
  fetchProducts,
} from "../../reducers/Slices/productSlice";
import { createCreditNote, getInvoiceDetailsById } from "../../reducers/Slices/invoiceSlice";
import { fetchCustomers, fetchMoreCustomers } from "../../reducers/Slices/customerSlice";
import toast from "react-hot-toast";
import BarcodeReader from "./InvoiceComponents/BarcodeReader";

function CreateCreditNote() {
  const dispatch = useDispatch();

  const [items, setItems] = useState([{}]);
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  const [invoiceDimension, setInvoiceDimension] = useState({});
  const [quantityLimitArr, setQuantityLimitArr] = useState([]);
  const [dealQuantityLimitArr, setDealQuantityLimitArr] = useState([]);
  const [discountOnMRPValue, setDiscountOnMRPValue] = useState([{}]);
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  //Fetch customer based on ID provided
  const customer = useSelector((state) => state.customer.customers.data.find((c) => c.id === parseInt(params.id)));
  const customersNext = useSelector((state) => state.customer.customers.next);

  const products = useSelector((state) => state.product.products.data);
  // const productsNext = useSelector((state) => state.product.products.next);

  const invoiceDetails = useSelector((state) => state.invoice.invoiceDetails);

  useEffect(() => {
    // Only run if there is something in invoiceDetails
    if (!invoiceDetails || invoiceDetails.length === 0) {
      return;
    }

    let updatedInvoiceInfo = [];
    let updatedQuantityLimitArr = [];
    let updatedDealQuantityLimitArr = [];
    let updatedDiscountOnMRPValue = [];
    let updatedCalculatedAmountValue = [];

    invoiceDetails.map((data, index) => {
      // const quantity = data.quantity;
      // const deal_quantity = data.deal_quantity;
      updatedInvoiceInfo.push({ id: data.id, productId: data.product, batchId: data.batch });
      updatedQuantityLimitArr.push(data.quantity);
      updatedDealQuantityLimitArr.push(data.deal_quantity);
      updatedDiscountOnMRPValue.push({ discountOnMRP: data.mrp_discount });
      updatedCalculatedAmountValue.push({});
      return data;
    });

    setInvoiceInfo(updatedInvoiceInfo);
    setQuantityLimitArr(updatedQuantityLimitArr);
    setDealQuantityLimitArr(updatedDealQuantityLimitArr);
    setDiscountOnMRPValue(updatedDiscountOnMRPValue);
    setCalculatedAmountValue(updatedCalculatedAmountValue);
  }, [invoiceDetails]);

  useEffect(() => {
    const updateItems = async () => {
      if (invoiceInfo?.length <= 0) {
        return;
      }

      let updatedItems = await Promise.all(
        invoiceInfo.map(async (info, index) => {
          const product = await findProductByProductID(info.productId);
          const batch = await findBatchByBatchID(product, info.batchId);

          return {
            ...product,
            ...batch,
            id: info.id,
            productId: info.productId,
            batchId: info.batchId,
            sales_price: calculateNetRate(batch?.mrp_price, discountOnMRPValue[index]?.discountOnMRP, batch?.tax),
          };
        })
      );

      setItems(updatedItems);
    };

    updateItems();
  }, [invoiceInfo, products]);

  //Function to reverse calculate netRate based on new discountOnMRPercent
  const calculateNetRate = (mrp, discountOnMRPPercent, taxPercent) => {
    //calculate the rate after discount
    const discountedRate = parseFloat(mrp) - parseFloat(mrp) * ((parseFloat(discountOnMRPPercent) || 0) / 100);
    const netRate = discountedRate / (1 + (parseFloat(taxPercent) || 0) / 100);

    return netRate.toFixed(3).slice(0, -1);
  };

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  //Fetch customers till none left
  useEffect(() => {
    if (customersNext) {
      dispatch(fetchMoreCustomers(customersNext));
    }
  }, [customersNext, dispatch]);

  useEffect(() => {
    dispatch(getInvoiceDetailsById({ id: params?.invoiceId }));
  }, [params]);

  //   const [userType, setUserType] = useState((type) => (parseInt(params.type) === 1 ? "vendor" : "customer"));

  const [formData, setFormData] = useState(() => {
    return { invoice: parseInt(params.invoiceId) };
  });

  //Payment Options
  //   const paymentOptions = [
  //     { label: "Cash", value: "cash" },
  //     { label: "UPI", value: "upi" },
  //     { label: "Debit", value: "debit" },
  //     { label: "Credit", value: "credit" },
  //   ];

  //Fetch all products
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchProducts());
    }
  }, [dispatch]);

  // //Fetch infinity products
  // useEffect(() => {
  //   if (productsNext) {
  //     dispatch(fetchMoreProducts(productsNext));
  //   }
  // }, [productsNext, dispatch]);

  useEffect(() => {
    handleCalculate();
  }, [items, discountOnMRPValue, calculatedAmountValue]);

  useEffect(() => {
    handleCalculateAmount(items);
  }, [items, discountOnMRPValue]);

  const handleCalculate = () => {
    const { total, discount, tax } = items.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.sales_price) {
          const quantity = parseInt(currentItem.quantity) || 0;
          let item_total = parseFloat(currentItem.sales_price || 0) * quantity;
          let item_mrp_total = parseFloat(currentItem.mrp_price || 0) * quantity;
          let item_discount = (parseFloat(discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0 }
    );

    setInvoiceDimension({ total: total + discount, discount, tax });

    setFormData({
      ...formData,
      total_amount: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      items: items.map((i, index) => ({
        invoice_item: i.id,
        return_quantity: parseInt(i.quantity) || 0,
        deal_quantity: parseInt(i.deal_quantity) || 0,
        return_amount: calculatedAmountValue[index]?.calculated_amount,
      })),
    });
  };

  const handleFieldChange = (value, index, field) => {
    setItems((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  const handleCalculateAmount = (items) => {
    if (items?.length <= 0) {
      return;
    }

    if (!items[0]?.id) {
      return;
    }

    setCalculatedAmountValue((prevValue) => {
      const updatedCalculatedAmountValue = prevValue?.map((_, index) => {
        if (_?.checkedByUser === true) {
          return _;
        }

        const { mrp_price, sales_price, quantity, tax } = items[index];
        const discountOnMRPPresent = parseFloat(discountOnMRPValue[index]?.discountOnMRP ?? 0);

        if (!sales_price || !quantity || !tax) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        const parsedQuantity = parseInt(quantity);

        if (sales_price === 0 || parsedQuantity === 0) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        // let amount = (parseFloat(mrp_price) - parseFloat(mrp_price) * (discountOnMRPPresent / 100)) * parsedQuantity;
        let amount = (parseFloat(sales_price) + (parseFloat(sales_price) * parseFloat(tax)) / 100) * parsedQuantity;

        //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
        const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

        return { calculated_amount: truncatedAmount, checkedByUser: false };
      });
      return updatedCalculatedAmountValue;
    });
  };

  const handleSubmit = async () => {
    // if (items[0]?.id) {
    //Todo - implement deal and quantity check
    setDisableSubmitButton(true);
    await dispatch(createCreditNote(formData))
      .then((action) => {
        if (action.payload) {
          navigate(-1);
          setDisableSubmitButton(false);
        } else {
          toast.error("sorry, Credit note cannot be created");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(`error occured: ${err}`);
        setDisableSubmitButton(false);
      });
  };

  const findProductByProductID = async (id) => {
    if (!id) {
      return;
    }

    let product = products?.find((product) => product.id === id);

    if (!product) {
      const response = await dispatch(fetchProductByID(id));
      if (response?.payload?.data) {
        product = response.payload.data;
      }
    }

    return product;
  };

  const findBatchByBatchID = async (product, id) => {
    if (!id) {
      return;
    }

    if (!product) {
      return;
    }

    let batch = product?.batches?.find((batch) => batch.id === id);
    if (!batch) {
      const response = await dispatch(fetchBatchesByProductID({ productId: product?.id, filters: `?batch_id=${id}` }));
      batch = response.payload.batchData.data[0];
    }

    return batch;
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  // const submitButton = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        // if (focusedElement === submitButton.current) {
        //   submitButton.current.click();
        //   return;
        // }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  //Barcode related operations

  // //using ref to add event listener to capture barcode;
  // const dummy_input_ref = useRef(null);
  // const Invoice_table_ref = useRef(null);

  // useEffect(() => {
  //   // Function to focus the dummy input if no other element is focused

  //   // Function to shift focus to the dummy input
  //   const focusDummyInput = () => {
  //     if (
  //       document.activeElement === document.body ||
  //       !(
  //         document.activeElement instanceof HTMLInputElement ||
  //         document.activeElement instanceof HTMLTextAreaElement ||
  //         document.activeElement instanceof HTMLSelectElement ||
  //         document.activeElement instanceof HTMLButtonElement
  //       )
  //     ) {
  //       dummy_input_ref.current.focus();
  //     }
  //   };

  //   // Initially focus on the dummy input
  //   dummy_input_ref.current.focus();

  //   // Add event listener for when focus shifts away from the dummy input
  //   const handleFocusOut = () => {
  //     setTimeout(() => {
  //       focusDummyInput();
  //     }, 0); // Small delay to ensure other inputs can take focus if clicked
  //   };

  //   // Add event listeners for focus and focusout
  //   document.addEventListener("focusout", handleFocusOut);
  //   document.addEventListener("focusin", focusDummyInput);

  //   // Clean up on component unmount
  //   return () => {
  //     document.removeEventListener("focusout", handleFocusOut);
  //     document.removeEventListener("focusin", focusDummyInput);
  //   };
  // }, []);

  // const handleDummyInputValueChange = (value) => {
  //   console.log(value, "this is the dummy input value");
  // };

  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
    some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  const handleAddCapturedProductsInItems = (productId, batchId) => {
    if (!batchId) {
      return;
    }

    if (!productId) {
      return;
    }

    const ItemAlreadyPresent = items.findIndex((item) => item?.productId === productId);
    const BatchAlreadyPresent = items.findIndex((item) => item?.batchId === batchId);

    let updatedItems = items;

    // console.log(quantityLimitArr[BatchAlreadyPresent], "this is the previous quantity");
    if (ItemAlreadyPresent >= 0 && BatchAlreadyPresent >= 0) {
      if (!updatedItems[BatchAlreadyPresent]?.quantity) {
        //Add quantity variable if not already present
        updatedItems[BatchAlreadyPresent] = {
          ...updatedItems[BatchAlreadyPresent],
          quantity: 1,
        };
      } else {
        const previousQuantity = parseInt(updatedItems[BatchAlreadyPresent].quantity);
        if (previousQuantity >= quantityLimitArr[BatchAlreadyPresent]) {
          toast.error("value can't be more than present in invoice");
        } else {
          updatedItems[BatchAlreadyPresent].quantity = previousQuantity + 1;
        }
      }
    }

    handleCalculateAmount(updatedItems);

    setItems(updatedItems);
  };

  //Side effect to rearrange data of captured product;
  useEffect(() => {
    //If product dosen't exist then return
    if (!capturedProduct?.id) {
      return;
    }

    const batchId = capturedProduct?.batch?.id;

    handleAddCapturedProductsInItems(capturedProduct?.id, batchId);
  }, [capturedProduct]);

  useEffect(() => {
    console.log(items, "these are the items");
  }, [items]);

  return (
    <div>
      <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
      <div className="mx-[20px] my-[20px]">
        <h1 className="text-2xl font-bold"> New Credit Note </h1>
        <div className="mt-8 w-full flex md:justify-start border-b-2 border-neutral-200">
          <div className="md:w-3/6 w-full">
            <h3 className="font-semibold text-xl text-black">{customer?.customer_name}</h3>
            <span className="font-semibold text-neutral-500">
              {customer?.address},{customer?.zipcode}
            </span>
            <div className="flex text-base gap-2 my-5">
              <h3 className="font-semibold text-black-500">GST Number: </h3>
              <span className="font-semibold text-neutral-500">
                {customer?.gst_number ? customer?.gst_number?.toUpperCase() : "Not Provided"}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-[20px] overflow-y-scroll">
            <table cellPadding={5} cellSpacing={10}>
              <tr>
                <th>Items Details</th>
                <th>Batch Number</th>
                <th>HSN Number</th>
                <th>Quantity</th>
                <th>Deal / Free</th>
                <th>
                  Retail <br />
                  Without Tax
                </th>
                <th>Tax %</th>
                <th>Discount on MRP %</th>
                <th>Amount</th>
              </tr>
              {items?.map((item, index) => (
                <tr key={item?.id || index} className="mb-4">
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          width: "350px",
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      disabled
                      value={items[index]?.product_name}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          width: "200px",
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      disabled
                      value={items[index]?.batch_number}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      disabled
                      value={items[index]?.hsn_number}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                        min: 1,
                      }}
                      onChange={(e) => {
                        if (e.target.value && e.target.value < 0) {
                          toast.error("value can't be negative");
                          handleFieldChange(1, index, "quantity");
                          return;
                        }

                        if (e.target.value > quantityLimitArr[index]) {
                          toast.error("value can't be more then present in invoice");
                          handleFieldChange(quantityLimitArr[index], index, "quantity");
                          return;
                        }
                        handleFieldChange(e.target.value, index, "quantity");
                      }}
                      value={items[index]?.quantity}
                      defaultValue={0}
                      type="number"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                        min: 1,
                      }}
                      onChange={(e) => {
                        if (e.target.value && e.target.value < 0) {
                          toast.error("value can't be negative");
                          handleFieldChange(0, index, "deal_quantity");
                          return;
                        }

                        if (e.target.value > dealQuantityLimitArr[index]) {
                          toast.error("value can't be more then present in invoice");
                          handleFieldChange(dealQuantityLimitArr[index], index, "deal_quantity");
                          return;
                        }

                        handleFieldChange(e.target.value, index, "deal_quantity");
                      }}
                      value={items[index]?.deal_quantity}
                      defaultValue={0}
                      type="number"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleFieldChange(e.target.value, index, "sales_price")}
                      value={items[index]?.sales_price}
                      // defaultValue={items[index]?.rate}
                      type="number"
                      variant="outlined"
                      disabled
                    />
                  </td>
                  <td>
                    <TextField
                      id="tax_percent"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      value={items[index]?.tax}
                      type="text"
                      variant="outlined"
                      disabled
                    />
                  </td>
                  <td>
                    <TextField
                      id="discount_on_MRP"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      value={discountOnMRPValue[index]?.discountOnMRP ?? ""}
                      type="text"
                      variant="outlined"
                      disabled
                    />
                  </td>
                  <td>
                    <TextField
                      id="amount"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      value={calculatedAmountValue[index]?.calculated_amount || 0}
                      type="text"
                      variant="outlined"
                      disabled
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div>
            <div className="w-full flex border-t-2 border-neutral-200 mt-[20px]">
              <div className="w-full">
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SubTotal</h3>
                  <h3 className="font-semibold text-gray-500">{(invoiceDimension?.total || 0).toFixed(2)}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Discount</h3>
                  <h3 className="font-semibold text-gray-500">{(invoiceDimension?.discount || 0).toFixed(2)}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">
                    {(invoiceDimension?.tax / 2 || 0).toFixed(3).slice(0, -1)}
                  </h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">
                    {(invoiceDimension?.tax / 2 || 0).toFixed(3).slice(0, -1)}
                  </h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Total</h3>
                  <h3 className="font-semibold text-gray-500">{(formData?.total_amount || 0).toFixed(2)}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-center my-4">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={disableSubmitButton}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
              >
                Create Credit Note
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCreditNote;
