import React, { useState } from "react";
// import { Box } from "@mui/material";
// import './Login.scss'; -Phaniver - todo - delete - if no dependency
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { http } from "../../service/http";
import OTPInput from "react-otp-input";
import { checkTokenExists, setSession } from "../../utils/session";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setProfileImage } from "../../reducers/Slices/userSlice";
import CreateBusinessModal from "./CreateBusinessModal";
import { apiUrl } from "../../config/appConfig";
// import LoginBackground from "../../assets/images/login_background.jpg";
import ProfitAILogo from "../../assets/images/TheProfitAi_logo.png";
import toast from "react-hot-toast";
import { isSubscriptionExpired } from "../../utils/checkSubscriptionExpiry";
import FeatureSwiper from "./loginComponents/FeatureSwiper";
// import { apiUrl } from "../../config/appConfig";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (checkTokenExists()) {
      if (localStorage.getItem("business_profile") === "true") {
        navigate("/");
        return;
      }
      setOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  //initial states of the form
  const initialState = {
    phone_number: "",
    otp: "",
  };

  //initial states of the form
  const [formData, setFormData] = useState(initialState);
  // const [inputError, setInputError] = useState({ phone_number: "", otp: "" });
  const [showError, setShowError] = useState(false);

  //Disable showError after certain amount of time
  useEffect(() => {
    if (showError) {
      setTimeout(() => setShowError(false), 5000);
    }
  }, [showError]);

  const [showOtp, setShowOtp] = useState(false);

  const handleSendOtp = async () => {
    // some check validation here
    setShowOtp(true);
    setTimer(60);
    http
      .post("/user_profile/backend/sendotp/", formData)
      .then((res) => {})
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleVerifyOtp = () => {
    http
      .post("/user_profile/backend/login/", formData)
      .then((res) => {
        if (res?.data?.token) {
          setSession(res.data);
          //If there is a profile picture set it
          if (res.data?.profile_image) {
            dispatch(setProfileImage(`${apiUrl}${res.data?.profile_image}`));
          }
          if (res.data.business_profile) {
            //If subscription is expired or there is none then navigate to plans page
            if (isSubscriptionExpired(res.data.business_profile.subscription_expiry)) {
              navigate("/plan_selection");
            }
            //Otherwise navigate to home page
            navigate("/");
          } else {
            setOpen(true);
          }
        } else {
          throw res.data.message;
        }
      })
      .catch((err) => {
        setShowError(true);
      });
  };

  //Let use Edit Number
  const handleEditNumber = () => {
    setShowOtp(false);
  };

  //Split Phone number and area code
  function splitPhoneNumber(phoneNumber) {
    // phaniver - currently only working for indian number
    let areaCode = phoneNumber.slice(0, 3);
    let remainingNumber = phoneNumber.slice(3);

    return `${areaCode}-${remainingNumber}`;
  }

  return (
    <div className="flex">
      {/* Form Section */}
      <div className="w-[35%] h-[100vh]">
        {/* Logo  */}
        <div className="pl-[20px]">
          <img src={ProfitAILogo} alt="The Profit AI logo" className="w-[14vw] xl:w-[12vw] 2xl:w-[9vw]" />
        </div>
        {/* GST Model, Business Modal And OTP input*/}
        <div className="h-[75vh] xl:h-[80vh] flex items-center justify-center">
          {open ? (
            <div>
              <CreateBusinessModal />
            </div>
          ) : (
            <div className="w-full ml-[3vw]">
              <div>
                <h1 className="text-2xl xl:text-3xl font-semibold pb-[10px] 2xl:pb-[15px]">Log In / Register</h1>
                <p className="text-lg text-neutral-500 font-medium">Welcome to the future of ERP systems,</p>
                <p className="text-lg text-neutral-500 font-medium -mt-[4px]">
                  Start your journey by signing up for free.
                </p>
              </div>
              <div className="max-w-[70%] 2xl:max-w-[60%] pb-[5vh]">
                {/* Phone Number Input  */}
                {!showOtp && (
                  <div className="flex flex-col justify-center">
                    <label className="block text-black text-md font-semibold pt-[35px] pb-[10px]" htmlFor="username">
                      Enter Your Mobile Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      disabled={showOtp}
                      value={formData.phone_number}
                      onChange={(phone_number) => setFormData({ ...formData, phone_number: `+${phone_number}` })}
                      onKeyDown={(e) => e.key === "Enter" && handleSendOtp()}
                      inputStyle={{
                        width: "100%",
                        borderColor: "#E0E0E0",
                        padding: "18px 47px",
                        borderRadius: "5px",
                        color: "rgba(1, 1, 1, 0.8)",
                      }}
                    />
                  </div>
                )}
                {showOtp ? (
                  <>
                    <label className="mt-[40px] mb-[10px] block text-base text-neutral-500" htmlFor="username">
                      We've just sent you 6 digit code on{" "}
                      <span className="text-black font-semibold">{splitPhoneNumber(formData.phone_number)}</span>
                    </label>
                    <div className="w-full flex py-1 mx-auto">
                      <OTPInput
                        value={formData.otp}
                        onChange={(otp) => setFormData({ ...formData, otp })}
                        numInputs={6}
                        shouldAutoFocus={true}
                        containerStyle={{
                          borderColor: "#000 !important",
                        }}
                        inputStyle={{
                          width: "2.8vmax",
                          height: "2.8vmax",
                          border: "1px solid gray",
                        }}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => (
                          <input {...props} onKeyDown={(e) => e.key === "Enter" && handleVerifyOtp()} />
                        )}
                      />
                    </div>
                    {showError && (
                      <span className="block py-[4px] text-red-500 text-sm">OTP is either empty or Incorrect!</span>
                    )}
                    <div className="text-base py-[5px] mt-[10px] ">
                      {timer > 0 ? (
                        <p className="text-neutral-500">You can choose to resend OTP in {timer}s </p>
                      ) : (
                        <p className="text-neutral-500">
                          Didn't receive code?{" "}
                          <span onClick={handleSendOtp} className="underline cursor-pointer text-black ">
                            Resend OTP
                          </span>{" "}
                          <span className="text-sm">or</span>{" "}
                          <span onClick={handleEditNumber} className="underline cursor-pointer text-black">
                            Change Number
                          </span>
                        </p>
                      )}
                    </div>
                    <div className="w-full flex gap-[20px] justify-center items-center my-[15px]">
                      <button
                        onClick={handleVerifyOtp}
                        onKeyDown={(e) => e.key === "Enter" && handleVerifyOtp}
                        className="bg-zinc-900 text-white px-[20px] py-[10px] w-full font-semibold rounded-[5px]"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="w-full flex justify-center items-center mt-[15px] mb-[30px] bg-green-200">
                    <button
                      onClick={handleSendOtp}
                      onKeyDown={(e) => e.key === "Enter" && handleSendOtp}
                      className="bg-zinc-900 text-white px-[20px] py-[10px] w-full font-semibold rounded-[5px]"
                    >
                      Send 6 Digit OTP
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Terms and Condition / Privacy Policy Section */}
        <div className="px-[2vw]">
          By accessing your account or creating a new one, you agree to our{" "}
          <a
            href="/app/documents/Terms_and_Conditions.pdf"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a href="/app/documents/Privacy_Policy.pdf" className="underline" target="_blank" rel="noopener noreferrer">
            Privacy Policy.
          </a>
        </div>
      </div>
      {/* Feature Highlight Section */}
      <div className="w-[65%] h-[100vh]">
        <div className="m-[10px] bg-blue-800 w-[calc(100%-20px)] h-[calc(100%-20px)] rounded-[20px] ">
          <div className="flex justify-center w-full">
            <h3 className="w-full text-2xl 2xl:text-3xl py-[6vh] text-center text-[#F6F5F5] font-semibold max-w-[50%]">
              Transform Your Business with AI-Powered ERP: Intelligent Solutions for Enhanced Efficiency and Insight
            </h3>
          </div>
          <FeatureSwiper />
        </div>
      </div>
    </div>
  );
}

export default Login;
