import React, { useState } from "react";

//P&L components

//Default P&L
import PL from "./profitAndLossComponents/PL";

//Tax P&L
import TaxPL from "./profitAndLossComponents/TaxPL";

//Party Wise P&L
import PartyPL from "./profitAndLossComponents/PartyPL";

//Company wise P&L
import CompanyPL from "./profitAndLossComponents/CompanyPL";

//Item wise P&L
import ItemPL from "./profitAndLossComponents/ItemPL";

//Route based P&L
import RoutePL from "./profitAndLossComponents/RoutePL";

const ProfitAndLoss = () => {
  const [selectedPL, setSelectedPL] = useState("default");

  //Render P&L statement based on selected P&L category
  const renderPLComponent = () => {
    switch (selectedPL) {
      case "TaxPL":
        return <TaxPL />;
      case "PartyPL":
        return <PartyPL />;
      case "CompanyPL":
        return <CompanyPL />;
      case "ItemPL":
        return <ItemPL />;
      case "RoutePL":
        return <RoutePL />;
      default:
        return <PL />;
    }
  };

  return (
    // P&L section
    <div className="flex">
      <aside className="p-[15px] border-r-2 border-neutral-200 min-w-[300px] w-[15vw] h-[calc(100vh-80px)]">
        <ul className="flex flex-col gap-[10px]">
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedPL("default")}
          >
            P&L statement
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedPL("TaxPL")}
          >
            Tax P&L statement
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedPL("PartyPL")}
          >
            Party Wise P&L
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedPL("CompanyPL")}
          >
            Company Wise P&L
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedPL("ItemPL")}
          >
            Item Wise P&L
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedPL("RoutePL")}
          >
            Route Wise P&L
          </li>
        </ul>
      </aside>

      {/* P&L container */}
      <div className="w-[85vw] max-w-[85vw] overflow-hidden">{renderPLComponent()}</div>
    </div>
  );
};

export default ProfitAndLoss;
