import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchMoreCustomers } from "../../../reducers/Slices/customerSlice";
import InfiniteScroll from "react-infinite-scroll-component";
// import filterImage from "../../assets/images/filter.png";
import gstIcon from "../../../assets/images/gst.jpg";
import InvoiceBarChart from "../Charts/InvoiceBarChart";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import AddUser from "../AddUser";
import { Checkbox, Tooltip } from "@mui/material";
import FavoriteIcon from "../../../assets/images/favChecked.png";
import FavoriteBorderIcon from "../../../assets/images/fav.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers, setFavouriteCustomer } from "../../../reducers/Slices/customerSlice";
import { fetchProductRecommendedList } from "../../../reducers/Slices/productSlice";
import { debounce } from "lodash";
import CommonLists from "../CommonLists";

//Functional Components
// import DeleteCustomer from "./InvoiceComponents/DeleteCustomer";

//UI component
import { Filter } from "react-feather";
import IconWrapper from "../../MicroComponents/IconWrapper";
import { useHotkeys } from "react-hotkeys-hook";

function EstimateInvoice() {
  const [showAddUser, setShowAddUser] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState();

  //Hot key to add Customer
  useHotkeys("Ctrl + Alt + C, Ctrl + Option + C", () => setShowAddUser(!showAddUser));

  const customers = useSelector((state) => state.customer.customers.data) || [];
  const customersNext = useSelector((state) => state.customer.customers.next);

  const recommendedProducts = useSelector((state) => state.product.productRecommendedList);
  const [showMenu, setShowMenu] = useState(null);

  useEffect(() => {
    search.length > 0 ? debouncedSearch(search) : dispatch(fetchCustomers(tab));
  }, [dispatch, tab]);

  //Fetch Recommended Products
  useEffect(() => {
    dispatch(fetchProductRecommendedList());
  }, [dispatch]);

  const handleCreateInvoice = (id) => {
    navigate(`/invoice/create/estimate/customer/${id}/0`);
  };

  const handleChangeTab = (value) => {
    setTab(tab === value ? null : value);
  };

  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  const handleCloseModal = () => {
    setShowAddUser(false);
  };

  // const handleUpdateCustomer = (customer) => {
  //   setCurrentCustomer(customer);
  //   setShowAddUser(true);
  // };

  const handleAddCustomer = () => {
    setCurrentCustomer({});
    setShowAddUser(true);
  };

  const debouncedSearch = debounce((search) => {
    dispatch(fetchCustomers(tab ? tab + "&search=" + search : "?search=" + search));
  }, 1500);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const [hasMoreCustomers, setHasMoreCustomers] = useState(() => (customersNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (customersNext) {
      setHasMoreCustomers(true);
    } else {
      setHasMoreCustomers(false);
    }
  }, [customersNext]);

  //fetch more expenses on scroll if available
  const fetchNextCustomers = () => {
    if (customersNext) {
      dispatch(fetchMoreCustomers(customersNext));
    }
  };
  return (
    <>
      <CustomModal isOpen={showAddUser} handleClose={handleCloseModal}>
        <AddUser handleCloseModal={handleCloseModal} currentCustomer={currentCustomer} />
      </CustomModal>

      <div className="h-full w-full">
        <ul className="flex flex-row text-nowrap whitespace-nowrap flex-wrap justify-center md:justify-normal font-medium text-center gap-4 py-[25px] px-[20px] border-b-2 border-neutral-200">
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/all_invoice/"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              ALL
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
              aria-current="page"
            >
              INVOICE
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/credit_note"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              CREDIT NOTE
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/debit_note"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              DEBIT NOTE
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/Cancel_Invoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              CANCEL INVOICE
            </Link>
          </li>
          <li>
            <Link
              to="/invoice/estimate_invoice"
              className="bg-black py-[10px] px-[20px] text-white hover:bg-white hover:text-black border-[1px] border-black rounded-full"
            >
              ESTIMATE INVOICE
            </Link>
          </li>
          <li>
            <Link
              to="/invoice/einvoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              E-INVOICE
            </Link>
          </li>
        </ul>

        <div className="flex flex-col md:flex-row content-center h-[83vh]">
          {/* customer container  */}
          <div className="w-full md:w-[80%] border-b-2 md:border-b-0 py-[20px] md:border-r-2 border-neutral-200">
            <div className="flex items-center gap-[10px] px-[20px]">
              {/* search Bar */}
              <div className="w-full">
                <label for="default-search" className="mb-2 xl:text-sm text-xs font-medium text-gray-900 sr-only">
                  Search
                </label>
                <div className="relative">
                  <input
                    type="search"
                    onChange={handleSearch}
                    id="default-search"
                    className="block w-full py-2 px-3 pe-10 xl:text-sm text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Mobile numbers or Username"
                    required
                  />
                  <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
                    <svg
                      className="w-4 h-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Filter Button */}
              <div className="relative">
                <button
                  onClick={() => {
                    handleShowMenu("settings");
                  }}
                >
                  <IconWrapper>
                    <Filter className="z-10 text-white" />
                  </IconWrapper>
                </button>
                <div
                  id="dropdownInformation"
                  onMouseLeave={() => handleShowMenu("settings")}
                  className={`${
                    showMenu !== "settings" ? "hidden" : ""
                  } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                >
                  <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                    <li>
                      <button
                        onClick={() => handleChangeTab("?favourite=1")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        Favorite
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleChangeTab("?most_frequent=1")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        Frequent
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleChangeTab("?most_profitable=1")}
                        className="text-lg pt-2 w-full border-neutral-200"
                      >
                        Top User
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* add Customer Button */}
              <div>
                <button
                  type="submit"
                  className="text-white whitespace-nowrap bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-neutral-200 font-semibold rounded-full xl:text-sm text-xs px-3 py-3"
                  onClick={handleAddCustomer}
                >
                  Add Customer
                </button>
              </div>
            </div>
            {/* <div className="relative flex p-2">
              <div className="">
                <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm  border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?favourite=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?favourite=1")}
                >
                  Favourite
                </button>
                <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm  border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?most_frequent=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?most_frequent=1")}
                >
                  Frequent
                </button>
                <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm  border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?most_profitable=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?most_profitable=1")}
                >
                  Top User
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="absolute right-0 m-4 text-white bg-transparent  hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 my-auto w-10"
                >
                  {" "}
                  <img src={filterImage} alt="Filter" srcset="" />
                </button>
              </div>
            </div> */}
            <div className="px-[20px] max-h-[76vh] overflow-y-scroll">
              <span className="block text-sm text-neutral-500 py-[10px] text-center">
                Double click to Create Estimate Invoice
              </span>
              <InfiniteScroll
                dataLength={customers.length}
                next={fetchNextCustomers}
                hasMore={hasMoreCustomers}
                height={"70vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Customer List End</b>
                  </p>
                }
              >
                {customers.map((item, index) => (
                  <Tooltip key={index} placement="right" title="Double click to create invoice">
                    {" "}
                    <div
                      onDoubleClick={() => handleCreateInvoice(item.id)}
                      className="p-[20px] mb-[20px] border-[1px] hover:border-neutral-500 focus:border-neutral-500 shadow bg-white rounded-[20px] "
                    >
                      <div className=" relative flex">
                        <div className="text-lg xl:text-xl flex justify-between">
                          <h2>
                            <span className="text-black text-md font-semibold max-w-[25vw] block">
                              {item.customer_name}
                            </span>
                            <span className="text-sm text-neutral-500">
                              GST number: {item.gst_number ? item.gst_number?.toUpperCase() : "Not Provided"}
                            </span>
                          </h2>
                        </div>
                        <div className="absolute right-0 text-xs xl:text-sm flex justify-between">
                          <h2 className="font-bold flex justify-center items-center -mt-2">
                            {/* <Link to={`/invoice/create/customer/${item.id}`} className='btn h-6 text-xs xl:text-sm  hover:bg-gray-300 !py-0 px-4 rounded-lg text-white bg-green-600'>Edit</Link> */}
                            {/* {item.gst_status && (
                            <button
                              type="button"
                              className="w-12 text-xs hover:bg-gray-300 p-2 ml-1 bg-transparent hover:bg-transparent "
                            >
                              <img src={gstIcon} alt="GST" srcset="" />
                            </button>
                          )} */}

                            <Checkbox
                              className="w-11"
                              checked={item.favourite}
                              onChange={(e) =>
                                dispatch(setFavouriteCustomer({ id: item.id, favourite: e.target.checked }))
                              }
                              icon={<img src={FavoriteBorderIcon} alt="" srcset="" />}
                              checkedIcon={<img src={FavoriteIcon} alt="" srcset="" />}
                            />
                            {/* <button
                            type="button"
                            className="flex items-center font-medium gap-[6px] border-[1px] text-md border-black px-2 py-[2px] rounded-full"
                            onClick={() => handleUpdateCustomer(item)}
                          >
                            Edit
                            <Edit2 className="h-[15px] w-[15px]" />
                          </button> */}
                            {/* <button
                            type="button"
                            className="flex items-center font-medium gap-[6px] border-[1px] border-red-500 text-red-500 text-md px-2 py-[2px] rounded-full ml-2"
                            onClick={() => handleDeleteCustomer(item)}
                          >
                            delete
                            <Cross className="h-[15px] w-[15px]" />
                          </button> */}
                            {/* <DeleteCustomer customer={item} /> */}
                          </h2>
                        </div>
                      </div>
                      <div className="relative mt-[20px] flex justify-between text-nowrap whitespace-nowrap">
                        <div className="text-xs xl:text-sm flex justify-between">
                          <h2 className="font-semibold">
                            <span className="text-neutral-500">{item.phone_number}</span>
                          </h2>
                        </div>
                        <div className="text-xs xl:text-sm flex justify-between">
                          <h2 className="font-semibold text-black">
                            Total Amount: <span className="text-neutral-500">₹{item.all_grand_total || 0.0}</span>
                          </h2>
                        </div>
                      </div>
                      <div className="relative flex text-nowrap whitespace-nowrap">
                        <div className="text-xs xl:text-sm flex justify-between">
                          <h2 className="font-bold min-h-4">
                            {item.gst_status && (
                              <>
                                GST : <span className="text-neutral-500">{item.gst_number?.toUpperCase()}</span>
                              </>
                            )}
                          </h2>
                        </div>
                        <div className="absolute right-0 text-xs xl:text-sm flex justify-between">
                          <h2 className="font-semibold">
                            Credit Amount: <span className="text-neutral-500">₹{item.all_remaining || 0.0}</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          {/* graph container */}
          {/* <div className="w-full h-[83vh] md:w-[40%] border-r-2 border-netural-200 flex justify-center pt-[20px]">
            <InvoiceBarChart />
          </div> */}
          {/* recommend product container  */}
          <div className="w-full h-[83vh] md:w-[20%] flex justify-center pt-[20px]">
            <div className="w-full px-[20px]">
              <CommonLists
                title="Inventory Low Stock"
                labels={["Index", "Name", "Quantity"]}
                data={recommendedProducts}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EstimateInvoice;
