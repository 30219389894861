import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
// import axios from "axios";

//fetch purchase invoices
export const fetchPurchaseInvoices = createAsyncThunk("purchaseInvoice/fetchPurchaseInvoices", async (data, extra) => {
  const res = await http.get(`invoice/api/invoice/vendor/list${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more purchase invoices for react infinity scroll
export const fetchMorePurchaseInvoices = createAsyncThunk(
  "purchaseInvoice/fetchMorePurchaseInvoices",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Get Purchase Invoice Details by ID
export const getPurchaseInvoiceDetailsById = createAsyncThunk(
  "purchaseInvoice/getPurchaseInvoiceDetailsById",
  async (data, extra) => {
    const res = await http.get(`/invoice/api/invoice/purchase-details/${data.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//Create purchase demand
export const createPurchaseDemand = createAsyncThunk("purchaseInvoice/createPurchaseDemand", async (data, extra) => {
  const res = await http.post("request_invoices/api/invoice/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Create purchase invoice
export const createPurchaseInvoice = createAsyncThunk("purchaseInvoice/createPurchaseInvoice", async (data, extra) => {
  const res = await http.post("invoice/api/purchase/create/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  }
});

//Cancel purchase invoice
export const deletePurchaseInvoice = createAsyncThunk("purchaseInvoice/deletePurchaseInvoice", async (data, extra) => {
  const res = await http.delete(`invoice/api/purchase/create/${data.id}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.status === 200 || res.status === 204) {
    return data;
  }
});

//Update purchase invoice
export const updatePurchaseInvoice = createAsyncThunk("purchaseInvoice/updatePurchaseInvoice", async (data, extra) => {
  const res = await http.put(`invoice/api/purchase/create/${data.id}/`, data.data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  }
});

//Purchase monthly analytics data
export const purchaseMonthlyAnalyticsData = createAsyncThunk(
  "purchaseInvoice/purchaseMonthlyAnalyticsData",
  async (data, extra) => {
    const res = await http.get(`/invoice/api/invoice/monthly-purchases-analytics${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//get vendor invoices
export const getVendorInvoices = createAsyncThunk("purchaseInvoice/getVendorInvoices", async (data, extra) => {
  const res = await http.get(`invoice/api/invoice/vendor/list${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const purchaseInvoiceSlice = createSlice({
  name: "purchaseInvoice",
  initialState: {
    // customer_invoices: [],
    purchaseInvoices: {
      data: [],
      next: null,
    },
    purchaseInvoiceDetails: [],
    purchase_analytics: { current_month_data: [], previous_month_data: [], x_labels: [] },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPurchaseInvoices.fulfilled, (state, action) => {
      return { ...state, purchaseInvoices: { data: action.payload?.data, next: action.payload?.next } };
    });
    builder.addCase(fetchMorePurchaseInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        purchaseInvoices: {
          data: [...state.purchaseInvoices.data, ...action.payload?.data],
          next: action.payload?.next,
        },
      };
    });
    builder.addCase(createPurchaseDemand.fulfilled, (state, action) => {
      return;
    });
    builder.addCase(getPurchaseInvoiceDetailsById.fulfilled, (state, action) => {
      return { ...state, purchaseInvoiceDetails: action.payload };
    });
    builder.addCase(createPurchaseInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        purchaseInvoices: { ...state.purchaseInvoices, data: [...state.purchaseInvoices.data, action.payload] },
      };
    });
    builder.addCase(purchaseMonthlyAnalyticsData.fulfilled, (state, action) => {
      return {
        ...state,
        purchase_analytics: action.payload,
      };
    });
    builder.addCase(updatePurchaseInvoice.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      const updatedPurchaseInvoice = action.payload;
      const updatedData = state.purchaseInvoices.data.map((invoice) => {
        if (invoice.id === updatedPurchaseInvoice.id) {
          return updatePurchaseInvoice;
        }
        return invoice;
      });
      return { ...state, purchaseInvoices: { ...state.purchaseInvoices, data: updatedData } };
    });
    builder.addCase(getVendorInvoices.fulfilled, (state, action) => {
      return { ...state, purchaseInvoices: { data: action.payload?.data, next: action.payload?.next } };
    });
    //   // builder.addCase(fetchInvoiceByCustomerId.fulfilled, (state, action) => {
    //   //   return { ...state, customer_invoices: action.payload };
    //   // });
    //   // builder.addCase(fetchInvoiceByVendorId.fulfilled, (state, action) => {
    //   //   return { ...state, vendor_invoices: action.payload };
    //   // });
    //   builder.addCase(fetchInvoiceByProductId.fulfilled, (state, action) => {
    //     return { ...state, product_invoices: { data: action.payload.data, next: action.payload.next } };
    //   });
    //   builder.addCase(fetchMoreInvoiceByProductId.fulfilled, (state, action) => {
    //     return {
    //       ...state,
    //       product_invoices: { data: [...state.product_invoices.data, ...action.payload.data], next: action.payload.next },
    //     };
    //   });
    //   builder.addCase(fetchSalesCompareAnalytics.fulfilled, (state, action) => {
    //     return { ...state, sales_analytics: action.payload };
    //   });
    //   builder.addCase(getInvoiceDetailsById.fulfilled, (state, action) => {
    //     return { ...state, invoiceDetails: action.payload };
    //   });
    //   builder.addCase(getVendorInvoices.fulfilled, (state, action) => {
    //     return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    //   });
    //   builder.addCase(getCustomerInvoices.fulfilled, (state, action) => {
    //     return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    //   });
    builder.addCase(deletePurchaseInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        purchaseInvoices: {
          ...state.purchaseInvoices,
          data: state.purchaseInvoices.data.filter((invoice) => invoice.id !== action.payload?.id),
        },
      };
    });
  },
});

export default purchaseInvoiceSlice.reducer;
// export const { getInvoiceById, addInvoice } = invoiceSlice.actions;
