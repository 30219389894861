import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

//API reducer calls
import {
  fetchAttendances,
  fetchEmployees,
  updateAttendance,
  fetchMoreEmployees,
  fetchMoreAttendances,
  createAllAttendances,
} from "../../reducers/Slices/employeeSlice";

//Component Import
import AddEmployee from "./EmployeesComponents/AddEmployee";
import RemoveEmployee from "./EmployeesComponents/RemoveEmployee";
import EditEmployee from "./EmployeesComponents/EditEmployee";
import CheckBox from "@mui/material/Checkbox";

//UI components
import IconWrapper from "../MicroComponents/IconWrapper";
import DetailContainer from "../MicroComponents/DetailContainer";
import { Search, Edit2 } from "react-feather";
import { changeDateFormat, humanDateFormat } from "../../utils/dateFormat";

const Employees = () => {
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employee.employees.data);
  const employeesNext = useSelector((state) => state.employee.employees.next);
  const attendances = useSelector((state) => state.employee.attendances.data);
  const attendancesNext = useSelector((state) => state.employee.attendances.next);

  const [selectedDate, setSelectedDate] = useState(() => changeDateFormat(Date.now()));
  const [createDateButton, setCreateDateButton] = useState(false);
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  const [hasMoreEmployees, setHasMoreEmployees] = useState(() => (employeesNext ? true : false));
  const [hasMoreAttendances, setHasMoreAttendances] = useState(() => (attendancesNext ? true : false));

  const [disableDateRecordsButton, setDisableDateRecordsButton] = useState(false);

  //Use effect to fetch more employees based on value of employeesNext
  useEffect(() => {
    if (employeesNext) {
      setHasMoreEmployees(true);
    } else {
      setHasMoreEmployees(false);
    }
  }, [employeesNext]);

  //fetch more employees on scroll if available
  const fetchNextEmployees = () => {
    if (employeesNext) {
      dispatch(fetchMoreEmployees(employeesNext));
    }
  };

  //Use effect to fetch more attedances based on value of attendancesNext
  useEffect(() => {
    if (attendancesNext) {
      setHasMoreAttendances(true);
    } else {
      setHasMoreAttendances(false);
    }
  }, [attendancesNext]);

  //fetch more attendances on scroll if available
  const fetchNextAttendances = () => {
    if (attendancesNext) {
      dispatch(fetchMoreAttendances(attendancesNext));
    }
  };

  //Fetch all employee and attendances
  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  //Fetch all attendance and attendance for particular date
  useEffect(() => {
    dispatch(fetchAttendances({ date: selectedDate })).then((action) => {
      if (action.payload?.data?.length === 0) {
        // //Today's date
        // let todayDate = changeDateFormat(new Date());
        // if (localStorage.getItem("employeeRecordFetchDate") !== todayDate) {
        setCreateDateButton(true);
        // }
      } else if (action.payload?.data?.length > 0) {
        setCreateDateButton(false);
      }
    });
  }, [dispatch, selectedDate, employees]);

  const [showDateEditor, setShowDateEditor] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  //Set seleted employee to employee one on first render
  useEffect(() => {
    setSelectedEmployee(() => {
      if (employees && employees.length > 0) {
        return employees[0];
      } else {
        return {};
      }
    });
  }, [setSelectedEmployee, employees]);

  const [searchEmployee, setSearchEmployee] = useState("");

  // Debounce employee search to throttle api calls to backend
  const debouncedEmployeeSearch = debounce((value) => setSearchEmployee(value), 300);

  useEffect(() => {
    if (searchEmployee.length > 0) {
      dispatch(fetchEmployees(`/?search=${searchEmployee}`));
    } else {
      dispatch(fetchEmployees());
    }
  }, [searchEmployee, dispatch]);

  // const testingNameList = [
  //   { name: "Evelyn Stone", status: "Present" },
  //   { name: "Jasper Finch", status: "Absent" },
  //   { name: "Isla Hawthorne", status: "Leave" },
  //   { name: "Leo Mercer", status: "Absent" },
  //   { name: "Clara Whitfield", status: "Present" },
  //   { name: "Max Donovan", status: "Present" },
  //   { name: "Harper Ellington", status: "Present" },
  //   { name: "Felix Winters", status: "Present" },
  //   { name: "Amelia Frost", status: "Present" },
  //   { name: "Owen Lancaster", status: "Present" },
  // ];

  function handleDateChange(date) {
    setSelectedDate(changeDateFormat(date));
    setShowDateEditor(!showDateEditor);
  }

  function handleUpdateAttendance(value, attendance) {
    const status = attendance.status;
    let updatedStatus;

    if (status === "Present") {
      updatedStatus = "Absent";
    } else {
      updatedStatus = "Present";
    }
    const updatedAttendance = {
      ...attendance,
      employee: attendance?.employee?.id || attendance?.employee,
      date: selectedDate,
      status: updatedStatus,
    };
    dispatch(updateAttendance(updatedAttendance));
  }

  function handleCreateDateRecords() {
    setDisableDateRecordsButton(true);
    if (!selectedDate) {
      setDisableDateRecordsButton(false);
      return;
    }

    dispatch(createAllAttendances({ date: selectedDate })).then((action) => {
      if (action.payload?.length > 0) {
        setDisableDateRecordsButton(false);
        setCreateDateButton(false);
      }
    });
  }

  //Get employee by ID
  // function findEmployeeNameById(id) {
  //   if (employees.length > 0 && id) {
  //     const employee = employees.filter((employee) => employee.id === id);
  //     return employee[0]?.name;
  //   } else {
  //     return "loading...";
  //   }
  // }

  return (
    <div className="flex h-[100vh-80px]">
      {/* employee list section*/}
      <div className="flex-1 max-w-[30%] border-r-2 border-neutral-200">
        <div className="flex gap-[10px] border-b-2 border-neutral-200 p-[15px]">
          {/* //Search Bar */}
          <div className="flex w-full">
            <input
              type="text"
              className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
              placeholder="Search Employees..."
              onChange={(e) => debouncedEmployeeSearch(e.target.value)}
            />
            <button className="-ml-[40px]">
              <IconWrapper>
                <Search className="z-10 text-white"></Search>
              </IconWrapper>
            </button>
          </div>

          {/* add employee button */}
          <AddEmployee />
        </div>
        {/* Employee Container */}
        {employees?.length === 0 && <div className="text-center py-10 font-semibold">No Entries found!</div>}
        {employees?.length > 0 && (
          <div>
            <InfiniteScroll
              dataLength={employees.length}
              next={fetchNextEmployees}
              hasMore={hasMoreEmployees}
              height={"70vh"}
              loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
              endMessage={
                <p style={{ textAlign: "center", paddingTop: "10px" }}>
                  <b>Employee List End</b>
                </p>
              }
            >
              <ul className="flex flex-col p-[15px] gap-[10px]">
                {employees.map((employee, index) => (
                  <li
                    key={index}
                    className="w-full p-[12px] border-2 border-neutral-200 rounded-full cursor-pointer"
                    onClick={() => setSelectedEmployee(employee)}
                  >
                    <span>{index + 1}.</span>
                    <span className="ml-[5px]">{employee.name}</span>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </div>
        )}
      </div>
      {/* personal details section */}
      <div className="flex-1 h-[calc(100vh-78px)] max-w-[40%] border-r-2 border-neutral-200">
        <div className="h-[76px] border-b-2 flex items-center justify-center px-[15px]">
          <h2 className="text-xl">Personal Details</h2>
        </div>
        <div className="p-[15px] flex gap-[15px] border-b-2 border-neutral-200">
          {/* profile Picture container* - currently not being implemented */}
          {/* <div className="relative max-h-[250px] max-w-[200px]">
              <img
                className="rounded-[20px]"
                src="https://picsum.photos/400/400"
                alt={`profile of ${selectedEmployee.name}`}
              /> */}
          {/* Edit Image Button */}
          {/* <button
                className="absolute top-0 right-0 bg-black h-[35px] w-[35px] flex items-center justify-center rounded-full translate-x-[25%] -translate-y-[25%]"
                onClick={() => toast.error("edit button was clicked")}
              >
                <Edit2 className="text-white h-[20px] w-[20px]" />
              </button>
            </div> */}
          {/* Name, Role and Edit and Delete Button */}
          <div className="w-full flex flex-col gap-[10px] justify-center">
            <DetailContainer title={"Name"} value={selectedEmployee.name} />
            <DetailContainer title={"Role"} value={selectedEmployee.role} />
            {/* Edit and Delete Button container */}
            {employees?.length > 0 && (
              <div className="flex gap-[10px] mt-[10px]">
                <EditEmployee employee={selectedEmployee} />
                <RemoveEmployee employee={selectedEmployee} />
              </div>
            )}
          </div>
        </div>
        <div className="h-[57.9vh] overflow-y-scroll">
          <h3 className="text-neutral-400 w-full text-center my-[15px] font-semibold">
            Contact Information & Employee Details
          </h3>
          <div className="w-full overflow-y-scroll flex flex-col gap-[10px] px-[15px]">
            <DetailContainer title={"Gender"} value={selectedEmployee.gender} />
            <DetailContainer title={"Location"} value={selectedEmployee.location} />
            <DetailContainer title={"Email"} value={selectedEmployee.email} />
            <DetailContainer title={"Contact Number"} value={selectedEmployee.contact_number} />
            <DetailContainer title={"Number of Holiday"} value={selectedEmployee.holidays} />
            <DetailContainer title={"Address"} value={selectedEmployee.address} />
            <DetailContainer title={"Date of Joining"} value={selectedEmployee.date_of_joining} />
            <DetailContainer title={"Salary"} value={selectedEmployee.salary} />
          </div>
        </div>
      </div>
      {/* Attendance Sheet section*/}
      <div className="flex-1 max-w-[30%]">
        <div className="h-[76px] border-b-2 border-neutral-200 flex justify-between px-[15px] items-center">
          <span className="text-xl">Attendance Sheet</span>
          <div>
            {showDateEditor && (
              <input type="date" max={today} onChange={(event) => handleDateChange(event.target.value)} />
            )}
            {!showDateEditor && (
              <span className="text-neutral-500" onClick={() => setShowDateEditor(!showDateEditor)}>
                {humanDateFormat(selectedDate)}
              </span>
            )}
          </div>
        </div>
        {employees?.length === 0 && (
          <div className="text-center py-10 font-semibold">Start adding employees to see this panel!</div>
        )}
        {employees?.length > 0 && (
          <div>
            {createDateButton && (
              <div className="flex flex-col items-center my-[20px]">
                <span>{`No Attendance records found for ${
                  selectedDate ? "date : " + humanDateFormat(selectedDate) : "today"
                }`}</span>
                <button
                  onClick={() => handleCreateDateRecords()}
                  disabled={disableDateRecordsButton}
                  className="my-[20px] py-[10px] px-[20px] text-white bg-black rounded-full"
                >
                  Create Date Records
                </button>
              </div>
            )}
            {!createDateButton && (
              <InfiniteScroll
                dataLength={attendances.length}
                next={fetchNextAttendances}
                hasMore={hasMoreAttendances}
                height={"47vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Attendance List End</b>
                  </p>
                }
              >
                {attendances.map((attendance, index) => (
                  <div
                    key={attendance.id}
                    className="w-full flex items-center justify-between px-[15px] border-b-2 border-neutral-200"
                  >
                    <span>
                      <span>{index + 1}. </span>
                      <span>{attendance?.employee?.name}</span>
                    </span>
                    <CheckBox
                      checked={attendance?.status === "Present" ? true : false}
                      onChange={(e) => handleUpdateAttendance(e.target.value, attendance)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    {/* <CheckBox checked={attendance.status === "Present" ? true : false} /> */}
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Employees;
