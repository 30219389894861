import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createBatch, fetchProducts } from "../../../reducers/Slices/productSlice";

// pHaniver - TODO - move CustomModel to Macro Component
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";

//UI component
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import toast from "react-hot-toast";
import BarcodeInput from "./BarcodeInput";

function AddBatch({ product }) {
  //Modal toggler
  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //State to hold batch form data
  const [batchFormData, setBatchFormData] = useState({
    product_id: null,
    batches: [
      {
        size: null,
        total_quantity: 0,
        remaining_quantity: 0,
        sales_price: null,
        deal_quantity: 0,
        mrp_price: "",
        rate: "",
        purchase_price: "",
        tax: "",
        discount: "",
        add_margin: null,
        status: null,
        bar_code_number: null,
        hsn_number: null,
        batch_number: "",
        package: null,
        expiry_date: "",
        is_deleted: false,
      },
    ],
  });

  function handleAddBatchClick() {
    setShowModal(true);
  }

  function handleClose() {
    resetInitialValue();
    setShowModal(false);
    setDisableSubmitButton(false);
  }

  function resetInitialValue() {
    setBatchFormData({
      product_id: product.id,
      batches: [
        {
          size: null,
          total_quantity: 0,
          deal_quantity: 0,
          remaining_quantity: 0,
          sales_price: null,
          mrp_price: "",
          rate: "",
          purchase_price: "",
          tax: "",
          discount: "",
          add_margin: null,
          status: null,
          bar_code_number: null,
          hsn_number: null,
          batch_number: "",
          package: null,
          expiry_date: "",
          is_deleted: false,
        },
      ],
    });
  }

  useEffect(() => {
    const nextBatchFormData = { ...batchFormData, product_id: product.id };
    setBatchFormData(nextBatchFormData);
    //eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    setBatchFormData((prevState) => {
      const updatedBatches = prevState.batches.map((batch) => {
        return {
          ...batch,
          total_quantity: parseInt(batch?.remaining_quantity ?? 0) + parseInt(batch?.deal_quantity ?? 0),
        };
      });
      return { ...prevState, batches: updatedBatches };
    });
    //eslint-disable-next-line
  }, [batchFormData?.batches[0]?.remaining_quantity, batchFormData?.batches[0]?.deal_quantity]);

  const dispatch = useDispatch();

  function handleSubmit() {
    setDisableSubmitButton(true);
    dispatch(createBatch(batchFormData))
      .then((action) => {
        if (action.payload) {
          setShowModal(false);
          dispatch(fetchProducts());
          resetInitialValue();
          setDisableSubmitButton(false);
        } else {
          toast.error("Sorry, batch cannot be added");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  }

  function handleDateChange(e) {
    setBatchFormData({
      ...batchFormData,
      batches: [{ ...batchFormData.batches[0], expiry_date: e.target.value }],
    });
  }

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect for barcode.
  useEffect(() => {
    setBatchFormData((prevState) => {
      return { ...prevState, batches: [{ ...batchFormData.batches[0], bar_code_number: barcodeNumber }] };
    });
  }, [barcodeNumber]);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center">Batch Description</div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="batch-number" className="text-neutral-500">
              Batch Number*
            </label>
            <input
              type="text"
              id="batch-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.batch_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], batch_number: e.target.value }],
                })
              }
              required
            />
          </div>
          {/* <div>
            <label htmlFor="size">Size</label>
            <input
              type="text"
              id="size"
              value={batchFormData.batches[0].size}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batches: [{ ...batchFormData.batches[0], size: e.target.value }] })
              }
            />
          </div> */}
          {/* <div>
            <label htmlFor="total-quantity">Total Quantity</label>
            <input
              type="number"
              id="total-quantity"
              value={batchFormData.batches[0].total_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], total_quantity: e.target.value }],
                })
              }
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="remaining-quantity" className="text-neutral-500">
              Quantity*
            </label>
            <input
              type="number"
              id="remaining-quantity"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.remaining_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], remaining_quantity: parseInt(e.target.value) }],
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Deal/Free
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.deal}
              // onChange={(e) =>
              //   setBatchFormData({
              //     ...batchFormData,
              //     batches: [{ ...batchFormData.batches[0], deal: e.target.value }],
              //   })
              // }
              disabled
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="mrp-price" className="text-neutral-500">
              MRP Price*
            </label>
            <input
              type="text"
              id="mrp-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.mrp_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], mrp_price: e.target.value }],
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="sales-price" className="text-neutral-500">
              Sales Price*
            </label>
            <input
              type="text"
              id="sales-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batches[0].sales_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], sales_price: e.target.value }],
                })
              }
              required
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Rate A / Retail*
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.sales_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], sales_price: e.target.value }],
                })
              }
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="purchase-price" className="text-neutral-500">
              Purchase Price*
            </label>
            <input
              type="text"
              id="purchase-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.purchase_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], purchase_price: e.target.value }],
                })
              }
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="tax" className="text-neutral-500">
              Tax %
            </label>
            <input
              type="text"
              id="tax"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.tax}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], tax: e.target.value }],
                })
              }
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="discount" className="text-neutral-500">
              Discount %
            </label>
            <input
              type="text"
              id="discount"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.discount}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], discount: e.target.value }],
                })
              }
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="add-margin" className="text-neutral-500">
              Add Margin %
            </label>
            <input
              type="text"
              id="add-margin"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batches[0].add_margin}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], add_margin: e.target.value }],
                })
              }
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="bar-code-number" className="text-neutral-500">
              Bar Code Number*
            </label>
            <input
              type="text"
              id="bar-code-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batches[0].bar_code_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], bar_code_number: e.target.value }],
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              HSN Number*
            </label>
            <input
              type="text"
              id="hsn-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batches[0].hsn_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], hsn_number: e.target.value }],
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="package" className="text-neutral-500">
              Package*
            </label>
            <input
              type="text"
              id="package"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batches[0].package}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], package: e.target.value }],
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="status" className="text-neutral-500">
              Status*
            </label>
            <input
              type="text"
              id="status"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batches[0].status}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], status: e.target.value }],
                })
              }
              required
            />
          </div> */}
          {/* For Barcode  */}
          <div className="relative">
            <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="expiry-date" className="text-neutral-500">
              Expiry Date*
            </label>
            <input
              type="date"
              id="expiry-date"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.expiry_date}
              onChange={handleDateChange}
            />
          </div>
          <div className="flex gap-[20px] justify-center">
            <button
              className="save px-[20px] py-[10px] rounded-full bg-black text-white"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <ButtonWrapper eventCallback={() => handleAddBatchClick(true)}>+ Add Batch</ButtonWrapper>
      </div>
    </>
  );
}

export default AddBatch;
