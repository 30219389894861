import { combineReducers } from "redux";
import authSlice from "./Slices/authSlice";
import userSlice from "./Slices/userSlice";
import customerSlice from "./Slices/customerSlice";
import appSlice from "./Slices/appSlice";
import productSlice from "./Slices/productSlice";
import invoiceSlice from "./Slices/invoiceSlice";
import purchaseInvoiceSlice from "./Slices/purchaseInvoiceSlice";
import vendorSlice from "./Slices/vendorSlice";
import employeeSlice from "./Slices/employeeSlice";
import expenseSlice from "./Slices/expenseSlice";
import ledgerSlice from "./Slices/ledgerSlice";
import profitAndLossSlice from "./Slices/profitAndLossSlice";
import cashRegisterSlice from "./Slices/cashRegisterSlice";
import onlineOrderSlice from "./Slices/onlineOrderSlice";
import onlineStoreSlice from "./Slices/onlineStoreSlice";

const appReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  customer: customerSlice,
  vendor: vendorSlice,
  product: productSlice,
  invoice: invoiceSlice,
  purchaseInvoice: purchaseInvoiceSlice,
  app: appSlice,
  employee: employeeSlice,
  expense: expenseSlice,
  ledger: ledgerSlice,
  profitAndLoss: profitAndLossSlice,
  onlineStore: onlineStoreSlice,
  onlineOrder: onlineOrderSlice,
  cashRegister: cashRegisterSlice,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
