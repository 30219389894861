import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InventoryList from "../Inventory/InventoryComponents/InventoryList";
import LineCharForInventory from "../Inventory/InventoryComponents/LineCharForInventory";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
// import ActiveInventoryData from "../Inventory/InventoryComponents/ActiveInventoryData";
// import { Checkbox } from "@mui/material";
// import gstIcon from "../../assets/images/gst.jpg";

//Functional Imports
import EditCustomer from "./LedgerBookComponents/EditCustomer";
import DeleteCustomer from "./LedgerBookComponents/DeleteCustomer";
import EditVendor from "./LedgerBookComponents/EditVendor";
import DeleteVendor from "./LedgerBookComponents/DeleteVendor";

import { fetchCustomerAndVendor, fetchMoreCustomerAndVendor } from "../../reducers/Slices/ledgerSlice";
import { fetchInvoices, fetchSalesCompareAnalytics } from "../../reducers/Slices/invoiceSlice";
import { getVendorInvoices, purchaseMonthlyAnalyticsData } from "../../reducers/Slices/purchaseInvoiceSlice";

//For Customers
// import { fetchCustomers } from "../../reducers/Slices/customerSlice";
// import { fetchInvoiceByCustomerId, fetchSalesCompareAnalytics } from "../../reducers/Slices/invoiceSlice";
// import { fetchSalesCompareAnalytics } from "../../reducers/Slices/invoiceSlice";

//For Vendors
// import { fetchVendors } from "../../reducers/Slices/vendorSlice";
// import { fetchInvoiceByVendorId } from "../../reducers/Slices/invoiceSlice";

// TODO - Fetch Invoice by Vendor ID

//UI components
import { Filter, Search } from "react-feather";
import IconWrapper from "../MicroComponents/IconWrapper";
import LogPayment from "./LedgerBookComponents/LogPayment";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LedgerBook = () => {
  //Check is there is query
  const query = useQuery();
  const navigate = useNavigate();
  const user_name = query.get("user_name");

  const dispatch = useDispatch();
  const users = useSelector((state) => state.ledger.users.data);
  const usersNext = useSelector((state) => state.ledger.users.next);

  const [hasMoreUsers, setHasMoreUsers] = useState(() => (usersNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (usersNext) {
      setHasMoreUsers(true);
    } else {
      setHasMoreUsers(false);
    }
  }, [usersNext]);

  //fetch more expenses on scroll if available
  const fetchNextUsers = () => {
    if (usersNext) {
      dispatch(fetchMoreCustomerAndVendor(usersNext));
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (user_name) {
      setSearchTerm(user_name);
      navigate("/ledger-book", { replace: true });
    }
  }, [user_name]);

  //Base string for fetchCustomerAndVendor Reducer API
  const [stringQuery, setStringQuery] = useState("/");

  //Select Intitial User
  useEffect(() => {
    setSelectedUser(users[0]);
  }, [users]);

  useEffect(() => {
    setStringQuery(() => {
      let initialString = "/";

      //Guard Clause
      if (searchTerm.length === 0 && filter.length === 0) {
        return "/";
      }

      //Setting search term
      if (searchTerm.length > 0) {
        initialString += `?search=${searchTerm}`;
      }

      //Setting filter string
      if (searchTerm.length === 0 && filter.length > 0) {
        initialString += `?${filter}`;
      } else if (filter.length > 0) {
        initialString += `&${filter}`;
      }

      return initialString;
    });
  }, [filter, searchTerm]);

  //Debounced SearchTerm
  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  // const customers = useSelector((state) => state.customer.customers);
  // const vendors = useSelector((state) => state.vendor.vendors);

  // const [combinedUsers, setCombinedUsers] = useState([]);

  //Combine both vendors and customers
  // useEffect(() => {
  //   setCombinedUsers(() => {
  //     if (customers.length > 0 && vendors.length > 0) {
  //       return [...customers, ...vendors];
  //     } else if (customers.length > 0) {
  //       return [...customers];
  //     } else if (vendors.length > 0) {
  //       return [...vendors];
  //     } else {
  //       return [];
  //     }
  //   });
  // }, [customers, vendors]);

  const sales_analytics = useSelector((state) => state.invoice.sales_analytics);
  const purchase_analytics = useSelector((state) => state.purchaseInvoice.purchase_analytics);
  const invoices = useSelector((state) => state.invoice.invoices);
  const purchaseInvoices = useSelector((state) => state.purchaseInvoice.purchaseInvoices);
  // const customer_invoices = useSelector((state) => state.invoice.customer_invoices);
  // const vendor_invoices = useSelector((state) => state.invoice.vendor_invoices);
  // TODO - fetch Vendor Invoices
  // const [showAddUser, setShowAddUser] = useState(false);
  // const [currentCustomer, setCurrentCustomer] = useState({});
  // const [selectedUser, setSelectedUser] = useState(customers[0] || {});
  const [selectedUser, setSelectedUser] = useState(users[0]);
  const [showMenu, setShowMenu] = useState(false);

  //Fetch Customers and Vendors
  useEffect(() => {
    // dispatch(fetchCustomers());
    // dispatch(fetchVendors());
    dispatch(fetchCustomerAndVendor(stringQuery));
  }, [dispatch, stringQuery]);

  useEffect(() => {
    if (selectedUser?.id) {
      if (selectedUser?.type === "vendor") {
        dispatch(getVendorInvoices(`?vendor_id=${selectedUser.id}`));
        dispatch(purchaseMonthlyAnalyticsData(`?vendorId=${selectedUser.id}`));
      } else if (selectedUser?.type === "customer") {
        dispatch(fetchInvoices(`?customer_id=${selectedUser.id}`));
        dispatch(fetchSalesCompareAnalytics(selectedUser.id));
      }
    }
  }, [selectedUser, dispatch]);

  // const handleUpdateCustomer = (customer) => {
  //   setCurrentCustomer(customer);
  //   setShowAddUser(true);
  // };

  //handle Filter button Menu
  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    // if (user?.vendor_name?.length > 0) {
    //   setSelectedUser(user);
    //   dispatch(fetchInvoiceByVendorId(user.id));
    // } else if (user?.customer_name?.length > 0) {
    //   setSelectedUser(user);
    //   dispatch(fetchInvoiceByCustomerId(user.id));
    // }
  };

  //pHaniver - todo - remove if dosen't serve any purpose
  // const [selectedItem, setSelectedItem] = useState({});

  return (
    <div className="flex h-full">
      {/* Ledger Users */}
      <div className="w-[30%] h-full border-r-2 border-neutral-200">
        <div className="border-b-2 border-neutral-200 flex p-[15px] gap-[5px]">
          {/* Search Bar and Search Button */}
          <div className="w-full flex">
            <input
              type="text"
              className="border-2 rounded-full h-[44px] w-full p-[10px]"
              placeholder="Search Vendor or Customer..."
              onChange={(e) => debouncedSetSearchTerm(e.target.value)}
            />
            <button className="h-[44px] -ml-[42px]">
              <IconWrapper>
                <Search className="text-white z-10" />
              </IconWrapper>
            </button>
          </div>

          {/* Filter Button and Functionality */}
          <div className="relative">
            <button className="h-[44px]" onClick={() => handleShowMenu("settings")}>
              <IconWrapper>
                <Filter className="text-white z-10" />
              </IconWrapper>
            </button>
            <div
              id="dropdownInformation"
              onMouseLeave={() => handleShowMenu("settings")}
              className={`${
                showMenu !== "settings" ? "hidden" : ""
              } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
            >
              <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                <li>
                  <button onClick={() => setFilter("")} className="text-lg py-2 w-full border-b-2 border-neutral-200">
                    All
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setFilter("vendor_only=true")}
                    className="text-lg py-2 w-full border-b-2 border-neutral-200"
                  >
                    Only Vendor
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setFilter("customer_only=true")}
                    className="text-lg pt-2 border-b-2 w-full border-neutral-200"
                  >
                    Only Customer
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setFilter("name=descending")}
                    className="text-lg pt-2 border-b-2 w-full border-neutral-200"
                  >
                    Name: Descending
                  </button>
                </li>
                <li>
                  <button onClick={() => setFilter("favorite=1")} className="text-lg pt-2 w-full border-neutral-200">
                    Favorite only
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-[15px]">
          <InfiniteScroll
            dataLength={users.length}
            next={fetchNextUsers}
            hasMore={hasMoreUsers}
            height={"78vh"}
            loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
            endMessage={
              <p style={{ textAlign: "center", paddingTop: "10px" }}>
                <b>Customer/Vendor List End</b>
              </p>
            }
          >
            {users?.map((item, index) => (
              <div
                key={`${item.name}`}
                onClick={() => handleSelectUser(item)}
                className={`${
                  selectedUser?.id === item?.id ? "border-grey-200" : ""
                } border-2 hover:border-black focus:border-black mb-[10px] rounded-[20px] p-[15px] bg-white cursor-pointer`}
              >
                <div className=" relative flex pl-2 pb-2 gap-2">
                  <div className="text-xs xl:text-sm flex justify-between">
                    <h2 className="text-black text-md font-semibold flex flex-col">
                      <span className="text-lg truncate text-ellipses max-w-[9.5vw]">{item.name}</span>
                      <span className="text-neutral-500">
                        GST number: {item.gst_number ? item.gst_number.toUpperCase() : "Not Provided"}
                      </span>
                      <span className={`${item.type === "vendor" ? "text-blue-500" : "text-orange-500"}`}>
                        {item.type === "vendor" ? "(Vendor)" : "(Customer)"}
                      </span>
                    </h2>
                  </div>
                  <div className="absolute right-1 text-xs xl:text-sm flex justify-between">
                    <h2 className={`font-bold flex justify-center items-center ${item.gst_status && "-mt-2"}`}>
                      {/* <Link to={`/invoice/create/customer/${item.id}`} className='btn h-6 text-xs xl:text-sm  hover:bg-gray-300 !py-0 px-4 rounded-lg text-white bg-green-600'>Edit</Link> */}
                      {/* <button
                      type="button"
                      className="border-[1px] border-neutral-500 py-[2px] px-[10px] rounded-full"
                      onClick={() => toast.success("edit was clicked")}
                    >
                      Edit
                    </button> */}
                      {item.type === "vendor" ? <EditVendor vendor={item} /> : <EditCustomer customer={item} />}
                      {/* <button
                      type="button"
                      className="border-[1px] ml-[5px] border-red-500 text-red-500 py-[2px] px-[10px] rounded-full"
                      onClick={() => toast.success("delete was clicked")}
                    >
                      Delete
                    </button> */}
                      {item.type === "vendor" ? <DeleteVendor vendor={item} /> : <DeleteCustomer customer={item} />}
                      {/* {item.gst_status && (
                      <button
                        type="button"
                        className="w-12 text-xs hover:bg-gray-300 p-2 ml-1 bg-transparent hover:bg-transparent "
                      >
                        <img src={gstIcon} alt="GST" srcset="" />
                      </button>
                    )} */}
                      {/* <Checkbox
                                                    className='w-11'
                                                    checked={item.favourite}
                                                    icon={<img src={FavoriteBorderIcon} alt="" srcset="" />}
                                                    checkedIcon={<img src={FavoriteIcon} alt="" srcset="" />}
                                                /> */}
                    </h2>
                  </div>
                </div>
                <div className="relative flex px-2 pt-2  text-nowrap whitespace-nowrap">
                  <div className="text-xs xl:text-sm flex justify-between">
                    <h2 className="font-semibold">
                      <span className="text-neutral-500">{item.phone_number}</span>
                    </h2>
                  </div>
                  <div className="absolute right-2 text-xs xl:text-sm flex justify-between">
                    <h2 className="font-semibold">
                      Total Amount: <span className="text-neutral-500">₹{item.all_grand_total || 0.0}</span>
                    </h2>
                  </div>
                </div>
                <div className="mt-0.5 relative flex p-2 text-nowrap whitespace-nowrap">
                  {item.gst_status && (
                    <div className="text-xs xl:text-sm flex justify-between">
                      <h2 className="font-semibold">
                        GST : <span className="text-neutral-500">{item.gst_number}</span>
                      </h2>
                    </div>
                  )}
                  <div
                    className={`absolute right-2 ${
                      item.gst_status ? "" : "top-0"
                    } text-xs xl:text-sm flex justify-between`}
                  >
                    <h2 className="font-semibold">
                      Credit Amount: <span className="text-neutral-500">₹{item.all_remaining || 0.0}</span>
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      {/* Selected User Data */}
      <div className="w-[45%] border-r-2">
        <div>
          <div className="border-b-2 px-[20px] py-[20px]">
            {/* <ActiveInventoryData data={selectedUser} /> */}
            <div className="pb-[20px] flex justify-between items-center">
              <h2 className="text-3xl max-w-[35vw]">{selectedUser?.name}</h2>
              {selectedUser?.type === "customer" && <LogPayment customer={selectedUser} />}
              {/* <div onClick={handleOpenAddItemModal}>Edit</div> */}
            </div>
            {selectedUser?.type === "customer" && (
              <div>
                <div className="font-semibold">
                  Total Sale: <span className="text-gray-500 font-regular">₹{sales_analytics?.total_sales || 0}</span>
                </div>
                <div className="font-semibold">
                  Total Credit:{" "}
                  <span className="text-gray-500 font-regular">₹{sales_analytics?.total_credit || 0}</span>
                </div>
                <div className="font-semibold">
                  Total Cash: <span className="text-gray-500 font-regular">₹{sales_analytics?.total_cash || 0}</span>
                </div>
                <div className="font-semibold">
                  Outstanding Balance:{" "}
                  <span className="text-red-500 font-regular">₹{sales_analytics?.total_outstanding || 0}</span>
                </div>
              </div>
            )}
            {selectedUser?.type === "vendor" && (
              <div>
                <div className="font-semibold">
                  Total Purchase:{" "}
                  <span className="text-gray-500 font-regular">₹{purchase_analytics?.total_purchase || 0}</span>
                </div>
                <div className="font-semibold">
                  Total Credit:{" "}
                  <span className="text-gray-500 font-regular">₹{purchase_analytics?.total_credit || 0}</span>
                </div>
                <div className="font-semibold">
                  Total Cash: <span className="text-gray-500 font-regular">₹{purchase_analytics?.total_cash || 0}</span>
                </div>
                <div className="font-semibold">
                  Outstanding Balance:{" "}
                  <span className="text-gray-500 font-regular">₹{purchase_analytics?.total_outstanding || 0}</span>
                </div>
              </div>
            )}
          </div>
          {/* Graph Container */}
          <div className="py-[20px]">
            <div className="text-center w-full py-[10px] text-lg text-neutral-500">
              {`Comparison of ${selectedUser?.type === "vendor" ? "purchase" : "sale"} of ${
                selectedUser?.name
              } to the last month`}
            </div>
            <LineCharForInventory
              data={selectedUser?.type === "customer" ? sales_analytics : purchase_analytics}
              type={selectedUser?.type}
            />
          </div>
        </div>
      </div>

      {/* inventory list container */}
      <div className="w-[25%]">
        <InventoryList
          type={selectedUser?.type}
          setSelectedItem={setSelectedUser}
          activeItem={selectedUser}
          invoices={selectedUser?.type === "vendor" ? purchaseInvoices : invoices}
          title={selectedUser?.name}
        />
      </div>
    </div>
  );
};

export default LedgerBook;
