import React from 'react'

const GSTFilings = () => {
  return (
    <div>
      GST Filings
    </div>
  )
}

export default GSTFilings
