// import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBatchesByProductID,
  fetchMoreProducts,
  fetchProductByBarcode,
  fetchProducts,
  searchProducts,
} from "../../reducers/Slices/productSlice";
import { fetchCustomers, fetchMoreCustomers } from "../../reducers/Slices/customerSlice";
import { createInvoice } from "../../reducers/Slices/invoiceSlice";
import toast from "react-hot-toast";

//UI Components
import IconWrapper from "../MicroComponents/IconWrapper";
import { X as Cross } from "react-feather";
import { getOrderDetailsByID } from "../../reducers/Slices/onlineOrderSlice";
import { ShipmentDispatchForm } from "./InvoiceComponents/ShipmentDispatchForm";
import BarcodeReader from "./InvoiceComponents/BarcodeReader";
import { debounce } from "lodash";

function CreateEInvoice() {
  const [items, setItems] = useState([{}]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  //Fetch both customer and vendor based on ID provided
  const customer = useSelector((state) => state.customer.customers.data.find((c) => c.id === parseInt(params.id)));
  const customersNext = useSelector((state) => state.customer.customers.next);
  // const vendor = useSelector((state) => state.vendor.vendors.data.find((v) => v.id === parseInt(params.id)));
  const business = useSelector((state) => state.user);

  const products = useSelector((state) => state.product.products.data);
  // const productsNext = useSelector((state) => state.product.products.next);

  //Dispatch and shipment details
  const [dispatchDetails, setDispatchDetails] = useState({});
  const [shipmentDetails, setShipmentDetails] = useState({});

  //Side effect to set shipping and dispatch details based on customer and business data
  useEffect(() => {
    if (!customer || !business) {
      return;
    }

    console.log(business, "this is the business");

    const {
      business_name,
      address1: business_address1,
      address2: business_address2,
      city: business_city,
      zipcode: business_zipcode,
      state_code: business_state_code,
      country_code: business_country_code,
    } = business;
    const {
      customer_name,
      address: customer_address1,
      address2: customer_address2,
      zipcode: customer_zipcode,
      city: customer_city,
      state_code: customer_state_code,
    } = customer;
    // console.log(business, "this is the business data");
    setShipmentDetails(() => {
      return {
        shipment_name: customer_name,
        shipment_address1: customer_address1,
        shipment_address2: customer_address2 || "",
        shipment_city: customer_city,
        shipment_pin: customer_zipcode,
        shipment_state_code: customer_state_code,
      };
    });
    setDispatchDetails(() => {
      return {
        dispatch_name: business_name,
        dispatch_address1: business_address1,
        dispatch_address2: business_address2 || "",
        dispatch_city: business_city,
        dispatch_pin: business_zipcode,
        dispatch_state_code: business_state_code,
        country_code: business_country_code,
      };
    });
  }, [customer, business]);

  //A relic from past currently depricated
  const [userType, setUserType] = useState((type) => (parseInt(params.type) === 1 ? "vendor" : "customer"));

  //--- Order related operations and functionality ---
  const [orderId, setOrderId] = useState(() => parseInt(params.orderId) || 0);
  const orderDetails = useSelector((state) => state.onlineOrder.orderDetails);
  const [remainingProductQuantity, setRemainingProductQuantity] = useState([]);
  const [ordererdProductQuantity, setOrderedProductQuantity] = useState([]);

  useEffect(() => {
    //if order ID is zero that means it is simple invoice not order converted to invoice
    if (orderId === 0) {
      return;
    }
    dispatch(getOrderDetailsByID(orderId));
  }, [orderId]);

  useEffect(() => {
    if (orderId === 0) {
      return;
    }

    if (!orderDetails && orderDetails?.length === 0) {
      return;
    }

    const orderedQuantity = [];

    setDiscountOnMRPValue((prev) => []);
    setCalculatedAmountValue((prev) => []);

    const productData = orderDetails?.items?.map((item) => {
      orderedQuantity.push({ quantity: item.quantity, productId: item.product.id, batchId: null });
      const product = findProductByProductID(item.product.id);
      setDiscountOnMRPValue((prev) => [...prev, {}]);
      setCalculatedAmountValue((prev) => [...prev, {}]);
      return { ...product, quantity: item.quantity };
    });

    setOrderedProductQuantity(orderedQuantity);
    setItems(productData);
  }, [orderDetails]);

  const orderedProductQuantityOperation = (batch, product, index) => {
    const batchQuantity = batch?.remaining_quantity;

    //Check if invoices is ordered invoice and order details are available
    if (orderId === 0) {
      return;
    }

    if (!orderDetails && orderDetails?.length === 0) {
      return;
    }
    // Extra Item options - required
    const itemOptions = {
      discount: batch?.discount,
      batch_number: batch?.batch_number,
      batchId: batch?.id,
      hsnNumber: getHSNNumberByBatchId(batch?.id),
      sales_price: batch?.sales_price,
      mrp_price: batch?.mrp_price,
      tax: batch?.tax,
      purchase_price: batch?.purchase_price,
      margin: batch?.add_margin,
      remaining_quantity: batch?.remaining_quantity,
    };

    //Find product and associated ordered quantity
    const orderedQuantity = ordererdProductQuantity.find((item) => item.productId === product.id);

    let updatedItems = items.map((item, i) => {
      if (index === i) {
        return { ...item, ...itemOptions };
      }
      return item;
    });

    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].batchId) {
        handleDiscountOnMRPChange(null, i, false, {
          mrp: updatedItems[i]?.mrp_price,
          tax_percent: updatedItems[i]?.tax,
          sales_price: updatedItems[i]?.sales_price,
        });
      }
    }

    //Check if product already exists in remainging quantity array i.e it contains remaining data
    const remainingProductIndex = remainingProductQuantity.findIndex((item) => item.id === product.id);

    if (remainingProductIndex >= 0) {
      //Check if remaining quantity is satisfied
      if (remainingProductQuantity[remainingProductIndex].remaining_quantity <= 0) {
        return;
      }
      //If product exists then check if new max batch quantity is enough for product
      if (batchQuantity < remainingProductQuantity[remainingProductIndex].remaining_quantity) {
        toast.error("Selected batch dosen't have enough quantity to satisfy order!");
        updatedItems[index].quantity = batchQuantity;

        //Add new product into items array with remaining quantity
        const newProductWithRemainingQuantity = {
          ...product,
          quantity: remainingProductQuantity[remainingProductIndex].remaining_quantity - batchQuantity,
        };
        setDiscountOnMRPValue((prev) => [...prev, {}]);
        setCalculatedAmountValue((prev) => [...prev, {}]);
        updatedItems.splice(index + 1, 0, newProductWithRemainingQuantity);

        // update quantity in remaining quantity array
        setRemainingProductQuantity((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === product.id) {
              return {
                ...item,
                remaining_quantity: remainingProductQuantity[remainingProductIndex].remaining_quantity - batchQuantity,
              };
            }
            return item;
          });
        });
      } else {
        updatedItems[index].quantity = remainingProductQuantity[remainingProductIndex].remaining_quantity;
        // update quantity in remaining quantity array
        setRemainingProductQuantity((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === product.id) {
              return {
                ...item,
                remaining_quantity: 0,
              };
            }
            return item;
          });
        });
      }
      setItems(updatedItems);
      return;
    }

    //Add product to items array and track remaining quantity data
    if (batchQuantity < orderedQuantity.quantity) {
      toast.error("Selected batch dosen't have enough quantity to satisfy order!");
      //add maximum avaible quantity of batch inside product
      updatedItems[index].quantity = batchQuantity;
      //Add new product into items array with remaining quantity
      const newProductWithRemainingQuantity = { ...product, quantity: orderedQuantity.quantity - batchQuantity };
      setDiscountOnMRPValue((prev) => [...prev, {}]);
      setCalculatedAmountValue((prev) => [...prev, {}]);
      updatedItems.splice(index + 1, 0, newProductWithRemainingQuantity);

      //add this product into remaingProductQuantity array for tracking remaining quantity
      setRemainingProductQuantity((prevItems) => {
        return [
          ...prevItems,
          { id: newProductWithRemainingQuantity.id, remaining_quantity: newProductWithRemainingQuantity.quantity },
        ];
      });
    }

    setItems(updatedItems);
  };
  // --- Order Related Operations End ---

  const [discountOnMRPValue, setDiscountOnMRPValue] = useState([{}]);
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return { customer: parseInt(params.id), payment_option: "cash", payment_type: "paid" };
  });

  //Fetch customers till end
  useEffect(() => {
    if (customersNext) {
      dispatch(fetchMoreCustomers(customersNext));
    }
  }, [customersNext, dispatch]);

  const navigate = useNavigate();

  //Payment Options
  const paymentOptions = [
    { label: "Cash", value: "cash" },
    { label: "UPI", value: "upi" },
    { label: "Credit", value: "credit" },
  ];

  //Fetch all products
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchProducts());
    }
  }, [dispatch]);

  //Fetch infinity products
  // useEffect(() => {
  //   if (productsNext) {
  //     dispatch(fetchMoreProducts(productsNext));
  //   }
  // }, [productsNext]);

  useEffect(() => {
    handleCalculate();
  }, [items, discountOnMRPValue, calculatedAmountValue]);

  //Side effect to reverse calculate rate based on discountOnMRP change
  useEffect(() => {
    // ~~ changes here
    //This side effect is working for both product based item and service based item
    const updatedItems = items.map((item, index) => {
      if (item?.is_service === "N") {
        return {
          ...item,
          sales_price: calculateNetRate(item?.mrp_price, discountOnMRPValue[index]?.discountOnMRP, item?.tax),
        };
      } else {
        return {
          ...item,
          rate: calculateNetRate(item?.mrp, discountOnMRPValue[index]?.discountOnMRP, item?.tax),
        };
      }
    });
    setItems(updatedItems);
  }, [discountOnMRPValue]);

  useEffect(() => {
    handleCalculateAmount(items);
  }, [items, discountOnMRPValue]);

  const handleChangeItem = (value, index) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          return value ? { ...value, price: value.sales_price, batches: value.batches } : { price: 0.0, tax: 0.0 };
        }
        return item;
      });
      return updatedItems;
    });
  };

  //Profit Margin for user
  const [profitMargin, setProfitMargin] = useState(0);

  //Function to reverse calculate netRate based on new discountOnMRPercent
  const calculateNetRate = (mrp, discountOnMRPPercent, taxPercent) => {
    //calculate the rate after discount
    const discountedRate = parseFloat(mrp) - parseFloat(mrp) * ((parseFloat(discountOnMRPPercent) || 0) / 100);
    const netRate = discountedRate / (1 + (parseFloat(taxPercent) || 0) / 100);

    return netRate.toFixed(3).slice(0, -1);
  };

  const handleCalculate = () => {
    if (items?.length <= 0 || !items) {
      return;
    }

    const { total, discount, tax, profit } = items.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.sales_price || currentItem?.sales_price == 0) {
          const quantity = parseInt(currentItem.quantity) || 0;
          let item_total = parseFloat(currentItem.sales_price || 0) * quantity;
          let item_mrp_total = parseFloat(currentItem.mrp_price || 0) * quantity;
          let item_discount = (parseFloat(discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);
          let item_profit =
            (calculateNetRate(currentItem?.mrp_price, discountOnMRPValue[index]?.discountOnMRP, currentItem?.tax) -
              (currentItem?.purchase_price ?? 0)) *
            quantity;

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
          accumulator.profit += item_profit;
        }

        // ~~ changes here
        if (currentItem?.rate || currentItem?.rate == 0) {
          const quantity = 1;
          let item_total = parseFloat(currentItem.rate || 0) * quantity;
          let item_mrp_total = parseFloat(currentItem.mrp || 0) * quantity;
          let item_discount = (parseFloat(discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);
          let item_profit =
            (calculateNetRate(currentItem?.mrp, discountOnMRPValue[index]?.discountOnMRP, currentItem?.tax) -
              (currentItem?.cost ?? 0)) *
            quantity;

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
          accumulator.profit += item_profit;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0, profit: 0 }
    );

    setProfitMargin(profit);

    //Changes here
    const productAndQuantity = [];
    const serviceAndQuantity = [];

    items.forEach((i, index) => {
      if (i?.is_service === "N") {
        productAndQuantity.push({
          productId: i.id,
          quantity: parseInt(i.quantity) || 0,
          deal_quantity: parseInt(i.deal_quantity) || 0,
          total_quantity: (parseInt(i.quantity) || 0) + (parseInt(i.deal_quantity) || 0),
          batchId: i.batchId,
          amount: calculatedAmountValue[index]?.calculated_amount,
          mrp_discount: discountOnMRPValue[index]?.discountOnMRP || 0,
          discount: parseFloat(discountOnMRPValue[index]?.discountOnMRP / 100) * i.mrp_price * i.quantity,
          assessable_amount: parseFloat(i?.sales_price) * parseInt(i.quantity),
          tax: ((parseFloat(i?.sales_price) * parseFloat(i.tax)) / 100) * parseInt(i.quantity),
        });
      } else {
        serviceAndQuantity.push({
          productId: i.id,
          quantity: 1,
          amount: calculatedAmountValue[index]?.calculated_amount,
          mrp_discount: discountOnMRPValue[index]?.discountOnMRP || 0,
          discount: parseFloat(discountOnMRPValue[index]?.discountOnMRP / 100) * i.mrp * 1,
          assessable_amount: parseFloat(i?.rate) * 1,
          other_charges: 0,
          tax: ((parseFloat(i?.rate) * parseFloat(i.tax)) / 100) * 1,
        });
      }
    });

    setFormData({
      ...formData,
      sub_total: (total + discount).toFixed(2),
      discount: discount.toFixed(2),
      grand_total: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      tax: tax.toFixed(3),
      paid_amount: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      payment_type: formData.payment_type,
      remaining_total: 0.0,
      product_and_quantity: productAndQuantity,
      service_and_quantity: serviceAndQuantity,
    });
  };

  const handleFieldChange = (value, index, field) => {
    setItems((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  //Calculate discount on MRP
  const handleDiscountOnMRPChange = (value, index, isDiscount, data) => {
    // ~~change here - this comment
    //Same function working for both product and service based invoices
    //If user backspaces all value then make field empty.
    if (!value) {
      setDiscountOnMRPValue((prevItems) => {
        return prevItems.map((item, i) => {
          if (i === index) {
            return { discountOnMRP: nextDiscountOnMRP }; // Update the specified field of the item
          }
          return item;
        });
      });
    }

    let nextDiscountOnMRP;

    //Calculate Discount On MRP if DiscountMRP is not directly given
    if (!isDiscount) {
      const nextValue = calculateDiscountOnMRP(data);
      nextDiscountOnMRP = nextValue ? nextValue : null;
    } else {
      nextDiscountOnMRP = value;
    }

    //Guard to check if nextDiscountOnMRP is not null
    if (!nextDiscountOnMRP) {
      return 0;
    }

    setDiscountOnMRPValue((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { discountOnMRP: nextDiscountOnMRP }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  //Function to calculate discount on mrp based on mrp and tax percent
  const calculateDiscountOnMRP = (data) => {
    // ~~change here - this comment
    //Same function working for both product and service based invoices
    if (!data?.tax_percent || !data?.mrp || !data?.sales_price) {
      return null;
    }
    // Convert tax percent to decimal
    const taxDecimal = parseFloat(data?.tax_percent ?? 0) / 100;

    const taxedRate = parseFloat(data?.sales_price ?? 0) + parseFloat(data?.sales_price ?? 0) * taxDecimal;

    // Calculate the discount on MRP
    const discountOnMRPPercent = (parseFloat(data?.mrp ?? 0) - taxedRate) / parseFloat(data?.mrp ?? 0);

    const discountOnMRP = parseFloat((discountOnMRPPercent * 100).toFixed(3));
    return discountOnMRP;
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }

    if (discountOnMRPValue.length > 1) {
      setDiscountOnMRPValue((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }

    if (calculatedAmountValue.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }
  };

  const handleCalculateAmount = (items) => {
    if (!items) {
      return;
    }

    if (items?.length <= 0) {
      return;
    }

    if (!items[0]?.id) {
      return;
    }

    setCalculatedAmountValue((prevValue) => {
      const updatedCalculatedAmountValue = prevValue?.map((_, index) => {
        if (_?.checkedByUser === true) {
          return _;
        }

        // ~~ changes here
        let mrp_price, sales_price, quantity, tax;

        // ~~ changes here
        //Assign values based on nature of product i.e service or goods
        if (items[index].is_service === "Y") {
          mrp_price = items[index].mrp;
          sales_price = items[index].rate;
          quantity = 1;
          tax = items[index].tax;
        } else {
          mrp_price = items[index].mrp_price;
          sales_price = items[index].sales_price;
          quantity = items[index].quantity;
          tax = items[index].tax;
        }

        // const discountOnMRPPresent = parseFloat(discountOnMRPValue[index]?.discountOnMRP ?? 0);

        if (!sales_price || !quantity || !tax) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        const parsedQuantity = parseInt(quantity);
        // const parsedTax = parseFloat(tax) / 100;

        if (sales_price === 0 || parsedQuantity === 0) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        // let amount = (parseFloat(mrp_price) - parseFloat(mrp_price) * (discountOnMRPPresent / 100)) * parsedQuantity;
        let amount = (parseFloat(sales_price) + (parseFloat(sales_price) * parseFloat(tax)) / 100) * parsedQuantity;

        //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
        const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

        return { calculated_amount: truncatedAmount, checkedByUser: false };
      });
      return updatedCalculatedAmountValue;
    });
  };

  //Change amount value when user manually changes it based on index
  const handleAmountValueChange = (value, index) => {
    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (index === i) {
          return { calculated_amount: value, checkedByUser: true };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });
  };

  //Function to calculate total quantities
  function getProductQuantities(data) {
    const productAndQuantity = data.product_and_quantity;

    // Create a map to store the total quantities by productId
    const quantityMap = productAndQuantity.reduce((acc, item) => {
      // If the productId is already in the map, add the current quantity to the total
      if (acc[item.productId]) {
        acc[item.productId] += item.quantity;
      } else {
        // Otherwise, set the initial quantity
        acc[item.productId] = item.quantity;
      }
      return acc;
    }, {});

    // Convert the map to an array of objects with productId and totalQuantity
    return Object.keys(quantityMap).map((productId) => ({
      productId: parseInt(productId, 10),
      totalQuantity: quantityMap[productId],
    }));
  }

  const handleSubmit = async () => {
    let updatedFormData = formData;
    setDisableSubmitButton(true);
    //Check to see both quantity and deal quantity is not zero per product
    const hasInvalidProduct = formData.product_and_quantity.some(
      (item) => item.quantity === 0 && item.deal_quantity === 0
    );

    if (dispatchDetails && shipmentDetails) {
      updatedFormData = { ...formData, ...dispatchDetails, ...shipmentDetails };
    }

    if (orderId !== 0) {
      updatedFormData = { ...formData, order_id: orderId };
    }

    if (hasInvalidProduct) {
      toast.error("both quantity or deal quantity cannot be 0 in product");
      setDisableSubmitButton(false);
      return;
    }

    const productAndQuantity = updatedFormData.product_and_quantity;

    const hasDuplicateProducts = productAndQuantity.some((item, index) => {
      const duplicates = productAndQuantity.filter((otherItem, otherIndex) => {
        return otherIndex !== index && item.productId === otherItem.productId && item.batchId === otherItem.batchId;
      });

      return duplicates.length >= 1;
    });

    if (hasDuplicateProducts) {
      toast.error("There are two or more products with the same productId and batchId.");
      setDisableSubmitButton(false);
      return;
    }
    if (orderId !== 0) {
      const productQuantities = getProductQuantities(updatedFormData);
      let matchProductStatusMet = true;

      ordererdProductQuantity.forEach((orderedProduct) => {
        const matchedProduct = productQuantities.find((product) => product.productId === orderedProduct.productId);

        if (matchedProduct) {
          if (matchedProduct.totalQuantity > orderedProduct.quantity) {
            toast.error(`Ordered product has more quantity than ordered.`);
            matchProductStatusMet = false;
            setDisableSubmitButton(false);
          } else if (matchedProduct.totalQuantity < orderedProduct.quantity) {
            toast.error(`Ordered product has less quantity than ordered.`);
            matchProductStatusMet = false;
            setDisableSubmitButton(false);
          }
        } else {
          toast.error(`Ordered product is not found in the product quantities.`);
          matchProductStatusMet = false;
          setDisableSubmitButton(false);
        }
      });

      if (!matchProductStatusMet) {
        return;
      }
    }

    console.log(updatedFormData, "this is the updated form data");
    setDisableSubmitButton(false);

    // if (items[0]?.id) {
    //   await dispatch(createInvoice(updatedFormData))
    //     .then((action) => {
    //       if (action.payload) {
    //         navigate(-1);
    //         setDisableSubmitButton(false);
    //       } else {
    //         toast.error("sorry, Invoice cannot be created!");
    //         setDisableSubmitButton(false);
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error("error", err);
    //       setDisableSubmitButton(false);
    //     });
    // } else {
    //   toast.error("Add more products");
    //   setDisableSubmitButton(false);
    // }
  };

  //Find HSN number based on batch selected
  const getHSNNumberByBatchId = (batchId) => {
    const product = products.find((p) => p.batches.some((batch) => batch.id === batchId));
    if (product) {
      const batch = product.batches.find((batch) => batch.id === batchId);
      return batch ? batch.hsn_number : null;
    }
    return null;
  };

  //Find product by product id for autocomplete
  const findProductByProductID = (id) => {
    if (!id) {
      return;
    }
    return products.find((product) => product.id === id);
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row

              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                if (window.confirm("want to add another item?")) {
                  addAnotherItemRef.current.focus(); // Focus on add another item button
                } else {
                  submitButton.current.focus(); //Focus on submit button
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  //Barcode related operations

  // //using ref to add event listener to capture barcode;
  // const dummy_input_ref = useRef(null);
  // const Invoice_table_ref = useRef(null);

  // useEffect(() => {
  //   // Function to focus the dummy input if no other element is focused

  //   // Function to shift focus to the dummy input
  //   const focusDummyInput = () => {
  //     if (
  //       document.activeElement === document.body ||
  //       !(
  //         document.activeElement instanceof HTMLInputElement ||
  //         document.activeElement instanceof HTMLTextAreaElement ||
  //         document.activeElement instanceof HTMLSelectElement ||
  //         document.activeElement instanceof HTMLButtonElement
  //       )
  //     ) {
  //       dummy_input_ref.current.focus();
  //     }
  //   };

  //   // Initially focus on the dummy input
  //   dummy_input_ref.current.focus();

  //   // Add event listener for when focus shifts away from the dummy input
  //   const handleFocusOut = () => {
  //     setTimeout(() => {
  //       focusDummyInput();
  //     }, 0); // Small delay to ensure other inputs can take focus if clicked
  //   };

  //   // Add event listeners for focus and focusout
  //   document.addEventListener("focusout", handleFocusOut);
  //   document.addEventListener("focusin", focusDummyInput);

  //   // Clean up on component unmount
  //   return () => {
  //     document.removeEventListener("focusout", handleFocusOut);
  //     document.removeEventListener("focusin", focusDummyInput);
  //   };
  // }, []);

  // const handleDummyInputValueChange = (value) => {
  //   console.log(value, "this is the dummy input value");
  // };

  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
    some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  const handleAddCapturedProductsInItems = (product) => {
    if (!product?.id) {
      return;
    }

    //Get batches from inventory
    const inventoryBatchesData = products.find((productInventory) => productInventory?.id === product?.id)?.batches;

    const productWithBatches = inventoryBatchesData
      ? { ...product, batches: inventoryBatchesData }
      : { ...product, batches: [] };

    const ItemAlreadyPresent = items.findIndex((item) => item?.id === product?.id);
    const BatchAlreadyPresent = items.findIndex((item) => item?.batchId === product?.batchId);

    let updatedItems = items;

    //Case one product is present but is service then add quantity, if it is present and has batch add quantity
    //otherwise add new product.

    if (ItemAlreadyPresent && updatedItems[ItemAlreadyPresent]?.is_service === "Y") {
      if (!updatedItems[ItemAlreadyPresent]?.quantity) {
        //Add quantity variable if not already present
        updatedItems[ItemAlreadyPresent] = {
          ...updatedItems[ItemAlreadyPresent],
          quantity: 0,
        };
      }
      const previousQuantity = parseInt(updatedItems[ItemAlreadyPresent].quantity);
      updatedItems[ItemAlreadyPresent].quantity = previousQuantity + 1;
    } else if (BatchAlreadyPresent >= 0) {
      if (!updatedItems[BatchAlreadyPresent]?.quantity) {
        //Add quantity variable if not already present
        updatedItems[BatchAlreadyPresent] = {
          ...updatedItems[BatchAlreadyPresent],
          quantity: 0,
        };
      }

      const previousQuantity = parseInt(updatedItems[BatchAlreadyPresent].quantity);
      updatedItems[BatchAlreadyPresent].quantity = previousQuantity + 1;
    } else {
      if (!items[0]?.id) {
        updatedItems = [productWithBatches];
      } else {
        updatedItems = [...items, productWithBatches];
        setDiscountOnMRPValue((prev) => [...prev, {}]);
        setCalculatedAmountValue((prev) => [...prev, {}]);
      }
    }

    updatedItems.forEach((value, index) => {
      if (value?.is_service === "Y") {
        handleDiscountOnMRPChange(null, index, false, {
          mrp: value?.mrp,
          tax_percent: value?.tax,
          sales_price: value?.rate,
        });
      } else {
        handleDiscountOnMRPChange(null, index, false, {
          mrp: value?.mrp_price,
          tax_percent: value?.tax,
          sales_price: value?.sales_price,
        });
      }
    });

    setItems(updatedItems);
  };

  //Side effect to rearrange data of captured product;
  useEffect(() => {
    //If product dosen't exist then return
    if (!capturedProduct?.id) {
      return;
    }

    //If batch data exists then extract id because id field is already taken in product
    const batchData = capturedProduct?.batch;

    // const batchId = capturedProduct?.batch?.id;

    //Was causing weird bugs in react, so followed a different approach
    // if (batchData) {
    //   delete batchData?.id;
    //   delete capturedProduct?.batch;
    // }

    let rearrangedProductData = {};

    if (batchData) {
      rearrangedProductData = {
        ...capturedProduct,
        quantity: 1,
        ...batchData,
        batchId: batchData.id,
        id: capturedProduct.id,
      };
    } else {
      rearrangedProductData = { ...capturedProduct, quantity: 1 };
    }

    handleAddCapturedProductsInItems(rearrangedProductData);
  }, [capturedProduct]);

  //batch for autocomplete - pass batch if you have batch or null
  const handleBatchForAutoComplete = (product) => {
    const productBatches = getBatchesByProductId(product?.id);
    const specificBatch = productBatches?.find((batch) => batch?.id === product?.batchId);
    if (specificBatch) {
      return specificBatch;
    } else {
      return null;
    }
  };

  //Handle Product Search in Autocomplete

  //Searched products
  const searchedProducts = useSelector((state) => state.product.searchedProducts.data);

  //Debounced search term state to call searched product API
  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 500);

  useEffect(() => {
    console.log(productSearchTerm, "this is the product search term");
    dispatch(searchProducts(productSearchTerm));
  }, [productSearchTerm]);

  useEffect(() => {
    console.log(searchedProducts, "these are searched products");
  }, [searchedProducts]);

  //Debounced batch search term state to call batches search API
  const [batchSearchTerm, setBatchSearchTerm] = useState("");
  const [productIdForSearchedBatch, setProductIdForSearchedBatch] = useState(null);

  const handleBatchSearch = (productId, value) => {
    setDebouncedBatchSearchTerm(value);
    setProductIdForSearchedBatch(productId);
  };

  const setDebouncedBatchSearchTerm = debounce((value) => setBatchSearchTerm(value), 500);

  useEffect(() => {
    if (!productIdForSearchedBatch) {
      return;
    }

    if (!batchSearchTerm) {
      return;
    }

    dispatch(
      fetchBatchesByProductID({ productId: productIdForSearchedBatch, filters: `?batch_search=${batchSearchTerm}` })
    );
  }, [batchSearchTerm]);

  const getBatchesByProductId = (id) => {
    const product = products?.find((product) => product?.id === id);
    if (product) {
      return product?.batches;
    }
  };

  return (
    <div>
      <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
      <div className="mx-[20px] my-[20px]">
        <h1 className="text-2xl font-bold"> New E-Invoice </h1>
        <div className="w-full flex md:justify-start border-b-2 border-neutral-200">
          <div className="md:w-3/6 xl:w-full">
            {/* <h3 className="font-semibold text-xl text-black">{customer?.customer_name}</h3>
            <span className="font-semibold text-neutral-500">
              {customer?.address},{customer?.zipcode}
              </span> */}

            <div className="flex text-base gap-2 my-5">
              <h3 className="font-semibold text-black-500">GST Number: </h3>
              <span className="font-semibold text-neutral-500">
                {customer?.gst_number ? customer?.gst_number?.toUpperCase() : "Not Provided"}
              </span>
            </div>
            <ShipmentDispatchForm
              dispatch={dispatchDetails}
              setDispatch={setDispatchDetails}
              shipment={shipmentDetails}
              setShipment={setShipmentDetails}
              customer={customer}
              business={business}
            />
          </div>
        </div>
        <div>
          <div className="mt-[20px] overflow-y-scroll">
            <table cellPadding={5} cellSpacing={10}>
              <tr>
                <th>Items Details</th>
                <th>Batch Number</th>
                <th>HSN Number</th>
                <th>Quantity</th>
                <th>Deal / Free</th>
                <th>
                  Retail <br />
                  Without Tax
                </th>
                <th>Tax %</th>
                <th>Discount on MRP %</th>
                <th>Amount</th>
              </tr>
              {items?.map((item, index) => (
                <tr key={item?.id || index} className="mb-4">
                  <td>
                    <Autocomplete
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={products || []}
                      value={findProductByProductID(item.id)}
                      getOptionLabel={(option) =>
                        option?.total_remaining_quantity
                          ? `${option?.product_name} - Qty: ${option?.total_remaining_quantity}`
                          : `${option?.product_name}`
                      }
                      onChange={(event, value) => {
                        handleChangeItem(value, index);
                        // ~~ change here

                        if (value?.is_service === "Y") {
                          handleDiscountOnMRPChange(null, index, false, {
                            mrp: value?.mrp,
                            tax_percent: value?.tax,
                            sales_price: value?.rate,
                          });
                        }
                      }}
                      sx={{ width: 350 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Products"
                          onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                        />
                      )}
                    />
                  </td>
                  {/* ~~ change here */}
                  {items[index].is_service === "N" ? (
                    <td>
                      <Autocomplete
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                          }
                        }}
                        value={handleBatchForAutoComplete(items[index])}
                        disablePortal
                        id="batch-number"
                        options={getBatchesByProductId(items[index]?.id) || []}
                        getOptionLabel={(option) =>
                          option?.batch_number
                            ? `${option?.batch_number} - Qty: ${option?.remaining_quantity}`
                            : `${option?.batch_number}`
                        }
                        onChange={(event, value) => {
                          handleFieldChange(value?.batch_number, index, "batch_number");
                          handleFieldChange(value?.id, index, "batchId"); // Store batch ID
                          handleFieldChange(getHSNNumberByBatchId(value?.id), index, "hsnNumber");
                          handleFieldChange(value?.discount, index, "discount");
                          //Value - for direct discountOnMRP, Index, isDiscount, data, checkedByUser
                          handleDiscountOnMRPChange(null, index, false, {
                            mrp: value?.mrp_price,
                            tax_percent: value?.tax,
                            sales_price: value?.sales_price,
                          });
                          handleFieldChange(value?.sales_price, index, "sales_price");
                          handleFieldChange(value?.mrp_price, index, "mrp_price");
                          handleFieldChange(value?.tax, index, "tax");
                          handleFieldChange(value?.purchase_price, index, "purchase_price");
                          handleFieldChange(value?.add_margin, index, "margin");
                          handleFieldChange(value?.remaining_quantity, index, "remaining_quantity");
                          orderedProductQuantityOperation(value, items[index], index);
                        }}
                        sx={{ width: 200 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Batch Number"
                            onChange={(e) => handleBatchSearch(items[index]?.id, e.target.value)}
                          />
                        )}
                      />
                    </td>
                  ) : (
                    <td>
                      <span className="w-[200px] py-[15px] rounded-md text-center text-neutral-500 border border-neutral-400 block">
                        N / A
                      </span>
                    </td>
                  )}
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      disabled
                      value={items[index]?.hsn_number}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td>
                  {/* ~~ change here */}
                  {items[index].is_service === "N" ? (
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                          min: 1,
                        }}
                        onChange={(e) => {
                          if (items[index].remaining_quantity < e.target.value) {
                            toast.error("can't add more than remaining quanitity");
                            handleFieldChange(items[index].remaining_quantity, index, "quantity");
                            return;
                          }
                          if (e.target.value && e.target.value < 0) {
                            toast.error("value can't be negative or 0");
                            handleFieldChange(1, index, "quantity");
                            return;
                          }
                          handleFieldChange(e.target.value, index, "quantity");
                        }}
                        value={items[index]?.quantity}
                        defaultValue={0}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                  ) : (
                    <td>
                      <span className="py-[15px] min-w-[6vw] w-full rounded-md text-center text-neutral-500 border border-neutral-400 block">
                        N / A
                      </span>
                    </td>
                  )}
                  {/* ~~ change here */}
                  {items[index].is_service === "N" ? (
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                          min: 1,
                        }}
                        onChange={(e) => {
                          if (e.target.value && e.target.value < 0) {
                            toast.error("value can't be negative or 0");
                            handleFieldChange(0, index, "deal_quantity");
                            return;
                          }
                          handleFieldChange(e.target.value, index, "deal_quantity");
                        }}
                        value={items[index]?.deal_quantity}
                        // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                        defaultValue={0}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                  ) : (
                    <td>
                      <span className="py-[15px] min-w-[6vw] w-full rounded-md text-center text-neutral-500 border border-neutral-400 block">
                        N / A
                      </span>
                    </td>
                  )}
                  {/* ~~ change here */}
                  {items[index].is_service === "N" ? (
                    <td>
                      <TextField
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => handleFieldChange(e.target.value, index, "sales_price")}
                        value={items[index]?.sales_price}
                        type="number"
                        variant="outlined"
                        disabled
                      />
                    </td>
                  ) : (
                    <td>
                      <TextField
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => handleFieldChange(e.target.value, index, "rate")}
                        value={items[index]?.rate}
                        type="number"
                        variant="outlined"
                        disabled
                      />
                    </td>
                  )}
                  <td>
                    <TextField
                      id="filled-adornment-weight"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleFieldChange(e.target.value, index, "tax")}
                      value={items[index]?.tax}
                      type="number"
                      variant="outlined"
                      disabled
                    />
                  </td>
                  <td>
                    <TextField
                      id="filled-adornment-weight"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                        }
                      }}
                      //value - to directly give discount, index, isDiscount, data, checkedByUser
                      onChange={(e) => handleDiscountOnMRPChange(e.target.value, index, true, {})}
                      type="number"
                      value={discountOnMRPValue[index]?.discountOnMRP ?? ""}
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="amount"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleAmountValueChange(e.target.value, index)}
                      value={calculatedAmountValue[index]?.calculated_amount || 0}
                      type="text"
                      variant="outlined"
                      // disabled
                    />
                  </td>
                  <td
                    onClick={() => {
                      handleRemoveItem(index);
                    }}
                  >
                    <IconWrapper>
                      <Cross className="text-white z-10" />
                    </IconWrapper>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div>
            <button
              ref={addAnotherItemRef}
              className="text-black p-2 pb-[20px]"
              onClick={() => {
                setItems((prev) => [...prev, {}]);
                setDiscountOnMRPValue((prev) => [...prev, {}]);
                setCalculatedAmountValue((prev) => [...prev, {}]);
              }}
            >
              + Add another item
            </button>
            <div className="w-full flex border-t-2 border-neutral-200">
              <div className="w-full">
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SubTotal</h3>
                  <h3 className="font-semibold text-gray-500">{formData.sub_total || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Discount</h3>
                  <h3 className="font-semibold text-gray-500">{formData.discount || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(3).slice(0, -1) || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(3).slice(0, -1) || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Total</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.grand_total || 0).toFixed(2)}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Profit Margin</h3>
                  <h3 className="font-semibold text-gray-500">{profitMargin.toFixed(2)}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Payment Options</h3>
                  <h3 className="font-semibold text-gray-500">
                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white sm:flex">
                      {paymentOptions.map((option, index) => (
                        <li key={option.value} className="w-full">
                          <div className="flex items-center ml-2">
                            <input
                              id="horizontal-list-radio-id"
                              type="radio"
                              value={option.value}
                              onChange={(e) => {
                                let nextPaymentType = "paid";

                                if (e.target.value === "credit") {
                                  nextPaymentType = "remain_payment";
                                }

                                setFormData({
                                  ...formData,
                                  payment_option: e.target.value,
                                  remaining_total: 0,
                                  paid_amount: formData?.grand_total,
                                  payment_type: nextPaymentType,
                                });
                              }}
                              checked={formData.payment_option === option.value}
                              name="list-radio"
                              className="w-4 h-4 text-blue-600"
                            />
                            <label for="horizontal-list-radio-id" className="w-full ms-2 px-1 text-base font-medium">
                              {option.label}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </h3>
                </div>
                {formData.payment_type === "remain_payment" && (
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Amount Received</h3>
                    <input
                      type="number"
                      className="py-[10px] px-[10px] border border-black rounded-sm"
                      placeholder="Amount Received"
                      value={parseFloat(formData.paid_amount)}
                      onChange={(e) => {
                        if (e.target.value > formData?.grand_total) {
                          toast.error("Amount received cannot be more than grand total!");
                          return;
                        }

                        setFormData({
                          ...formData,
                          remaining_total: parseFloat(formData?.grand_total) - parseFloat(e.target.value),
                          paid_amount: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </div>
                )}
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Description</h3>
                  <h3 className="font-semibold text-gray-500">
                    <textarea
                      className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
                      placeholder=""
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      value={formData.description}
                    ></textarea>
                  </h3>
                </div>
              </div>
            </div>

            <div className="w-full flex items-center justify-center my-4">
              <button
                type="button"
                disabled={disableSubmitButton}
                ref={submitButton}
                onClick={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
              >
                Create E-Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEInvoice;
