import React from 'react';
import { Modal, } from '@mui/material';
import { Box, Fade, Backdrop } from "@mui/material";


const CustomModal = ({ children, isOpen, styles, handleClose }) => {
    const styled = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        maxHeight: "96vh !important",
        overflowY: "scroll",
        bgcolor: "background.paper",
        border: "none",
        outline: "none",
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
        ...styles
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isOpen}>
                <Box sx={styled}>
                    {children}
                </Box>
            </Fade>
        </Modal>
        
    )
}

export default CustomModal
