import { useDispatch } from "react-redux";
import { generateBarcodeImage } from "../../../reducers/Slices/productSlice";
import formatDuration from "../../../utils/formatDuration";
import React from "react";
import toast from "react-hot-toast";

function ServiceDetailsTable({ product }) {
  const dispatch = useDispatch();

  const handlePrintBarCode = (product) => {
    if (!product) {
      return;
    }

    const barcode = product?.bar_code_number;

    if (barcode) {
      dispatch(generateBarcodeImage(barcode))
        .then((response) => {
          // Assuming the response contains the PNG image data
          const barcodeImage = response.payload;
          toast.success("Barcode image received");
        })
        .catch((error) => {
          toast.error("Error generating barcode image:", error);
        });
    }
  };

  return (
    <div className="overflow-y-scroll h-[55vh] max-h-[55vh] border-[0.5px] border-[rgb(0,0,0,.25)]">
      <table border="1" className="table-auto w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              MRP Price
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Rate*
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Barcode Number*
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Service Cost to Business / Expense*
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Duration Time
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Tax %
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Print Barcode
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.mrp || "N/A"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.rate || "N/A"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.bar_code_number || "Nil"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.cost || "N/A"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.duration_time ? formatDuration(product?.duration_time) : "0 Minutes" || "N/A"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product.tax || "N/A"}
            </td>
            {product?.bar_code_number ? (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button onClick={() => handlePrintBarCode(product)} className="font-bold">
                  Print Barcode{" "}
                </button>
              </td>
            ) : (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                N/A
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ServiceDetailsTable;
