import React, { useState } from "react";
import GSTScreen from "../SharedComponents/GSTScreen";
import Vendor from "./Vendor";
import Product from "../Invoices/Product";

function AddVendor(props) {
  const { handleCloseModal, currentVendor } = props;
  const [formData, setFormData] = useState(currentVendor);
  const [data, setData] = useState({});
  const [toggleGST, setToggleGST] = useState(!currentVendor?.id);
  const [showTab, setShowTab] = useState("Vendor");
  const renderForm = (params) => {
    switch (params) {
      case "product":
        return <Product />;
      default:
        return (
          <Vendor
            isNew={!currentVendor?.id}
            handleCloseModal={handleCloseModal}
            data={data}
            setData={setData}
            formData={formData}
            setFormData={setFormData}
          />
        );
    }
  };
  return (
    <div className="w-[22rem] mx-auto">
      {toggleGST ? (
        <GSTScreen setFormData={setFormData} formData={formData} setShowGstTab={setToggleGST} />
      ) : (
        renderForm(showTab)
      )}
    </div>
  );
}

export default AddVendor;
