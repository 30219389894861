import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

export const app = initializeApp(firebaseConfig);
export let messaging = null;

if ("serviceWorker" in navigator) {
  messaging = getMessaging(app);
}

export const requestForToken = () => {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY, serviceWorkerRegistration: registration })
          .then((currentToken) => {
            if (currentToken) {
              return currentToken; // Return the token if available
            } else {
              console.error("No registration token available. Request permission to generate one.");
              return null; // Explicitly return null if no token is available
            }
          })
          .catch((err) => {
            console.error("An error occurred while retrieving token - " + err);
            return null; // Explicitly return null in case of an error
          });
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
        return null; // Explicitly return null if service worker registration fails
      });
  } else {
    console.error("Service Worker is not supported in this browser.");
    return null; // Explicitly return null if service workers are not supported
  }
};

// onMessage(messaging, ({ notification }) => {
//   new Notification(notification.title, {
//     body: notification.body,
//     icon: notification.icon,
//   });
// });
