// import React, { useState, useEffect } from "react";
import React, { useState } from "react";
// import { useNavigate, useLocation, Link } from "react-router-dom"; TODO - Delete if not needed
import { useLocation, Link } from "react-router-dom";
// import "./DesktopSideBar.scss"; TODO - Deletion if nothing breaks
// import { useSelector } from "react-redux";

// Data import
import { navigationItems } from "./SideBarData";

// UI libarary and components
import IconWrapper from "../../MicroComponents/IconWrapper";
import { ArrowRight } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// iconMapping.js
import {
  faHome,
  // faShoppingCart,
  faBook,
  faBox,
  faCashRegister,
  faFileInvoice,
  // faChartBar,
  faChartPie,
  faStore,
  faMoneyBillWave,
  faUsers,
  faArrowTrendUp,
  faReceipt,
  faHardDrive,
} from "@fortawesome/free-solid-svg-icons";

export const iconMapping = {
  "/": faHome,
  "/sales-book": faBook,
  "/purchase-book": faBook,
  "/inventory": faBox,
  "/cash-register": faCashRegister,
  "/ledger-book": faBook,
  "/reports": faChartPie,
  "/gst-filing": faFileInvoice,
  "/pnl": faArrowTrendUp,
  "/online-store": faStore,
  "/expenses": faMoneyBillWave,
  "/employees": faUsers,
  "/import_export": faHardDrive,
};

const DesktopSideBar = () => {
  // const navigate = useNavigate();
  const location = useLocation();

  const [showSidebar, setShowSidebar] = useState(false);

  // const sidebar = useSelector((state) => state.app.sidebar);

  // Use state to manage the active item
  // const [active, setActive] = useState("");

  // useEffect to set active item when location changes
  // useEffect(() => {
  // Find the navigation item that matches the current path
  // const activeItem = navigationItems.find((item) => item.path === location.pathname);

  // Set the active state to the title of the active item
  //   if (activeItem) {
  //     setActive(activeItem.title);
  //   }
  // }, [location.pathname]);

  // Function to handle navigation click
  // const handleNavigationClick = (path) => () => {
  //   navigate(path);
  // };

  return (
    <>
      {/* Placeholder to stop UI from breaking on showMenu reason aside(block) -> aside(absolute) */}
      <div className={`${showSidebar && "w-[86.5px]"}`}></div>

      {/* pHaniver - aside - removed - border w-40 */}
      <aside
        id="default-sidebar"
        className={`bg-[#F6F6F6] border-r-2 border-stone-200 z-40 hidden md:block xl:w-72 h-[100vh] ${
          showSidebar && "absolute top-0 left-0 w-[30vw]"
        }`}
        aria-label="Sidebar"
      >
        <div className="sticky h-full overflow-y-hidden">
          {/* <img src='/image/logo.svg' className='w-full' alt='Profit.AI'/>   */}
          {/* pHaniver - div - Removed - my-3 text-center text-2xl*/}
          {/* pHaniver - div - Adding - h-[80px] ml-[20px] flex items-center */}
          <div className="h-[80px] mx-[20px] flex items-center justify-between">
            <Link
              to={"/"}
              className={`text-[#277494] font-semibold text-2xl xl:block ${!showSidebar && "md:hidden"}`}
              style={{ fontFamily: "Comfortaa, sans-serif" }}
            >
              theprofit<span className="text-orange-500">.AI</span>{" "}
            </Link>

            <span className="xl:hidden" onClick={() => setShowSidebar(!showSidebar)}>
              <IconWrapper theme="light">
                <ArrowRight className={`text-white z-10 ${showSidebar && "rotate-180"}`} />
              </IconWrapper>
            </span>
          </div>
          {/* pHaniver - ul - Removed - bg-gray-100 px-3 py-4 space-y-2 font-medium*/}
          {/* pHaniver - ul - Added - bg-black flex flex-col mt-[20px] px-[20px] gap-5 */}
          <ul className="flex flex-col mt-[20px] px-[10px] h-[100%]">
            {navigationItems.map((item, index) => (
              // pHaniver - li - Removed - text-gray-900 hover:bg-blue-100 bg-gray-200 md:text-sm text-xs font-semibold p-2 rounded-lg
              // pHaniver - li - Added - text-white text-neutral-500 font-semibold
              <li
                key={index}
                className={`my-[0.5vh] py-[10px] xl:pl-[10px] ${showSidebar ? "pl-[10px]" : ""} rounded-lg ${
                  item.path === location.pathname ? "bg-stone-200" : ""
                }`}
              >
                <Link
                  to={item.path}
                  className={`${
                    item.path === location.pathname ? "text-neutral-600" : "text-neutral-500"
                  } flex items-center font-semibold ${
                    showSidebar ? "justify-normal" : "justify-center xl:justify-normal"
                  }`}
                >
                  {/* pHaniver - TODO - remove temporary fix invert and replace Icons */}
                  {/* pHaniver - Added - conditional contrast */}
                  {/* <img
                    src={item.imgUrl}
                    alt={item.title}
                    className={`h-[44px] w-[44px] lg:h-[25px] lg:w-[25px] max-w-fit ${
                      item.path === location.pathname ? "" : "contrast-[25%]"
                    }`}
                  /> */}
                  <FontAwesomeIcon
                    icon={iconMapping[item.path]}
                    className={`h-[20px] w-[20px] ${
                      item.path === location.pathname ? "text-[#277494]" : "text-neutral-500"
                    }`}
                  />
                  <span className={`ms-3 md:${showSidebar ? "" : "hidden"} xl:block`}>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default DesktopSideBar;
