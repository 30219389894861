import React, { useState, useEffect, useRef } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { updatePurchaseInvoice, getPurchaseInvoiceDetailsById } from "../../../reducers/Slices/purchaseInvoiceSlice";
import AddProduct from "../../Purchase/purchaseComponents/AddProduct";
import moment from "moment";
import toast from "react-hot-toast";
import {
  fetchProductByBarcode,
  fetchProductByID,
  fetchProducts,
  searchProducts,
} from "../../../reducers/Slices/productSlice";
import BarcodeReader from "../../Invoices/InvoiceComponents/BarcodeReader";
import BarcodeInputPurchase from "./BarcodeInputPurchase";
import { debounce } from "lodash";

function UpdatePurchaseInvoice({ invoice, fetchedProducts }) {
  const [showModal, setShowModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Purchase Invoice Details
  const purchaseInvoiceDetails = useSelector((state) => state.purchaseInvoice.purchaseInvoiceDetails);
  const products = fetchedProducts.filter((product) => product?.is_service === "N");

  //Function to close custom modal and perform actions during closing of modal
  function handleClose() {
    setShowModal(false);
  }

  const [items, setItems] = useState([{}]);

  //Side effect to change items array based on api data
  useEffect(() => {
    setItems(() => {
      if (purchaseInvoiceDetails?.length > 0) {
        return purchaseInvoiceDetails.map((item, index) => {
          return { ...item, amount: handleCalculateAmount(index, null, purchaseInvoiceDetails) };
        });
      } else {
        return [{}];
      }
    });
  }, [purchaseInvoiceDetails]);

  // const [discountTaxItems, setDiscountTaxItems] = useState([{}]);

  //Optional Parameters for Purchase Invoice
  const [eBillValue, setEBillValue] = useState(null);
  const [invoiceCounterValue, setInvoiceCounterValue] = useState(null);
  const [billDate, setBillDate] = useState(null);

  //Amount value for display
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  function handleUpdateInvoiceClick() {
    if (invoice?.id) {
      setFormData(() => {
        return {
          vendor: parseInt(invoice?.vendor?.id),
          payment_option: "cash",
          payment_type: "paid",
          description: null,
        };
      });
      setInvoiceCounterValue(invoice?.invoice_counter);
      setEBillValue(invoice?.ewaybill_number ?? null);
      setBillDate(invoice?.bill_date ?? moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
      dispatch(getPurchaseInvoiceDetailsById({ id: invoice?.id }));
    }
    setShowModal(true);
  }

  const paymentOptions = [
    { label: "Cash", value: "cash" },
    { label: "UPI", value: "upi" },
    { label: "Debit", value: "debit" },
    { label: "Credit", value: "credit" },
  ];

  const handlePaymentOptionChange = (value) => {
    setFormData((prevState) => {
      const updatedState = {
        ...prevState,
        payment_option: value.value,
        payment_type: value.value === "debit" ? "pay_later" : "cash",
      };
      return updatedState;
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return {
      vendor: parseInt(invoice?.vendor?.id),
      payment_option: "cash",
      payment_type: "paid",
      description: null,
    };
  });

  useEffect(() => {
    handleCalculate();
  }, [items]);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        order_date_time: billDate,
        ewaybill_number: eBillValue,
        invoice_counter: invoiceCounterValue,
      };
    });
  }, [billDate, eBillValue, invoiceCounterValue]);

  const handleChangeItem = (value, index) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            product: value?.id,
            batch: {
              size: null,
              add_margin: null,
              hsn_number: value?.hsn_number ?? null,
              package: value?.product_packaging ?? null,
              sales_price: null,
              deal_quantity: 0,
            },
          };
        }
        return item;
      });
      return updatedItems;
    });
    // setItems((prevItems) => {
    //   const updatedItems = prevItems.map((item, i) => {
    //     if (i === index) {
    //       return value ? { ...value, price: value.rate, batches: value.batches } : { price: 0.0, tax: 0.0 };
    //     }
    //     return item;
    //   });
    //   return updatedItems;
    // });
  };

  // const handleChangeDiscountTaxItems = (value, index, field) => {
  //   setDiscountTaxItems((prevItems) => {
  //     const updatedDiscountTaxItems = prevItems.map((item, i) => {
  //       if (i === index) {
  //         return { ...item, [field]: value };
  //       }
  //       return item;
  //     });
  //     updateItemFields(index, null, updatedDiscountTaxItems);
  //     return updatedDiscountTaxItems;
  //   });
  // };

  // Profit Margin for user
  // const [profitMargin, setProfitMargin] = useState(0);

  const handleCalculate = () => {
    if (!items?.length > 0) {
      return;
    }

    const { total, discount, tax } = items?.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.batch?.rate) {
          const quantity = parseInt(currentItem?.batch?.remaining_quantity ?? 0);
          let item_total = parseFloat(currentItem?.batch?.rate || 0) * quantity;
          let item_discount = item_total * (currentItem?.batch?.discount ? currentItem?.batch?.discount / 100 : 0);

          let item_after_discount = item_discount ? item_total - item_discount : item_total;
          let item_tax = item_after_discount * (currentItem?.batch?.tax ? currentItem?.batch?.tax / 100 : 0);
          // let item_profit = ((currentItem?.batch?.rate ?? 0) - (currentItem?.batch?.purchase_price ?? 0)) * quantity;

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
          // accumulator.profit += item_profit;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0 }
    );

    // setProfitMargin(profit);

    setFormData({
      ...formData,
      sub_total: total.toFixed(2),
      discount: discount.toFixed(2),
      grand_total: (total - discount + tax).toFixed(3).slice(0, -1),
      tax: tax.toFixed(3),
      paid_amount: (total - discount + tax).toFixed(3).slice(0, -1),
      remaining_total: 0.0,
      product_and_quantity: items,
    });
  };

  // //Set form values
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     products: items.map((i) => ({
  //       product_name: i.product_name,
  //       brand: i.product_brand,
  //       qty: parseInt(i.quantity ?? 1),
  //     })),
  //   });
  // }, [items]);

  const handleFieldChange = (value, index, field) => {
    if (!items[index]?.product) {
      toast.error("Product Field Empty!");
      return;
    }

    const updatedItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, batch: { ...item.batch, [field]: value } };
      }
      return item;
    });

    setItems(updatedItems);
    updateItemFields(index, updatedItems);
  };

  const updateItemFields = (index, updatedItems) => {
    // const latestItems = updatedItems ? updatedItems : items;
    // const latestDiscountTaxItems = updatedDiscountTaxItems ? updatedDiscountTaxItems : discountTaxItems;

    const rate = parseFloat(updatedItems[index]?.batch?.rate ?? 0);
    const quantity = parseInt(
      updatedItems[index]?.batch?.remaining_quantity ? parseInt(updatedItems[index]?.batch?.remaining_quantity) : 0
    );
    const deal = parseInt(updatedItems[index]?.batch?.deal_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.batch?.discount ?? 0) / 100);
    const discount = rate * discountPercent;
    // const totalDiscount = discount * quantity;

    // const taxPercent = parseFloat((updatedItems[index]?.tax ?? 0) / 100);
    // const tax = (rate - discount) * taxPercent;
    // const totalTax = tax * quantity;

    const total_quantity = deal + quantity;
    const purchase_price = ((rate - discount) * quantity) / total_quantity;

    setItems((prevItems) => {
      const nextItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            amount: handleCalculateAmount(index, null, updatedItems),
            batch: { ...item.batch, purchase_price, total_quantity },
          };
        }
        return item;
      });

      return nextItems;
    });
  };

  const handleCalculateAmount = (index, value, updatedItems) => {
    if (value || value === 0 || value === "0" || value === "") {
      setCalculatedAmountValue((prevItems) => {
        const updatedCalculatedAmount = prevItems.map((item, i) => {
          if (i === index) {
            return { calculated_amount: value ? parseFloat(value) : "" };
          }
          return item;
        });
        return updatedCalculatedAmount;
      });

      setItems((prevItems) => {
        const nextItems = prevItems.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              amount: value ? parseFloat(value) : "",
            };
          }
          return item;
        });
        return nextItems;
      });
      return 0;
    }

    const rate = parseFloat(updatedItems[index]?.batch?.rate ?? 0);
    const quanitity = parseInt(updatedItems[index]?.batch?.remaining_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.batch?.discount ?? 0) / 100);
    const discount = rate * discountPercent;

    const taxPercent = parseFloat((updatedItems[index]?.batch?.tax ?? 0) / 100);
    const tax = (rate - discount) * taxPercent;

    const amount = (rate + tax - discount) * quanitity;

    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (i === index) {
          return { calculated_amount: amount };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });

    return amount;
  };

  const handleRemoveItem = (index) => {
    if (items?.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }
    if (items.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
  };

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    dispatch(updatePurchaseInvoice({ id: invoice?.id, data: formData }))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          navigate(-1);
        } else {
          toast.error("sorry, Purchase Invoice Cannot be Updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleAddMoreProducts = () => {
    setItems((prevItems) => [...prevItems, {}]);
    setCalculatedAmountValue((prevItems) => [...prevItems, {}]);
  };

  const findProductByProductId = async (id) => {
    if (!id) {
      return {};
    }

    let product = products.find((product) => product.id === id);

    //If product is not found in the cached product array then search for it using API call
    if (!product) {
      const response = await dispatch(fetchProductByID(id));
      if (response?.payload?.data) {
        product = response.payload.data;
      }
    }

    return product;
  };

  //Product list state and side effect to keep track of items for MUI product autocomplete.
  const [selectedProductList, setSelectedProductList] = useState([]);

  useEffect(() => {
    const populateSelectedProductListAsync = async () => {
      const updatedProductList = await Promise.all(
        items.map(async (item) => {
          if (!item?.product) {
            return {};
          }
          const product = await findProductByProductId(item?.product);
          console.log(product, "this is the product");
          return product;
        })
      );

      setSelectedProductList(updatedProductList);
    };

    populateSelectedProductListAsync();
  }, [items]);

  useEffect(() => {
    console.log(selectedProductList, "this is the selected product list");
  }, [selectedProductList]);

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                //Note - for some reason this is not working like in other components without timout
                if (window.confirm("want to add another item")) {
                  setTimeout(() => {
                    addAnotherItemRef.current.focus(); // Focus on add another item button
                  }, 200);
                } else {
                  setTimeout(() => {
                    submitButton.current.focus(); //Focus on submit button
                  }, 200);
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  //Barcode related operations
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
      some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  //Do operations based on captured products
  useEffect(() => {
    //if product is service based product then don't add it.
    if (capturedProduct?.is_service === "Y") {
      toast.error("Sorry! seems like barcode you scanned is for a service!");
      return;
    }
    //Add scanned products into purchase invoice with this data format
    const productData = {
      product: capturedProduct?.id,
      batch: {
        size: null,
        add_margin: null,
        hsn_number: capturedProduct?.hsn_number ?? null,
        package: capturedProduct?.product_packaging ?? null,
        sales_price: null,
        deal_quantity: 0,
        remaining_quantity: 1,
      },
    };

    let updatedItems = [];

    //if there is nothing in items then add product instead of empty object otherwise just add product to already available product.
    // console.log(items, "these are items alreadt present");
    if (!items[0]?.product) {
      updatedItems = [productData];
    } else {
      updatedItems = [...items, productData];
      setCalculatedAmountValue((prev) => [...prev, {}]);
    }

    console.log(updatedItems, "these are the updated items");

    setItems(updatedItems);
    //eslint-disable-next-line
  }, [capturedProduct]);

  //Searched products
  const searchedProducts = useSelector((state) => state.product.searchedProducts.data);

  //Debounced search term state to call searched product API
  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 500);

  useEffect(() => {
    dispatch(searchProducts(productSearchTerm));
  }, [productSearchTerm]);

  useEffect(() => {
    console.log(searchedProducts, "these are searched products");
  }, [searchedProducts]);

  const handleSelectedProductForMUI = (product) => {
    /* React is glitching and is not taking product if ti is missing despite being present.. I think it is due to milisenconds of latency issue 
    in react reconciliation algorithm */
    if (!product?.id) {
      return null;
    }
    return product;
  };
  return (
    <>
      <AddProduct showModal={showProductModal} setShowModal={setShowProductModal} />
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center text-xl">Edit Purchase Invoice</div>
          {items?.map((item, index) => (
            <div
              key={item?.product || index}
              className="flex flex-col gap-[20px] border-b-2 border-neutral-200 py-[20px]"
            >
              <div>
                <label htmlFor="products" className="sr-only">
                  product
                </label>
                <Autocomplete
                  disablePortal
                  hiddenLabel
                  id="products"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                    }
                  }}
                  options={products || []}
                  value={handleSelectedProductForMUI(selectedProductList[index])}
                  // getOptionLabel={(option) =>
                  //   option?.total_remaining_quantity
                  //     ? `${option?.product_name} - Qty: ${option?.total_remaining_quantity}`
                  //     : `${option?.product_name}`
                  // }
                  getOptionLabel={(option) => option?.product_name}
                  noOptionsText={
                    <button onClick={() => setShowProductModal(true)} className="w-full">
                      + add product
                    </button>
                  }
                  onChange={(event, value) => handleChangeItem(value, index)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Products"
                      onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                    />
                  )}
                  aria-required
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="batch-number">Batch Number</label>
                <TextField
                  id="batch-number"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  value={items[index]?.batch?.batch_number}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "batch_number");
                  }}
                  // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                  type="text"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="hsn-number">HSN number</label>
                <TextField
                  id="hsn-number"
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  value={items[index]?.batch?.hsn_number ?? 0}
                  type="text"
                  variant="outlined"
                  disabled
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="remaining-quantity">Quantity</label>
                <TextField
                  id="remaining-quantity"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "remaining_quantity");
                  }}
                  value={items[index]?.batch?.remaining_quantity}
                  // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                  type="number"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="deal_quantity">Deal Quantity</label>
                <TextField
                  id="deal_quantity"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "deal_quantity");
                  }}
                  value={items[index]?.batch?.deal_quantity}
                  defaultValue={0}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="mrp_price">MRP</label>
                <TextField
                  id="mrp_price"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "mrp_price");
                  }}
                  value={items[index]?.batch?.mrp_price}
                  type="number"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="rate">Rate</label>
                <TextField
                  id="rate"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "rate");
                  }}
                  value={items[index]?.batch?.rate}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="rate">Selling Price(opt)</label>
                <TextField
                  id="sales_price"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][6] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "sales_price");
                  }}
                  value={items[index]?.batch?.sales_price}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="tax_percent">Tax %</label>
                <TextField
                  id="tax_percent"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][7] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "tax");
                  }}
                  value={items[index]?.batch?.tax}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="discount_percent">Discount %</label>
                <TextField
                  id="discount_percent"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][8] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "discount");
                  }}
                  value={items[index]?.batch?.discount}
                  type="number"
                  variant="outlined"
                />
              </div>
              {/* <div className="flex flex-col">
                <label htmlFor="purchase_price">Purchase Price</label>
                <TextField
                  id="purchase_price"
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  value={items[index]?.batch?.purchase_price}
                  type="number"
                  variant="outlined"
                  disabled
                />
              </div> */}
              <BarcodeInputPurchase items={items} setItems={setItems} index={index} />
              <div className="flex flex-col">
                <label htmlFor="expiry_date">Expiry Date</label>
                <TextField
                  id="expiry_date"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][9] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "expiry_date");
                  }}
                  value={items[index]?.expiry_date}
                  type="date"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="amount">Amount</label>
                <TextField
                  id="amount"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][10] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => handleCalculateAmount(index, e.target.value)}
                  value={calculatedAmountValue[index]?.calculated_amount || 0}
                  type="text"
                  variant="outlined"
                />
              </div>
              <div className="py-[20px] w-full bg-green-20 flex justify-center">
                <button
                  className="px-[20px] py-[10px] bg-black rounded-full text-white"
                  onClick={() => handleRemoveItem(index)}
                >
                  Remove Product
                </button>
              </div>
            </div>
          ))}
          <div className="py-[20px] w-full bg-green-20 flex justify-center">
            <button
              ref={addAnotherItemRef}
              className="px-[20px] py-[10px] bg-black rounded-full text-white"
              onClick={() => handleAddMoreProducts()}
            >
              Add More Products
            </button>
          </div>
          <div className="flex flex-col">
            <label htmlFor="invoiceId">Invoice Id</label>
            <TextField
              hiddenLabel
              id="invoiceId"
              variant="outlined"
              type="text"
              value={invoiceCounterValue || ""}
              onChange={(e) => setInvoiceCounterValue(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="eWayBill">E-Way Bill(opt.)</label>
            <TextField
              hiddenLabel
              id="eWayBill"
              type="text"
              variant="outlined"
              value={eBillValue}
              onChange={(e) => setEBillValue(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="billDate">Bill Date</label>
            <TextField
              hiddenLabel
              id="billDate"
              variant="outlined"
              type="date"
              value={moment(billDate).format("YYYY-MM-DD")}
              onChange={(e) => setBillDate(moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss"))}
            />
          </div>
          <div>
            <div className="py-[10px]"></div>
            <label htmlFor="payment-type" className="sr-only">
              Payment Type
            </label>
            <Autocomplete
              disablePortal
              id="payment-type"
              options={paymentOptions || []}
              sx={{ width: "100%" }}
              value={formData?.payment_option}
              onChange={(event, value) => handlePaymentOptionChange(value)}
              renderInput={(params) => <TextField {...params} label="Payment Option" />}
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description">Description</label>
            <textarea
              className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
              placeholder=""
              id="description"
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              value={formData.description}
            ></textarea>
          </div>
          <div className="flex flex-col">
            <label htmlFor="sub_total">Sub Total</label>
            <TextField hiddenLabel id="sub_total" variant="outlined" value={formData.sub_total || 0} disabled />
          </div>
          <div className="flex flex-col">
            <label htmlFor="discount">Discount</label>
            <TextField hiddenLabel id="discount" variant="outlined" value={formData.discount || 0} disabled />
          </div>
          <div className="flex flex-col">
            <label htmlFor="sgst">SGST</label>
            <TextField
              hiddenLabel
              id="sgst"
              variant="outlined"
              value={(formData.tax / 2).toFixed(3).slice(0, -1) || 0}
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="cgst">CGST</label>
            <TextField
              hiddenLabel
              id="cgst"
              variant="outlined"
              value={(formData.tax / 2).toFixed(3).slice(0, -1) || 0}
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="total">Total</label>
            <TextField hiddenLabel id="total" variant="outlined" value={formData.grand_total || 0} disabled />
          </div>
          {/* <div className="flex flex-col">
            <label htmlFor="profit">Profit Margin</label>
            <TextField hiddenLabel id="profit" variant="outlined" value={profitMargin} disabled />
          </div>{" "} */}
          <div className="flex gap-[10px] justify-center">
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
              ref={submitButton}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              Save
            </button>
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleClose}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClose();
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <button onClick={handleUpdateInvoiceClick}>Edit</button>
      </div>
    </>
  );
}

export default UpdatePurchaseInvoice;
