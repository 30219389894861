import React, { useEffect, useState, useRef } from "react";
// import "./Home.scss";
import TargetCompletion from "./HomeComponents/TargetCompletion";
import TotalSales from "./HomeComponents/TotalSales";
import ListContainer from "./HomeComponents/ListContainer";
import Inventory from "./HomeComponents/Inventory";
import CommonLists from "./HomeComponents/Shared/CommonLists";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard, updateSalesTarget } from "../../reducers/Slices/appSlice";
import { fetchTopCustomers } from "../../reducers/Slices/customerSlice";
import { fetchTopProducts } from "../../reducers/Slices/productSlice";
import { PieChart, useDrawingArea } from "@mui/x-charts";
import { styled } from "@mui/material";

//import style module for grid
import styles from "./Home.module.css";
import toast from "react-hot-toast";

const Home = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.app.dashboard);
  const top_customers = useSelector((state) => state.customer.top_customers);
  const top_products = useSelector((state) => state.product.top_products);

  const [graphContainerDimension, setGraphContainerDimension] = useState({ height: 0, width: 0 });

  const graphRef = useRef("");

  // Run this side effect on first render to get graph container's dimensions.
  useEffect(() => {
    const nextGraphContainerDimension = {
      height: graphRef.current.offsetHeight,
      width: graphRef.current.offsetWidth,
    };
    setGraphContainerDimension(nextGraphContainerDimension);
  }, []);

  useEffect(() => {
    dispatch(fetchTopCustomers());
    dispatch(fetchTopProducts());
    dispatch(fetchDashboard());
  }, [dispatch]);

  useEffect(() => {
    // Trigger animation after component is mounted
    setIsAnimating(true);

    // Turn off animation after 0.5s (same duration as transition)
    const timeoutId = setTimeout(() => {
      setIsAnimating(false);
    }, 1000);

    // Clean up the timeout
    return () => clearTimeout(timeoutId);
  }, []);

  const data = [
    { value: dashboard?.today_target_sales?.today_sales_value || 0, label: "Completed", color: "#277494" },
    {
      value:
        dashboard?.today_target_sales?.target_sales_value || 0 - dashboard?.today_target_sales?.today_sales_value || 0,
      label: "Remaining",
      color: "#ff9900",
    },
  ];

  const size = {
    width: 355,
    height: 200,
  };

  // const data1 = {
  //   title: "Top Selling Items",
  //   title2: "",
  //   title3: "",
  //   names: [
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "jane morgan",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "jane morgan",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //     {
  //       name: "John Doe",
  //       amount: "₹ 25000",
  //     },
  //   ],
  // };

  // const data2 = {
  //   title: "Top Customer Monthly",
  //   title2: "",
  //   title3: "",
  //   names: top_customers,
  // };

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const [targetSalesChangeToggle, setTargetSalesChangeToggle] = useState(false);
  const [targetSalesInput, setTargetSalesInput] = useState(0);

  const handleChangeTargetToggleStatus = () => {
    setTargetSalesChangeToggle(!targetSalesChangeToggle);
  };

  const handleChangeTargetSales = () => {
    //Do something with value
    // alert(targetSalesInput);
    // Payload: { "sales_target": 500000.00 }
    dispatch(updateSalesTarget({ sales_target: targetSalesInput }))
      .then((action) => {
        if (action.payload) {
          toast.success("Sales Target Changed Successfully");
          setTargetSalesChangeToggle(false);
        } else {
          toast.error("Something went wrong.. try again!");
        }
      })
      .catch((err) => {
        toast.err(err);
      });
  };

  return (
    <div className="relative h-full flex flex-col lg:flex-row">
      <div id="analytics-data" className={`h-full w-full p-[10px] lg:w-[80%] ${styles.gridContainer}`}>
        {/* Graph Dashboard Item */}
        <div className={`${styles.graphItem} rounded-[20px] flex items-center justify-center`} ref={graphRef}>
          <TotalSales data={dashboard?.sales_graph} containerDimension={graphContainerDimension} />
        </div>

        {/* Target Sales Dashboard Item */}
        <div
          className={`${styles.targetItem} flex items-center justify-center rounded-[20px] p-[20px]`}
          onDoubleClick={() => handleChangeTargetToggleStatus()}
          title="Double click to change target value."
        >
          {data[0].value === 0 && data[1].value === 0 && <div>No sale recorded for target sales estimation</div>}
          {data[1].value !== 0 && targetSalesChangeToggle === false && (
            <PieChart series={[{ data, innerRadius: 80, outerRadius: 60 }]} {...size}>
              <PieCenterLabel>Target Sales</PieCenterLabel>
            </PieChart>
          )}
          {targetSalesChangeToggle === true && (
            <div className="w-full h-full p-[20px] flex flex-col items-center gap-[10px]">
              <h3 className="text-xl font-semibold mb-[10px]">New Target Sales</h3>
              <input
                type="number"
                className="w-full border border-neutral-200 text-center py-[10px] rounded-full"
                value={targetSalesInput}
                onChange={(e) => setTargetSalesInput(e.target.value)}
              />
              <button
                className="mt-[10px] px-[10px] py-[5px] text-sm text-white bg-black rounded-full font-semibold"
                onClick={() => handleChangeTargetSales()}
              >
                Change Target Value
              </button>
            </div>
          )}
        </div>

        {/* Iventory Dashboard Item  */}
        <div className={`${styles.inventoryItem} flex justify-center rounded-[20px]`}>
          {!dashboard?.total_sales_by_brand && (
            <div className="w-full flex items-center justify-center">No inventory data available!</div>
          )}
          {dashboard?.total_sales_by_brand && (
            <div>
              <Inventory data={dashboard?.total_sales_by_brand} />
            </div>
          )}
        </div>

        {/* Profit Margin Dashboard Item  */}
        <div
          className={`${styles.profitItem} p-[15px] flex flex-col justify-center gap-2 items-center group rounded-[20px]`}
        >
          <div className="flex flex-col gap-[2px] items-center justify-center">
            <h3 className="text-md lg:text-xl text-neutral-500">Profit Margin</h3>
            <span className="text-xs text-neutral-500">(Monthly)</span>
          </div>
          {dashboard?.profit_margin === 0 && <div>No profit margin recorded!</div>}
          {!!dashboard?.profit_margin && dashboard?.profit_margin !== 0 && (
            <div className="flex gap-4">
              <div className="text-xl lg:text-2xl group-hover:text-blue-700">
                {parseFloat(dashboard?.profit_margin || 0).toFixed(2)}%
              </div>
              <div className="text-xl lg:text-2xl group-hover:text-blue-700">
                ₹{parseFloat(dashboard?.absolute_profit_margin || 0).toFixed(2)}
              </div>
            </div>
          )}
        </div>

        {/* Sales Dashboard Item  */}
        <div
          className={`${styles.salesItem} p-[15px] flex flex-col justify-center gap-2 items-center group rounded-[20px]`}
        >
          <div className="flex flex-col gap-[2px] items-center justify-center">
            <h3 className="text-md lg:text-xl text-neutral-500">Total Sales</h3>
            <span className="text-xs text-neutral-500">(Monthly)</span>
          </div>
          {console.log(dashboard?.total_month_sales_prices, "this is the total sales price")}
          {dashboard?.total_month_sales_prices === 0 && <div>No sale recorded!</div>}
          {!!dashboard?.total_month_sales_prices && dashboard?.total_month_sales_prices !== 0 && (
            <div className="text-xl lg:text-2xl group-hover:text-blue-700">
              ₹{parseFloat(dashboard?.total_month_sales_prices).toFixed(2)}
            </div>
          )}
        </div>

        {/* Debt Dashboard Item  */}
        <div
          className={`${styles.debtItem} p-[15px] flex flex-col justify-center gap-2 items-center group rounded-[20px]`}
        >
          <div className="flex flex-col gap-[2px] items-center justify-center">
            <h3 className="text-md lg:text-xl text-neutral-500">Debt Given</h3>
            <span className="text-xs text-neutral-500">(Monthly)</span>
          </div>

          {(!dashboard?.total_debit_value || dashboard?.total_debit_value === 0) && <div>No debt recorded!</div>}
          {!!dashboard?.total_debit_value && dashboard?.total_debit_value !== 0 && (
            <div className="text-xl lg:text-2xl group-hover:text-blue-700">₹{dashboard?.total_debit_value || 0}</div>
          )}
        </div>

        {/* Cash Dashboard Item  */}
        <div
          className={`${styles.cashItem} p-[15px] flex flex-col gap-4 lg:gap-0 lg:flex-row justify-around items-center rounded-[20px] group`}
        >
          <div className="flex flex-col gap-2 items-center">
            <div className="flex flex-col gap-[2px] items-center justify-center">
              <h3 className="text-md lg:text-xl text-neutral-500">Cash on Hand</h3>
              <span className="text-xs text-neutral-500">(Daily)</span>
            </div>
            {(!dashboard?.cash_in_hand_value || dashboard?.cash_in_hand_value === 0) && <div>0 cash on hand!</div>}
            {!!dashboard?.cash_in_hand_value && dashboard?.cash_in_hand_value !== 0 && (
              <div className="text-lg lg:text-2xl group-hover:text-blue-700">₹{dashboard?.cash_in_hand_value ?? 0}</div>
            )}
          </div>
          <div className="flex flex-col gap-2 items-center">
            <h3 className="text-md lg:text-xl text-neutral-500">Current Stock Value</h3>

            {dashboard?.total_stock_price === 0 && <div>No stock found!</div>}
            {!!dashboard?.total_stock_price && dashboard?.total_stock_price !== 0 && (
              <div className="text-lg lg:text-2xl group-hover:text-blue-700">
                ₹{parseFloat(dashboard?.total_stock_price).toFixed(2)}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 items-center">
            <h3 className="text-md lg:text-xl text-neutral-500">Expected Profit Margin</h3>
            {(!dashboard?.expected_profit_margin || dashboard?.expected_profit_margin === 0) && (
              <div>No sale recorded!</div>
            )}
            {!!dashboard?.expected_profit_margin && dashboard?.expected_profit_margin !== 0 && (
              <div className="text-lg lg:text-2xl group-hover:text-blue-700">
                {parseFloat(dashboard?.expected_profit_margin ?? 0).toFixed(2)}%
              </div>
            )}
          </div>
        </div>

        {/* Total Purchase Dashboard Item  */}
        <div
          className={`${styles.purchaseItem} p-[15px] flex flex-col justify-center gap-2 items-center group rounded-[20px]`}
        >
          <div className="flex flex-col gap-[5px] items-center justify-center">
            <h3 className="text-md lg:text-xl text-neutral-500">Today's Sales Value</h3>
            <span className="text-xs text-neutral-500">(Daily)</span>
          </div>
          {(!dashboard?.today_sales_value || dashboard?.today_sales_value === 0) && <div>No sale recorded today!</div>}
          {!!dashboard?.today_sales_value && dashboard?.today_sales_value !== 0 && (
            <div className="text-xl lg:text-2xl group-hover:text-blue-700">
              ₹{parseFloat(dashboard?.today_sales_value ?? 0).toFixed(2)}
            </div>
          )}
        </div>
      </div>

      <div
        id="analytics-list"
        className="w-full border-t-2 lg:border-l-2 lg:border-t-0 lg:w-[20%] lg:h-full flex flex-col md:flex-row lg:flex-col gap-[10px] px-[10px] py-[10px]"
      >
        <div className="w-full">
          <CommonLists
            title="Top Customer Monthly"
            data={top_customers}
            labels={["Index", "Name", "Sales"]}
            type="TCM"
          />
        </div>
        <div className="w-full">
          <CommonLists
            title="Top Selling Items"
            data={dashboard?.top_selling_items}
            labels={["Index", "Name", "Quantity"]}
            type="TSI"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
