import React, { useEffect, useState } from "react";
// import "./ComponentStyles.scss";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchProducts, fetchMoreProducts } from "../../../reducers/Slices/productSlice";
// import { fetchInvoices } from "../../../reducers/Slices/invoiceSlice";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import UpdateInvoice from "./UpdateInvoice";
import UpdatePurchaseInvoice from "./UpdatePurchaseInvoice";
import UpdateCreditNote from "./UpdateCreditNote";
import { apiUrl } from "../../../config/appConfig";
// import { apiUrl } from "../../../config/appConfig";

const InvoiceDataTable = ({ infoType, invoices, fetchMore, next, creditNote }) => {
  const dispatch = useDispatch();
  // Get the user's timezone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //filter invoices based on infoType
  const customerInvoices = invoices?.filter((invoice) => invoice?.is_purchase === false);
  const vendorInvoices = invoices;

  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (next ? true : false));

  const products = useSelector((state) => state.product.products.data);
  // const productsNext = useSelector((state) => state.product.products.next);

  //Fetch products
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // //Fetch all product
  // useEffect(() => {
  //   if (productsNext) {
  //     dispatch(fetchMoreProducts(productsNext));
  //   }
  // }, [productsNext, dispatch]);

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (next) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [next]);

  const invoiceDataTableHeaderCustomer = [
    {
      id: 0,
      title: "Date",
      key: "date",
    },
    {
      id: 1,
      title: "Invoice No.",
      key: "invoiceNumber",
    },
    {
      id: 2,
      title: `${infoType}s`,
      key: `${infoType}s`,
    },
    {
      id: 5,
      title: "Amount",
      key: "amount",
    },
    {
      id: 6,
      title: "Status",
      key: "status",
    },
    {
      id: 7,
      title: "invoice PDF",
      key: "download",
    },
    {
      id: 8,
      title: "Edit Invoice",
      key: "editInvoice",
    },
  ];

  const invoiceDataTableHeaderCreditNote = [
    {
      id: 0,
      title: "Date",
      key: "date",
    },
    {
      id: 1,
      title: "Invoice No.",
      key: "invoiceNumber",
    },
    {
      id: 1,
      title: "Credit Note No.",
      key: "creditNoteNumber",
    },
    {
      id: 3,
      title: `${infoType}s`,
      key: `${infoType}s`,
    },
    {
      id: 4,
      title: "Amount",
      key: "amount",
    },
    {
      id: 5,
      title: "Credit Note PDF",
      key: "download",
    },
    {
      id: 6,
      title: "Edit Credit Note",
      key: "editCreditNote",
    },
  ];

  const invoiceDataTableHeaderVendor = [
    {
      id: 0,
      title: "Date",
      key: "date",
    },
    {
      id: 1,
      title: "Invoice No.",
      key: "invoiceNumber",
    },
    {
      id: 2,
      title: `${infoType}s`,
      key: `${infoType}s`,
    },
    {
      id: 5,
      title: "Amount",
      key: "amount",
    },
    {
      id: 6,
      title: "Status",
      key: "status",
    },
    {
      id: 3,
      title: "invoice PDF",
      key: "download",
    },
    {
      id: 4,
      title: "Edit Invoice",
      key: "editInvoice",
    },
  ];

  if (infoType === "vendor") {
    return (
      <div>
        <InfiniteScroll
          dataLength={invoices.length}
          next={fetchMore}
          hasMore={hasMoreInvoices}
          height={"50vh"}
          loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
          endMessage={
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              <b>Vendor Invoice List End</b>
            </p>
          }
        >
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                {invoiceDataTableHeaderVendor.map((item, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                    scope="col"
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            </thead>
            {vendorInvoices?.length > 0 ? (
              <tbody>
                {vendorInvoices.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {moment.utc(item?.order_date_time).tz(userTimezone).format("LLL")}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item?.invoice_counter}
                    </td>

                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item?.vendor?.vendor_name}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      Rs {item?.grand_total}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item?.payment_type}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white border-[1px] border-neutral-200">
                      <Link to={item?.Invoice} target="_blank" rel="noopener noreferrer">
                        <button className="capitalize">download</button>
                      </Link>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
                      <UpdatePurchaseInvoice invoice={item} fetchedProducts={products} />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <td colSpan={invoiceDataTableHeaderVendor.length}>
                <h2 className="text-center"> No Invoice Found</h2>
              </td>
            )}
          </table>
        </InfiniteScroll>
      </div>
    );
  } else if (!creditNote) {
    return (
      <div>
        <InfiniteScroll
          dataLength={invoices.length}
          next={fetchMore}
          hasMore={hasMoreInvoices}
          height={"50vh"}
          loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
          endMessage={
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              <b>Customer Credit Note List End</b>
            </p>
          }
        >
          <table className="w-full">
            <thead>
              <tr>
                {invoiceDataTableHeaderCreditNote.map((item, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                    scope="col"
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            </thead>
            {invoices?.length > 0 ? (
              <tbody>
                {invoices.map((item, index) => (
                  <tr key={`${index}${item}`}>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {moment.utc(item.return_date_time).tz(userTimezone).format("LLL")}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item?.invoice_counter}
                    </td>
                    {!creditNote && (
                      <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                        {item?.credit_note_number}
                      </td>
                    )}
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item?.customer?.customer_name}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      Rs {item.total_amount}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white  border-[1px] border-neutral-200">
                      <Link to={`${apiUrl}${item.credit_note_pdf}`} target="_blank" rel="noopener noreferrer">
                        <button className="capitalize">download</button>
                      </Link>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
                      <UpdateCreditNote invoice={item} products={products} />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <td colSpan={invoiceDataTableHeaderCustomer.length}>
                <h2 className="text-center">No Invoice Found</h2>
              </td>
            )}
          </table>
        </InfiniteScroll>
      </div>
    );
  } else {
    return (
      <div>
        <InfiniteScroll
          dataLength={invoices.length}
          next={fetchMore}
          hasMore={hasMoreInvoices}
          height={"50vh"}
          loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
          endMessage={
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              <b>Customer Invoice List End</b>
            </p>
          }
        >
          <table className="w-full">
            <thead>
              <tr>
                {invoiceDataTableHeaderCustomer.map((item, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                    scope="col"
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            </thead>
            {customerInvoices?.length > 0 ? (
              <tbody>
                {customerInvoices.map((item, index) => (
                  <tr key={`${index}${item}`}>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {moment.utc(item.order_date_time).tz(userTimezone).format("LLL")}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item.invoice_counter}
                    </td>
                    {!creditNote && (
                      <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                        {item.invoice_counter}
                      </td>
                    )}
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      {item.customer.customer_name}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                      Rs {item.grand_total}
                    </td>
                    {creditNote && (
                      <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                        {item.payment_type}
                      </td>
                    )}
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white  border-[1px] border-neutral-200">
                      <Link to={item.Invoice} target="_blank" rel="noopener noreferrer">
                        <button className="capitalize">download</button>
                      </Link>
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
                      <UpdateInvoice invoice={item} products={products} />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <td colSpan={invoiceDataTableHeaderCustomer.length}>
                <h2 className="text-center">No Invoice Found</h2>
              </td>
            )}
          </table>
        </InfiniteScroll>
      </div>
    );
  }
};

export default InvoiceDataTable;
