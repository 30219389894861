import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import filterImage from "../../assets/images/filter.png";
// import gstIcon from "../../../assets/images/gst.jpg";
// import InvoiceBarChart from "../Charts/InvoiceBarChart";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import AddUser from "../AddUser";
// import { Checkbox, Tooltip } from "@mui/material";
// import FavoriteIcon from "../../../assets/images/favChecked.png";
// import FavoriteBorderIcon from "../../../assets/images/fav.png";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerInvoices, cancelInvoice, fetchMoreInvoices } from "../../../reducers/Slices/invoiceSlice";

import moment from "moment";
// import { fetchCustomers, setFavouriteCustomer } from "../../../reducers/Slices/customerSlice";
// import { fetchProductRecommendedList } from "../../../reducers/Slices/productSlice";
import { debounce } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
// import CommonLists from "../CommonLists";

//Functional Components
// import DeleteCustomer from "./InvoiceComponents/DeleteCustomer";

//UI component
import { Filter } from "react-feather";
import IconWrapper from "../../MicroComponents/IconWrapper";
import toast from "react-hot-toast";

function AllInvoice() {
  const [showAddUser, setShowAddUser] = useState(false);
  const [filterValue, setFilterValue] = useState("invoices");
  const [isCancelled, setIsCancelled] = useState(false);

  //Hot key to add Customer
  useHotkeys("Ctrl + Alt + C, Ctrl + Option + C", () => setShowAddUser(!showAddUser));

  const invoices = useSelector((state) => state.invoice.invoices.data);
  const invoicesNext = useSelector((state) => state.invoice.invoices.next);

  // const [currentCustomer, setCurrentCustomer] = useState({});
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [search, setSearch] = useState("");
  // const [tab, setTab] = useState();
  // const customers = useSelector((state) => state.customer.customers) || [];
  // const recommendedProducts = useSelector((state) => state.product.productRecommendedList);
  const [showMenu, setShowMenu] = useState(null);

  useEffect(() => {
    let baseString = "";

    if (search) {
      baseString = `search=${search}`;
    } else {
      baseString = "";
    }

    if (filterValue === "invoices") {
      dispatch(getCustomerInvoices(`?${baseString}`));
      setIsCancelled(false);
    } else if (filterValue === "cancelled_invoices") {
      dispatch(getCustomerInvoices(`?is_cancelled=1${baseString ? `&search=${baseString}` : ""}`));
      setIsCancelled(true);
    }
  }, [filterValue, search, dispatch]);

  // useEffect(() => {
  //   search.length > 0 ? debouncedSearch(search) : dispatch(fetchCustomers(tab));
  // }, [dispatch, tab]);

  //Fetch Recommended Products
  // useEffect(() => {
  //   dispatch(fetchProductRecommendedList());
  // }, [dispatch]);

  // const handleCreateInvoice = (id) => {
  //   navigate(`/invoice/create/customer/${id}/0`);
  // };

  // const handleChangeTab = (value) => {
  //   setTab(tab === value ? null : value);
  // };

  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  const handleCloseModal = () => {
    setShowAddUser(false);
  };

  // const handleUpdateCustomer = (customer) => {
  //   setCurrentCustomer(customer);
  //   setShowAddUser(true);
  // };

  const handleAddCustomer = () => {
    // setCurrentCustomer({});
    setShowAddUser(true);
  };

  const setDebouncedSearch = debounce((search) => {
    // dispatch(fetchCustomers(tab ? tab + "&search=" + search : "?search=" + search));
    setSearch(search);
  }, 1500);

  const handleSearch = (e) => {
    setDebouncedSearch(e.target.value);
  };

  //Function to cancel Invoice
  const deleteInvoice = (id) => {
    if (window.confirm("are you sure you want to cancel this invoice?")) {
      dispatch(cancelInvoice({ id }))
        .then((action) => {
          if (action.payload) {
          } else {
            toast.error("Invoice cancellation failed");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (invoicesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (invoicesNext) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [invoicesNext]);

  //fetch more expenses on scroll if available
  const fetchNextInvoices = () => {
    if (invoicesNext) {
      dispatch(fetchMoreInvoices(invoicesNext));
    }
  };

  return (
    <>
      <CustomModal isOpen={showAddUser} handleClose={handleCloseModal}>
        <AddUser handleCloseModal={handleCloseModal} currentCustomer={false} />
      </CustomModal>

      <div className="h-full w-full">
        <ul className="flex flex-row text-nowrap whitespace-nowrap flex-wrap justify-center md:justify-normal font-medium text-center gap-4 py-[25px] px-[20px] border-b-2 border-neutral-200">
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/all_invoice/"
              className="bg-black py-[10px] px-[20px] text-white hover:bg-white hover:text-black border-[1px] border-black rounded-full"
              aria-current="page"
            >
              ALL
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              INVOICE
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/credit_note"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              CREDIT NOTE
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/debit_note"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              DEBIT NOTE
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/invoice/Cancel_Invoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              CANCEL INVOICE
            </Link>
          </li>
          <li>
            <Link
              to="/invoice/estimate_invoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              ESTIMATE INVOICE
            </Link>
          </li>
          <li>
            <Link
              to="/invoice/einvoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              E-INVOICE
            </Link>
          </li>
        </ul>

        <div className="flex flex-col md:flex-row content-center h-[83vh]">
          {/* customer container  */}
          <div className="w-full border-b-2 md:border-b-0 py-[20px] md:border-r-2 border-neutral-200">
            <div className="flex md:w-[40%] items-center gap-[10px] px-[20px]">
              {/* search Bar */}
              <div className="w-full">
                <label for="default-search" className="mb-2 xl:text-sm text-xs font-medium text-gray-900 sr-only">
                  Search
                </label>
                <div className="relative">
                  <input
                    type="search"
                    onChange={handleSearch}
                    id="default-search"
                    className="block w-full py-2 px-3 pe-10 xl:text-sm text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Mobile numbers or Username"
                    required
                  />
                  <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
                    <svg
                      className="w-4 h-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Filter Button */}
              <div className="relative">
                <button
                  onClick={() => {
                    handleShowMenu("settings");
                  }}
                >
                  <IconWrapper>
                    <Filter className="z-10 text-white" />
                  </IconWrapper>
                </button>
                <div
                  id="dropdownInformation"
                  onMouseLeave={() => handleShowMenu("settings")}
                  className={`${
                    showMenu !== "settings" ? "hidden" : ""
                  } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                >
                  <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                    <li>
                      <button
                        onClick={() => setFilterValue("invoices")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        All
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setFilterValue("cancelled_invoices")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        Cancelled Invoices
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* add Customer Button */}
              <div>
                <button
                  type="submit"
                  className="text-white whitespace-nowrap bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-neutral-200 font-semibold rounded-full xl:text-sm text-xs px-3 py-3"
                  onClick={handleAddCustomer}
                >
                  Add Customer
                </button>
              </div>
            </div>
            {/* <div className="relative flex p-2">
              <div className="">
                <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm  border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?favourite=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?favourite=1")}
                >
                  Favourite
                </button>
                <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm  border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?most_frequent=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?most_frequent=1")}
                >
                  Frequent
                </button>
                <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm  border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?most_profitable=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?most_profitable=1")}
                >
                  Top User
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="absolute right-0 m-4 text-white bg-transparent  hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 my-auto w-10"
                >
                  {" "}
                  <img src={filterImage} alt="Filter" srcset="" />
                </button>
              </div>
            </div> */}
            <div className="px-[20px] max-h-[76vh] overflow-y-scroll mt-[20px] border-t-2 border-neutral-200 py-[20px]">
              <InfiniteScroll
                dataLength={invoices.length}
                next={fetchNextInvoices}
                hasMore={hasMoreInvoices}
                height={"55vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Invoices List End</b>
                  </p>
                }
              >
                <table border="1" className="table-auto w-full">
                  <thead className="bg-gray-50">
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Date
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Invoice No.
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Customer
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Amount
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Status
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Invoice PDF
                    </th>
                    {!isCancelled && (
                      <th
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                        scope="col"
                      >
                        Invoice Action
                      </th>
                    )}
                    {isCancelled && (
                      <th
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                        scope="col"
                      >
                        Invoice Status
                      </th>
                    )}
                  </thead>
                  <tbody>
                    {invoices?.map((invoice) => (
                      <tr key={invoice?.id}>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {moment(invoice.order_date_time).format("LLL")}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.invoice_counter}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.customer?.customer_name}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          Rs {invoice?.grand_total}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.payment_type}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white border-[1px] border-neutral-200">
                          <Link to={invoice?.Invoice} target="_blank" rel="noopener noreferrer">
                            <button className="capitalize">download</button>
                          </Link>
                        </td>
                        {!isCancelled && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500 font-semibold bg-white border-[1px] border-neutral-200">
                            <button onClick={() => deleteInvoice(invoice?.id)}>Cancel Invoice</button>
                          </td>
                        )}
                        {isCancelled && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500 font-semibold bg-white border-[1px] border-neutral-200">
                            Cancelled
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
          {/* graph container */}
          {/* <div className="w-full h-[83vh] md:w-[40%] border-r-2 border-netural-200 flex justify-center pt-[20px]">
            <InvoiceBarChart />
          </div> */}
          {/* recommend product container  */}
          {/* <div className="w-full h-[83vh] md:w-[20%] flex justify-center pt-[20px]">
            <div className="w-full px-[20px]">
              <CommonLists title="Product Recommended List" data={recommendedProducts} />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default AllInvoice;
