export default function formatDuration(totalSeconds) {
  const days = Math.floor(totalSeconds / 86400); // 86400 seconds in a day
  const hours = Math.floor((totalSeconds % 86400) / 3600); // 3600 seconds in an hour
  const minutes = Math.floor((totalSeconds % 3600) / 60); // 60 seconds in a minute

  let result = "";

  if (days > 0) {
    result += `${days} ${days === 1 ? "day" : "days"}`;
  }
  if (hours > 0) {
    if (result) result += ", ";
    result += `${hours} ${hours === 1 ? "hour" : "hours"}`;
  }
  if (minutes > 0) {
    if (result) result += ", ";
    result += `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  }

  return result || "0 minutes"; // Default to '0 minutes' if no value is set
}
