import React, { useState } from "react";
import { http } from "../../service/http";
import { useDispatch } from "react-redux";
import { addCustomer, updateCustomer } from "../../reducers/Slices/customerSlice";
import { useNavigate } from "react-router-dom";
import { searchZipcode } from "../../reducers/Slices/userSlice";
import toast from "react-hot-toast";
// import { updateVendor } from "../../reducers/Slices/vendorSlice";
// import { toggleAlert } from "../../reducers/Slices/appSlice";

function Customer(props) {
  const { formData, setFormData, setData, isNew, handleCloseModal } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //pHaniver - todo - move to redux reducers
  const handleSearchZipcode = (e) => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code: action.payload.state_code,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    if (isNew) {
      http
        .post(`/user_profile/backend/customer/`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setData(res.data.data);
            dispatch(addCustomer(res.data.data));
            setDisableSubmitButton(false);
            // navigate(`/invoice/create/customer/${res.data.data.id}/${res.data.data.is_purchase ? 1 : 0}`);
            handleCloseModal();
          }
        })
        .catch((err) => {
          toast.error(err);
          setDisableSubmitButton(false);
        });
    } else {
      dispatch(updateCustomer(formData))
        .then((action) => {
          if (action.payload) {
            setDisableSubmitButton(false);
            handleCloseModal();
          } else {
            toast.error("Sorry, Details updated cannot be added!");
            setDisableSubmitButton(false);
          }
        })
        .catch((err) => {
          toast.error(err);
          setDisableSubmitButton(false);
        });
    }
  };

  return (
    <div className="p-4  w-full">
      <div className="flex justify-center">
        <form className="w-full mx-auto" onSubmit={handleSubmit}>
          <div className="w-full">
            <div className="mb-5">
              <label htmlFor="customer_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                Full Customer Name*
              </label>
              <input
                type="text"
                id="customer_name"
                onChange={(e) => setFormData({ ...formData, customer_name: e.target.value })}
                value={formData.customer_name}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                placeholder="name@profitai.com"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                Mobile Number*
              </label>
              <input
                type="number"
                id="phone_number"
                value={formData.phone_number}
                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value.slice(0, 10) })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                min={0}
                max={9999999999}
                placeholder="Enter mobile number"
                required
              />
            </div>
            <div>
              <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                Zipcode*
              </label>
              <div className="relative z-0 flex gap-[10px] mb-5 group">
                <input
                  type="zipcode"
                  onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                  id="zipcode"
                  value={formData.zipcode}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter zipcode"
                  required
                />
                <button
                  type="button"
                  onClick={handleSearchZipcode}
                  className="bg-black text-white px-[20px] py-[10px] rounded-lg"
                >
                  Find
                </button>
              </div>
            </div>
            {/* <div className="flex mb-5 space-x-4">
              <div className="w-1/2 p-1">
                {" "}
                <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 ">
                  State
                </label>
                <input
                  type="state"
                  id="state"
                  value={formData.state}
                  onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter state"
                  required
                />
              </div>
              <div className="w-1/2 p-1">
                {" "}
                <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                  City
                </label>
                <input
                  type="city"
                  id="city"
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter city"
                  required
                />
              </div>
            </div> */}
            <form className="mb-5 mx-auto">
              <label htmlFor="address" className="block mb-2 text-sm font-medium">
                Address*
              </label>
              <textarea
                id="address"
                rows="2"
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="block p-2.5 w-full text-sm rounded-lg border border-black focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={formData.address}
                placeholder="Enter address"
                required
              ></textarea>
            </form>
            <div className="mb-5">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                Email*
              </label>
              <input
                type="email"
                id="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                value={formData.email}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                placeholder="name@profitai.com"
                required
              />
            </div>
            <div className="w-full flex items-center justify-center py-[20px]">
              <button
                type="submit"
                disabled={disableSubmitButton}
                // onClick={handleSubmit}
                className="bg-black text-white rounded-full px-[20px] py-[10px]"
              >
                Click to Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Customer;
