import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateBatch } from "../../../reducers/Slices/productSlice";
import { changeDateFormat } from "../../../utils/dateFormat";

// pHaniver - TODO - move CustomModel to Macro Component
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import toast from "react-hot-toast";
import BarcodeInput from "./BarcodeInput";

function EditBatch({ batch, product }) {
  //Modal toggler
  const [showModal, setShowModal] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //State to hold batch form data
  const [batchFormData, setBatchFormData] = useState({
    product_id: product?.id,
    batch_id: batch.id,
    batch: {
      ...batch,
      size: "Deprecated",
      total_quantity: 0,
    },
  });

  function handleEditBatchClick() {
    setShowModal(true);
  }

  function handleClose() {
    setShowModal(false);
  }

  // useEffect(() => {
  //   setBatchFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       batch: { ...batch, total_quantity: parseInt(batch?.remaining_quantity ?? 0) + parseInt(batch?.deal ?? 0) },
  //     };
  //   });
  //   //eslint-disable-next-line
  // }, [batchFormData?.batch?.remaining_quantity, batchFormData?.batch?.deal]);

  useEffect(() => {
    if (batchFormData) {
      setBatchFormData((prevState) => {
        const updatedBatch = {
          ...prevState.batch,
          total_quantity:
            parseInt(prevState.batch.remaining_quantity ?? 0) + parseInt(prevState?.batch?.deal_quantity ?? 0),
        };
        return { ...prevState, batch: updatedBatch };
      });
    }

    //eslint-disable-next-line
  }, [batchFormData?.batch?.remaining_quantity, batchFormData?.batch?.deal_quantity]);

  const dispatch = useDispatch();

  //Update Product when parent product changes
  useEffect(() => {
    if (product) {
      const { id } = product;
      const batchFormDataWithProductId = { ...batchFormData, product_id: id };
      setBatchFormData(batchFormDataWithProductId);
    }
    //eslint-disable-next-line
  }, [product]);

  function handleSubmit() {
    setDisableSubmitButton(true);
    //eslint-disable-next-line
    dispatch(updateBatch(batchFormData))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          toast.error("Sorry, batch cannot be updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error("Error occurred while updating batch");
        setDisableSubmitButton(false);
      });
  }

  function handleDateChange(e) {
    setBatchFormData({
      ...batchFormData,
      batch: { ...batchFormData.batch, expiry_date: e.target.value },
    });
  }

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect to set batch if available in batches
  useEffect(() => {
    if (batch?.bar_code_number) {
      setBarcodeNumber(batch?.bar_code_number);
    }
  }, [batch]);

  //side effect for barcode.
  useEffect(() => {
    setBatchFormData((prevState) => {
      return { ...prevState, batch: { ...prevState?.batch, bar_code_number: barcodeNumber } };
    });
  }, [barcodeNumber]);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center">Batch Description</div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="batch-number" className="text-neutral-500">
              Batch Number*
            </label>
            <input
              type="text"
              id="batch-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.batch_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, batch_number: e.target.value },
                })
              }
              required
            />
          </div>
          {/* <div>
            <label htmlFor="size">Size</label>
            <input
              type="text"
              id="size"
              value={batchFormData.batch.size}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, size: e.target.value } })
              }
            />
          </div> */}
          {/* <div>
            <label htmlFor="total-quantity">Total Quantity</label>
            <input
              type="number"
              id="total-quantity"
              value={batchFormData.batch.total_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, total_quantity: e.target.value },
                })
              }
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="remaining-quantity" className="text-neutral-500">
              Quantity*
            </label>
            <input
              type="number"
              id="remaining-quantity"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batch?.remaining_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, remaining_quantity: e.target.value },
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Deal/Free
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batch?.deal}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, deal: e.target.value },
                })
              }
              required
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="mrp-price" className="text-neutral-500">
              MRP Price*
            </label>
            <input
              type="text"
              id="mrp-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.mrp_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, mrp_price: e.target.value },
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="sales-price" className="text-neutral-500">
              Sales Price*
            </label>
            <input
              type="text"
              id="sales-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.sales_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, sales_price: e.target.value },
                })
              }
              required
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Rate A / Retail*
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.sales_price}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, sales_price: e.target.value } })
              }
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="purchase-price" className="text-neutral-500">
              Purchase Price*
            </label>
            <input
              type="text"
              id="purchase-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.purchase_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, purchase_price: e.target.value },
                })
              }
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="tax" className="text-neutral-500">
              Tax %
            </label>
            <input
              type="text"
              id="tax"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.tax}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, tax: e.target.value } })
              }
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="discount" className="text-neutral-500">
              Discount %
            </label>
            <input
              type="text"
              id="discount"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.discount}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, discount: e.target.value } })
              }
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="add-margin" className="text-neutral-500">
              Add Margin %
            </label>
            <input
              type="text"
              id="add-margin"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.add_margin}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, add_margin: e.target.value },
                })
              }
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="bar-code-number" className="text-neutral-500">
              Bar Code Number*
            </label>
            <input
              type="text"
              id="bar-code-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.bar_code_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, bar_code_number: e.target.value },
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              HSN Number*
            </label>
            <input
              type="text"
              id="hsn-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.hsn_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, hsn_number: e.target.value },
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="package" className="text-neutral-500">
              Package*
            </label>
            <input
              type="text"
              id="package"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.package}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, package: e.target.value },
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="status" className="text-neutral-500">
              Status
            </label>
            <input
              type="text"
              id="status"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.status}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, status: e.target.value } })
              }
            />
          </div> */}
          {/* For Barcode  */}
          <div className="relative">
            <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="expiry-date" className="text-neutral-500">
              Expiry Date
            </label>
            <input
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              type="date"
              id="expiry-date"
              value={batchFormData?.batch?.expiry_date ? changeDateFormat(batchFormData?.batch?.expiry_date) : null}
              onChange={handleDateChange}
            />
          </div>
          <div className="flex gap-[20px] justify-center">
            <button
              className="save px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <button className="font-bold" onClick={() => handleEditBatchClick(true)}>
          Edit
        </button>
      </div>
    </>
  );
}

export default EditBatch;
