import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateProduct } from "../../../reducers/Slices/productSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import toast from "react-hot-toast";
import DurationInput from "./DurationInput";
import BarcodeInput from "./BarcodeInput";

function EditProduct({ product }) {
  // Modal toggler
  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState(null);

  // State to hold form data for edit form
  const [editProductFormData, setEditProductFormData] = useState({
    id: product?.id || "",
    product_name: product?.product_name || "",
    brand: product?.brand || "",
    hsn_number: product?.hsn_number || "",
    package: product?.package || "",
    is_service: product?.is_service || "N",
    rate: product?.rate || 0,
    cost: product?.cost || 0,
    duration_time: product?.duration_time || 0,
    tax: product?.tax || 0,
    bar_code_number: product?.bar_code_number || 0,
    mrp: product?.mrp || 0,
    batches: product?.batches || [],
  });

  // Update Product when parent product changes
  useEffect(() => {
    if (product) {
      const { id, product_name, brand, hsn_number, is_service, rate, cost, duration_time, tax, mrp, batches } = product;

      setEditProductFormData({
        id,
        product_name,
        brand,
        hsn_number,
        package: product?.package,
        is_service: is_service ?? "N",
        rate: rate ?? 0,
        cost: cost ?? 0,
        duration_time: duration_time ?? 0,
        tax: tax ?? 0,
        mrp: mrp ?? 0,
        batches,
      });
    }
  }, [product]);

  useEffect(() => {
    setEditProductFormData((prevState) => {
      return { ...prevState, duration_time: totalSeconds };
    });
  }, [totalSeconds]);

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect to set batch if available in batches
  useEffect(() => {
    if (product?.bar_code_number) {
      setBarcodeNumber(product?.bar_code_number);
    }
  }, [product]);

  //side effect for barcode.
  useEffect(() => {
    setEditProductFormData((prevState) => {
      return { ...prevState, bar_code_number: barcodeNumber };
    });
  }, [barcodeNumber]);

  function handleEditClick() {
    setShowModal(true);

    //Only autofill totalSeconds if product is service based product
    if (product?.is_service === "Y") {
      setTotalSeconds(parseInt(product?.duration_time));
    }
  }

  const dispatch = useDispatch();

  const handleClose = () => {
    if (product) {
      const { id, product_name, brand, hsn_number, is_service, rate, cost, duration_time, tax, mrp, batches } = product;

      setEditProductFormData({
        id,
        product_name,
        brand,
        hsn_number,
        package: product?.package,
        is_service: is_service ?? "N",
        rate: rate ?? 0,
        cost: cost ?? 0,
        duration_time: duration_time ?? 0,
        tax: tax ?? 0,
        mrp: mrp ?? 0,
        batches,
      });
    }
    setShowModal(false);
  };

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    // Guard - check required fields
    if (
      !editProductFormData?.product_name ||
      !editProductFormData?.brand ||
      !editProductFormData?.package ||
      !editProductFormData?.hsn_number ||
      (editProductFormData.is_service === "Y" && (!editProductFormData.rate || !editProductFormData.mrp))
    ) {
      toast.error("please fill all field values in form!");
      setDisableSubmitButton(false);
      return;
    }

    setDisableSubmitButton(false);
    console.log(editProductFormData, "this is the form data");
    dispatch(updateProduct(editProductFormData))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          toast.error("Sorry, product cannot be Edited");
          setDisableSubmitButton(false);
        }
      })
      .catch(() => {
        toast.error("Error Occured");
        setDisableSubmitButton(false);
      });
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center">Product description</div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="Product-name" className="text-neutral-500">
              Product / Service Name*
            </label>
            <input
              type="text"
              id="Product-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={editProductFormData.product_name}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, product_name: e.target.value })}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="brand-name" className="text-neutral-500">
              Brand / Company Name*
            </label>
            <input
              type="text"
              id="brand-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={editProductFormData.brand}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, brand: e.target.value })}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              HSN Number*
            </label>
            <input
              type="text"
              id="hsn-number"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={editProductFormData.hsn_number}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, hsn_number: e.target.value })}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="product-package" className="text-neutral-500">
              Product Packaging / Service Type*
            </label>
            <input
              type="text"
              id="product-package"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={editProductFormData.package}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, package: e.target.value })}
              required
            />
          </div>

          {/* is_service field */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="is-service" className="text-neutral-500">
              Is Service*
            </label>
            <select
              id="is-service"
              className="border-[1px] border-neutral-500 bg-white px-[10px] py-[10px]"
              value={editProductFormData.is_service}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, is_service: e.target.value })}
              required
            >
              <option value="N">No</option>
              <option value="Y">Yes</option>
            </select>
          </div>

          {/* Conditionally render extra fields if is_service is "Y" */}
          {editProductFormData.is_service === "Y" && (
            <>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="mrp-price" className="text-neutral-500">
                  MRP Price*
                </label>
                <input
                  type="number"
                  id="mrp-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData.mrp}
                  onChange={(e) => setEditProductFormData({ ...editProductFormData, mrp: parseFloat(e.target.value) })}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="sales-price" className="text-neutral-500">
                  Rate*
                </label>
                <input
                  type="number"
                  id="sales-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData.rate}
                  onChange={(e) => setEditProductFormData({ ...editProductFormData, rate: parseFloat(e.target.value) })}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="purchase-price" className="text-neutral-500">
                  Service Cost to Business / Expense*
                </label>
                <input
                  type="number"
                  id="purchase-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData.cost}
                  onChange={(e) => setEditProductFormData({ ...editProductFormData, cost: parseFloat(e.target.value) })}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="tax-percentage" className="text-neutral-500">
                  Tax %*
                </label>
                <input
                  type="number"
                  id="tax-percentage"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData.tax}
                  onChange={(e) => setEditProductFormData({ ...editProductFormData, tax: parseFloat(e.target.value) })}
                  required
                />
              </div>
              {/* Barcode Input */}
              <div className="relative">
                <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
              </div>
              <DurationInput totalSeconds={totalSeconds} setTotalSeconds={setTotalSeconds} />
            </>
          )}

          <div className="flex justify-center gap-[20px]">
            <button
              className="save bg-black px-[20px] py-[10px] text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>

      {/* Edit Product Button */}
      <ButtonWrapper type="button" eventCallback={handleEditClick}>
        Edit Product
      </ButtonWrapper>
    </>
  );
}

export default EditProduct;
