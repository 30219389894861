import React, { useState } from "react";

export const ShipmentDispatchForm = ({ dispatch, setDispatch, shipment, setShipment, customer, business }) => {
  // State for business and customer information
  // const [business] = useState({
  //   name: "Jane Doe",
  //   address1: "789 Elm Street",
  //   address2: "Apt 101",
  //   city: "Los Angeles",
  //   pin: "90001",
  //   state_code: "CA",
  // });

  // const [customer] = useState({
  //   name: "John Doe",
  //   address1: "123 Main Street",
  //   address2: "Suite 456",
  //   city: "New York",
  //   pin: "10001",
  //   state_code: "NY",
  // });

  // State for dispatch and shipment
  // const [dispatch, setDispatch] = useState({});
  // const [shipment, setShipment] = useState({});

  // Radio button states
  const [isDispatchSameAsBusiness, setIsDispatchSameAsBusiness] = useState(true);
  const [isShipmentSameAsCustomer, setIsShipmentSameAsCustomer] = useState(true);

  const handleShipmentCheckboxChange = (value) => {
    if (value) {
      setIsShipmentSameAsCustomer(value);
      return;
    }

    if (!customer) {
      return;
    }

    const {
      customer_name,
      address: customer_address1,
      address2: customer_address2,
      zipcode: customer_zipcode,
      city: customer_city,
      state_code: customer_state_code,
    } = customer;

    setShipment(() => {
      return {
        shipment_name: customer_name,
        shipment_address1: customer_address1,
        shipment_address2: customer_address2 || "",
        shipment_city: customer_city,
        shipment_pin: customer_zipcode,
        shipment_state_code: customer_state_code,
      };
    });

    setIsShipmentSameAsCustomer(value);
  };

  const handleDispatchCheckboxChange = (value) => {
    if (value) {
      setIsDispatchSameAsBusiness(value);
      return;
    }

    if (!business) {
      return;
    }

    const {
      business_name,
      address1: business_address1,
      address2: business_address2,
      city: business_city,
      zipcode: business_zipcode,
      state_code: business_state_code,
      country_code: business_country_code,
    } = business;

    setDispatch(() => {
      return {
        dispatch_name: business_name,
        dispatch_address1: business_address1,
        dispatch_address2: business_address2 || "",
        dispatch_city: business_city,
        dispatch_pin: business_zipcode,
        dispatch_state_code: business_state_code,
        country_code: business_country_code,
      };
    });

    setIsDispatchSameAsBusiness(value);
  };

  // Handlers for manual dispatch and shipment input changes
  const handleDispatchChange = (e) => {
    const { name, value } = e.target;
    setDispatch((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleShipmentChange = (e) => {
    const { name, value } = e.target;
    setShipment((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="mb-[40px]">
      <div className="w-full">
        <h2 className="text-lg font-bold text-black py-[10px]">Shipment Information</h2>
        {/* checkbox button for Shipment - Same as Customer */}
        <label className="flex gap-[5px] max-w-fit">
          <input
            type="checkbox"
            checked={isShipmentSameAsCustomer}
            onChange={() => handleShipmentCheckboxChange(!isShipmentSameAsCustomer)}
          />
          Same as Customer
        </label>
        {/* Conditional input for Shipment */}
        {
          <form className="mt-[10px] mb-[20px]">
            <div className={`flex gap-[10px] ${!isShipmentSameAsCustomer ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isShipmentSameAsCustomer ? "w-[100px]" : "min-w-fit"}`}>
                Name:
              </label>
              <input
                type="text"
                name="shipment_name"
                className={`text-neutral-500 ${
                  !isShipmentSameAsCustomer ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={shipment?.shipment_name}
                onChange={handleShipmentChange}
                disabled={isShipmentSameAsCustomer}
              />
            </div>
            <div className={`flex gap-[10px] ${!isShipmentSameAsCustomer ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isShipmentSameAsCustomer ? "w-[100px]" : "min-w-fit"}`}>
                Address 1:
              </label>
              <input
                type="text"
                name="shipment_address1"
                className={`text-neutral-500 ${
                  !isShipmentSameAsCustomer ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={shipment?.shipment_address1}
                onChange={handleShipmentChange}
                disabled={isShipmentSameAsCustomer}
              />
            </div>
            <div className={`flex gap-[10px] ${!isShipmentSameAsCustomer ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isShipmentSameAsCustomer ? "w-[100px]" : "min-w-fit"}`}>
                Address 2:
              </label>
              <input
                type="text"
                name="shipment_address2"
                className={`text-neutral-500 ${
                  !isShipmentSameAsCustomer ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={shipment?.shipment_address2}
                onChange={handleShipmentChange}
                disabled={isShipmentSameAsCustomer}
              />
            </div>
            <div className={`flex gap-[10px] ${!isShipmentSameAsCustomer ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isShipmentSameAsCustomer ? "w-[100px]" : "min-w-fit"}`}>
                City:
              </label>
              <input
                type="text"
                name="shipment_city"
                className={`text-neutral-500 ${
                  !isShipmentSameAsCustomer ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={shipment?.shipment_city}
                onChange={handleShipmentChange}
                disabled={isShipmentSameAsCustomer}
              />
            </div>
            <div className={`flex gap-[10px] ${!isShipmentSameAsCustomer ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isShipmentSameAsCustomer ? "w-[100px]" : "min-w-fit"}`}>
                Pin:
              </label>
              <input
                type="text"
                name="shipment_pin"
                className={`text-neutral-500 ${
                  !isShipmentSameAsCustomer ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={shipment?.shipment_pin}
                onChange={handleShipmentChange}
                disabled={isShipmentSameAsCustomer}
              />
            </div>
            <div className={`flex gap-[10px] ${!isShipmentSameAsCustomer ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isShipmentSameAsCustomer ? "w-[100px]" : "min-w-fit"}`}>
                State Code:
              </label>
              <input
                type="text"
                name="shipment_state_code"
                className={`text-neutral-500 ${
                  !isShipmentSameAsCustomer ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={shipment?.shipment_state_code}
                onChange={handleShipmentChange}
                disabled={isShipmentSameAsCustomer}
              />
            </div>
          </form>
        }
      </div>

      <div className="w-full">
        <h2 className="text-lg text-black font-bold py-[10px]">Dispatch Information</h2>
        {/* checkbox button for Dispatch - Same as Business */}
        <label className="flex gap-[5px] max-w-fit">
          <input
            type="checkbox"
            checked={isDispatchSameAsBusiness}
            onChange={() => handleDispatchCheckboxChange(!isDispatchSameAsBusiness)}
          />
          Same as Business
        </label>
        {/* Conditional input for Dispatch */}
        {
          <form className="mt-[10px]">
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                Name:
              </label>
              <input
                type="text"
                name="dispatch_name"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.dispatch_name}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                Address 1:
              </label>
              <input
                type="text"
                name="dispatch_address1"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.dispatch_address1}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                Address 2:
              </label>
              <input
                type="text"
                name="dispatch_address2"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.dispatch_address2}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                City:
              </label>
              <input
                type="text"
                name="dispatch_city"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.dispatch_city}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                Pin:
              </label>
              <input
                type="text"
                name="dispatch_pin"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.dispatch_pin}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                State Code:
              </label>
              <input
                type="text"
                name="dispatch_state_code"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.dispatch_state_code}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
            <div className={`flex gap-[10px] ${!isDispatchSameAsBusiness ? "py-[5px]" : ""}`}>
              <label className={`text-black font-semibold ${!isDispatchSameAsBusiness ? "w-[100px]" : "min-w-fit"}`}>
                Country Code:
              </label>
              <input
                type="text"
                name="country_code"
                className={`text-neutral-500 ${
                  !isDispatchSameAsBusiness ? "border-b border-neutral-600 w-[65%]" : "w-full"
                }`}
                value={dispatch?.country_code}
                onChange={handleDispatchChange}
                disabled={isDispatchSameAsBusiness}
              />
            </div>
          </form>
        }
      </div>
    </div>
  );
};
