import React from "react";
import { useDispatch } from "react-redux";
import { deleteVendor } from "../../../reducers/Slices/ledgerSlice";
import { X as Cross } from "react-feather";
import toast from "react-hot-toast";

function DeleteVendor({ vendor }) {
  const dispatch = useDispatch();
  function handleDeleteVendor() {
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      dispatch(deleteVendor({ vendor_id: vendor.id }))
        .then((action) => {
          if (action.payload) {
          } else {
            toast.error("sorry, delete not successful!");
          }
        })
        .catch(() => {
          toast.error("some error occured!");
        });
    }
  }

  return (
    <button
      type="button"
      className="flex items-center font-medium gap-[6px] border-[1px] border-red-500 text-red-500 text-md px-2 py-[2px] rounded-full ml-2"
      onClick={() => handleDeleteVendor()}
    >
      delete
      <Cross className="h-[15px] w-[15px]" />
    </button>
  );
}

export default DeleteVendor;
