// import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors, fetchMoreVendors } from "../../reducers/Slices/vendorSlice";
import { createPurchaseInvoice } from "../../reducers/Slices/purchaseInvoiceSlice";
import AddProduct from "./purchaseComponents/AddProduct";
import { changeDateFormat } from "../../utils/dateFormat";
import {
  fetchMoreProducts,
  fetchProductByBarcode,
  fetchProducts,
  searchProducts,
} from "../../reducers/Slices/productSlice";
import moment from "moment";
// import { createInvoice } from "../../reducers/Slices/invoiceSlice";
// import { toggleAlert } from "../../reducers/Slices/appSlice";

//UI Components
import IconWrapper from "../MicroComponents/IconWrapper";
import { X as Cross, Maximize } from "react-feather";
import { useHotkeys } from "react-hotkeys-hook";
import toast from "react-hot-toast";
import BarcodeReader from "../Invoices/InvoiceComponents/BarcodeReader";
import BarcodePopup from "./purchaseComponents/BarcodePopup";
import { debounce } from "lodash";

function CreatePurchaseInvoice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [items, setItems] = useState([{}]);
  // const [discountTaxItems, setDiscountTaxItems] = useState([{}]);

  //Optional Parameters for Purchase Invoice
  const [eBillValue, setEBillValue] = useState(null);
  const [invoiceIdValue, setInvoiceIdValue] = useState(null);
  const [billDate, setBillDate] = useState(moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Amount value for display
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  const [showModal, setShowModal] = useState(false);

  //Hot key to add item
  useHotkeys("Ctrl + Alt + P, Ctrl + Option + P", () => setShowModal(!showModal));

  const params = useParams();

  useEffect(() => {
    dispatch(fetchVendors());
  }, [dispatch]);

  //Fetch both customer and vendor based on ID provided
  const vendorsNext = useSelector((state) => state.vendor.vendors.next);
  const vendor = useSelector((state) => state.vendor.vendors.data.find((v) => v.id === parseInt(params.id)));

  //Fetch customers till end
  useEffect(() => {
    if (vendorsNext) {
      dispatch(fetchMoreVendors(vendorsNext));
    }
  }, [vendorsNext, dispatch]);

  const fetchedProducts = useSelector((state) => state.product.products.data);
  const products = fetchedProducts.filter((product) => product?.is_service === "N");
  // const productsNext = useSelector((state) => state.product.products.next);

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return { vendor: parseInt(params.id), payment_option: "cash", payment_type: "paid", description: null };
  });

  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();

  //Payment Options
  const paymentOptions = [
    { label: "Cash", value: "cash" },
    { label: "UPI", value: "upi" },
    { label: "Debit", value: "debit" },
    { label: "Credit", value: "credit" },
  ];

  //Fetch all products
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchProducts());
    }
  }, [dispatch]);

  //Fetch infinity products
  // useEffect(() => {
  //   if (productsNext) {
  //     dispatch(fetchMoreProducts(productsNext));
  //   }
  // }, [productsNext, dispatch]);

  useEffect(() => {
    handleCalculate();
  }, [items]);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        order_date_time: billDate,
        ewaybill_number: eBillValue,
        invoice_counter: invoiceIdValue,
      };
    });
  }, [billDate, eBillValue, invoiceIdValue]);

  const handleChangeItem = (value, index) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            productId: value?.id,
            batch: {
              size: null,
              add_margin: null,
              hsn_number: value?.hsn_number ?? null,
              package: value?.product_packaging ?? null,
              sales_price: null,
              deal_quantity: 0,
            },
          };
        }
        return item;
      });
      return updatedItems;
    });
    // setItems((prevItems) => {
    //   const updatedItems = prevItems.map((item, i) => {
    //     if (i === index) {
    //       return value ? { ...value, price: value.rate, batches: value.batches } : { price: 0.0, tax: 0.0 };
    //     }
    //     return item;
    //   });
    //   return updatedItems;
    // });
  };

  // const handleChangeDiscountTaxItems = (value, index, field) => {
  //   setDiscountTaxItems((prevItems) => {
  //     const updatedDiscountTaxItems = prevItems.map((item, i) => {
  //       if (i === index) {
  //         return { ...item, [field]: value };
  //       }
  //       return item;
  //     });
  //     updateItemFields(index, null, updatedDiscountTaxItems);
  //     return updatedDiscountTaxItems;
  //   });
  // };

  // Profit Margin for user
  // const [profitMargin, setProfitMargin] = useState(0);

  const handleCalculate = () => {
    const { total, discount, tax } = items.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.batch?.rate) {
          const quantity = parseInt(currentItem?.batch?.remaining_quantity ?? 0);
          let item_total = parseFloat(currentItem?.batch?.rate || 0) * quantity;
          let item_discount = item_total * (currentItem?.batch?.discount ? currentItem?.batch?.discount / 100 : 0);

          let item_after_discount = item_discount ? item_total - item_discount : item_total;
          let item_tax = item_after_discount * (currentItem?.batch?.tax ? currentItem?.batch?.tax / 100 : 0);
          // let item_profit = ((currentItem?.batch?.rate ?? 0) - (currentItem?.batch?.purchase_price ?? 0)) * quantity;

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
          // accumulator.profit += item_profit;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0 }
    );

    // setProfitMargin(profit);

    setFormData({
      ...formData,
      sub_total: total.toFixed(2),
      discount: discount.toFixed(2),
      grand_total: (total - discount + tax).toFixed(3).slice(0, -1),
      tax: tax.toFixed(3),
      paid_amount: (total - discount + tax).toFixed(3).slice(0, -1),
      remaining_total: 0.0,
      product_and_quantity: items,
    });
  };

  // //Set form values
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     products: items.map((i) => ({
  //       product_name: i.product_name,
  //       brand: i.product_brand,
  //       qty: parseInt(i.quantity ?? 1),
  //     })),
  //   });
  // }, [items]);

  const handleFieldChange = (value, index, field) => {
    if (!items[index]?.productId) {
      toast.error("Product Field Empty!");
      return;
    }

    const updatedItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, batch: { ...item.batch, [field]: value } };
      }
      return item;
    });

    //Set Items
    setItems(updatedItems);
    updateItemFields(index, updatedItems);

    // setItems((prevItems) => {
    // const updatedItems = items.map((item, i) => {
    //   if (i === index) {
    //     return { ...item, batch: { ...item.batch, [field]: value } };
    //   }
    //   return item;
    // });

    // updateItemFields(index, updatedItems);

    // return updatedItems;
    // });
  };

  const updateItemFields = (index, updatedItems) => {
    // const latestDiscountTaxItems = updatedDiscountTaxItems ? updatedDiscountTaxItems : discountTaxItems;

    const rate = parseFloat(updatedItems[index]?.batch?.rate ?? 0);
    const quantity = parseInt(
      updatedItems[index]?.batch?.remaining_quantity ? parseInt(updatedItems[index]?.batch?.remaining_quantity) : 0
    );
    const deal = parseInt(updatedItems[index]?.batch?.deal_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.batch?.discount ?? 0) / 100);
    const discount = rate * discountPercent;
    // const totalDiscount = discount * quantity;

    // const taxPercent = parseFloat((latestDiscountTaxItems[index]?.tax_percent ?? 0) / 100);
    // const tax = (rate - discount) * taxPercent;
    // const totalTax = tax * quantity;

    const total_quantity = deal + quantity;
    const purchase_price = ((rate - discount) * quantity) / total_quantity;

    setItems((prevItems) => {
      const nextItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            amount: handleCalculateAmount(index, null, updatedItems),
            batch: {
              ...item.batch,
              purchase_price,
              total_quantity,
            },
          };
        }
        return item;
      });
      return nextItems;
    });
  };

  const handleCalculateAmount = (index, value, updatedItems) => {
    if (value || value === 0 || value === "0" || value === "") {
      setCalculatedAmountValue((prevItems) => {
        const updatedCalculatedAmount = prevItems.map((item, i) => {
          if (i === index) {
            return { calculated_amount: value ? parseFloat(value) : "" };
          }
          return item;
        });
        return updatedCalculatedAmount;
      });

      setItems((prevItems) => {
        const nextItems = prevItems.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              amount: value ? parseFloat(value) : "",
            };
          }
          return item;
        });
        return nextItems;
      });
      return 0;
    }

    const rate = parseFloat(updatedItems[index]?.batch?.rate ?? 0);
    const quanitity = parseInt(updatedItems[index]?.batch?.remaining_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.batch?.discount ?? 0) / 100);
    const discount = rate * discountPercent;

    const taxPercent = parseFloat((updatedItems[index]?.batch?.tax ?? 0) / 100);
    const tax = (rate - discount) * taxPercent;

    const amount = (rate + tax - discount) * quanitity;

    //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
    const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (i === index) {
          return { calculated_amount: truncatedAmount };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });

    return amount;
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
      // setDiscountTaxItems((prevItems) => {
      //   const updatedItems = [...prevItems]; // Create a copy of the array
      //   updatedItems.splice(index, 1); // Remove the item at the specified index
      //   return updatedItems; // Set the updated array as the new state
      // });
    }
    if (items.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
  };

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    dispatch(createPurchaseInvoice(formData))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          // dispatch(toggleAlert({ message: "Invoice created successfully!", type: "success", showAlert: true }));
          navigate(-1);
        } else {
          toast.error("sorry, Purchase Invoice Cannot be created");
          setDisableSubmitButton(false);
          // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
        }
      })
      .catch((err) => {
        toast.error("error", err);
        setDisableSubmitButton(false);
        // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
      });
  };
  //Guard to check for empty values
  // formData?.products.forEach((product) => {
  //   if (!product?.brand || !product?.product_name) {
  //     toast.error("Some Details are Empty! Please fill all details");
  //     return;
  //   }
  //   dispatch(createPurchaseInvoice(formData))
  //     .then((action) => {
  //       if (action.payload) {
  //         toast.success("Invoice Created successfully");
  //         // dispatch(toggleAlert({ message: "Invoice created successfully!", type: "success", showAlert: true }));
  //         navigate(-1);
  //       } else {
  //         toast.error("sorry, Invoice cannot be created");
  //         // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("error", err);
  //       // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
  //     });
  // });
  // if (items[0]?.id) {
  //   toast.success("Create Purchase Invoice was Clicked!");
  //   await dispatch(createInvoice(formData))
  //     .then((action) => {
  //       if (action.payload) {
  //         toast.success("Invoice Created successfully");
  //         // dispatch(toggleAlert({ message: "Invoice created successfully!", type: "success", showAlert: true }));
  //         navigate(-1);
  //       } else {
  //         toast.error("sorry, Invoice cannot be created");
  //         // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("error", err);
  //       // dispatch(toggleAlert({ message: "Sorry, Invoice cannot be create!", type: "error", showAlert: true }))
  //     });
  // } else {
  // dispatch(toggleAlert({ message: "Please , Add Products", type: "error", showAlert: true }));
  //   toast.error("Add more products");
  // }
  // };

  //Find HSN number based on batch selected
  //   const getHSNNumberByBatchId = (batchId) => {
  //     const product = products.find((p) => p.batches.some((batch) => batch.id === batchId));
  //     if (product) {
  //       const batch = product.batches.find((batch) => batch.id === batchId);
  //       return batch ? batch.hsn_number : null;
  //     }
  //     return null;
  //   };

  //Find product by product id for autocomplete
  const findProductByProductID = (id) => {
    if (!id) {
      return;
    }
    return products.find((product) => product.id === id);
  };
  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                if (window.confirm("want to add another item?")) {
                  addAnotherItemRef.current.focus(); // Focus on add another item button
                } else {
                  submitButton.current.focus(); //Focus on submit button
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  //Barcode related operations
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
    some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  //Do operations based on captured products
  useEffect(() => {
    //if product is service based product then don't add it.
    if (capturedProduct?.is_service === "Y") {
      toast.error("Sorry! seems like barcode you scanned is for a service!");
      return;
    }
    //Add scanned products into purchase invoice with this data format
    const productData = {
      productId: capturedProduct?.id,
      batch: {
        size: null,
        add_margin: null,
        hsn_number: capturedProduct?.hsn_number ?? null,
        package: capturedProduct?.product_packaging ?? null,
        sales_price: null,
        deal_quantity: 0,
        remaining_quantity: 1,
      },
    };

    let updatedItems = [];

    //if there is nothing in items then add product instead of empty object otherwise just add product to already available product.
    if (!items[0]?.productId) {
      updatedItems = [productData];
    } else {
      updatedItems = [...items, productData];
      setCalculatedAmountValue((prev) => [...prev, {}]);
    }

    setItems(updatedItems);
    //eslint-disable-next-line
  }, [capturedProduct]);

  const [barcodePopup, setBarcodePopup] = useState(false);
  const [clickedBarcodeIndex, setClickedBarcodeIndex] = useState(0);
  // const [barcodeNumber, setBarcodeNumber] = useState('');

  const handleBarcodeButtonClick = (index) => {
    // barcodeInput = barcodeRefs.current[index].queryS
    setClickedBarcodeIndex(index);
    setBarcodePopup(!barcodePopup);
  };
  //Handle Product Search in Autocomplete

  //Searched products
  const searchedProducts = useSelector((state) => state.product.searchedProducts.data);

  //Debounced search term state to call searched product API
  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 500);

  useEffect(() => {
    console.log(productSearchTerm, "this is the product search term");
    dispatch(searchProducts(productSearchTerm));
  }, [productSearchTerm]);

  useEffect(() => {
    console.log(searchedProducts, "these are searched products");
  }, [searchedProducts]);

  return (
    <>
      <AddProduct showModal={showModal} setShowModal={setShowModal} />
      <div>
        <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
        {barcodePopup && (
          <BarcodePopup setBarcodePopup={setBarcodePopup} index={clickedBarcodeIndex} setItems={setItems} />
        )}
        <div className="mx-[20px] my-[20px]">
          <h1 className="text-2xl font-bold"> New Purchase Invoice </h1>
          <div className="mt-8 w-full flex md:justify-start border-b-2 border-neutral-200">
            <div className="md:w-3/6 w-full">
              <h3 className="font-semibold text-xl text-black">{vendor?.vendor_name}</h3>
              <span className="font-semibold text-neutral-500">{`${vendor?.address} ${vendor?.zipcode}`}</span>
              {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-black">Invoice Number</h3>
              <span className="font-semibold text-neutral-500">{formData.invoiceId}</span>
              </div>
              <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-black">Order Number</h3>
              <h3 className="font-semibold text-neutral-500">{formData.orderId}</h3>
              </div> */}
              <div className="flex text-base gap-2 my-5">
                <h3 className="font-semibold text-black">GST Number: </h3>
                <span className="font-semibold text-neutral-500">
                  {vendor?.gst_number ? vendor?.gst_number?.toUpperCase() : "Not Provided"}
                </span>
              </div>
              {/* Optional E-Bill ID */}
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="Ebill" className="font-semibold text-black">
                  Invoice Id (opt.):{" "}
                </label>
                <input
                  type="text"
                  className="border border-neutral-300 px-[20px] py-[10px] rounded-md"
                  value={invoiceIdValue}
                  onChange={(e) => setInvoiceIdValue(e.target.value)}
                />
              </div>
              {/* Optional E-Bill ID */}
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="Ebill" className="font-semibold text-black">
                  E-Bill Id (opt.):{" "}
                </label>
                <input
                  type="text"
                  className="border border-neutral-300 px-[20px] py-[10px] rounded-md"
                  value={eBillValue}
                  onChange={(e) => setEBillValue(e.target.value)}
                />
              </div>
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="date" className="font-semibold text-black">
                  Bill Date:
                </label>
                <input
                  type="date"
                  className="border border-neutral-300 max-w-fit px-[20px] py-[10px] rounded-md"
                  value={changeDateFormat(billDate)}
                  onChange={(e) => setBillDate(moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss"))}
                  // onChange={(e) => setBillDate(e.target.value)}
                  max={changeDateFormat(Date.now())}
                />
              </div>

              {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500">Zipcode</h3>
              <h3 className="font-semibold text-blue-500">{customer?.zipcode}</h3>
              </div> */}
              {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500">State</h3>
              <h3 className="font-semibold text-blue-500">{customer.state}</h3>
              </div>
              <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500">City</h3>
              <h3 className="font-semibold text-blue-500">{customer.city}</h3>
              </div> */}
              {/* <div className="flex text-base justify-between my-5">
              <h3 className="font-semibold text-gray-500 mr-2">Address</h3>
              <h3 className="font-semibold text-blue-500">{customer?.address}</h3>
              </div> */}
            </div>
          </div>
          <div>
            <div className="mt-[20px] overflow-x-scroll">
              <table cellPadding={5} cellSpacing={10} className="w-[150%]">
                <tr>
                  <th>Product Name</th>
                  <th>Batch Number</th>
                  <th>HSN/SAC</th>
                  {/* <th>Bar Code</th> */}
                  <th>Qty</th>
                  <th>Deal / Free</th>
                  <th>MRP</th>
                  <th>Rate</th>
                  <th>Selling Price(opt)</th>
                  <th>Tax %</th>
                  <th>Discount % </th>
                  {/* <th>Retail</th> */}
                  {/* <th>Total Qty</th> */}
                  <th>Barcode Number</th>
                  <th>Expiry Date</th>
                  <th>Amount</th>
                </tr>
                {items?.map((item, index) => (
                  <tr key={item?.productId || index} className="mb-4">
                    <td>
                      <Autocomplete
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                          }
                        }}
                        disablePortal
                        id="combo-box-demo"
                        value={findProductByProductID(item?.productId)}
                        options={products || []}
                        // getOptionLabel={(option) =>
                        //   option?.total_remaining_quantity
                        //     ? `${option?.product_name} - Qty: ${option?.total_remaining_quantity}`
                        //     : `${option?.product_name}`
                        // }
                        getOptionLabel={(option) => option?.product_name}
                        noOptionsText={
                          <button onClick={() => setShowModal(true)} className="w-full">
                            + add product
                          </button>
                        }
                        onChange={(event, value) => handleChangeItem(value, index)}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Products"
                            onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                          />
                        )}
                        aria-required
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        value={items[index]?.batch?.batch_number}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "batch_number");
                        }}
                        // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                        type="text"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        value={items[index]?.batch?.hsn_number}
                        type="text"
                        variant="outlined"
                        disabled
                      />
                    </td>
                    {/* <td>
                      <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          height: "20px",
                          paddingLeft: "10px",
                          },
                          }}
                          onChange={(e) => {
                            handleFieldChange(e.target.value, index, "bar_code");
                            }}
                            value={items[index]?.bar_code}
                            // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                            type="number"
                            variant="outlined"
                            />
                            </td> */}
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "remaining_quantity");
                        }}
                        value={items[index]?.batch?.remaining_quantity}
                        // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                        type="number"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "deal_quantity");
                        }}
                        value={items[index]?.batch?.deal_quantity}
                        defaultValue={0}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "mrp_price");
                        }}
                        value={items[index]?.batch?.mrp_price}
                        type="number"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "rate");
                        }}
                        value={items[index]?.batch?.rate}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][6] = el; // Assign the ref if both checks pass
                          }
                        }}
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "sales_price");
                        }}
                        value={items[index]?.batch?.sales_price}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][7] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "tax");
                        }}
                        value={items[index]?.batch?.tax}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][8] = el; // Assign the ref if both checks pass
                          }
                        }}
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "discount");
                        }}
                        value={items[index]?.batch?.discount}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <div className="flex border border-stone-400 rounded-md">
                        <TextField
                          id="outlined-basic"
                          ref={(el) => {
                            if (el && inputRefs.current[index]) {
                              inputRefs.current[index][9] = el; // Assign the ref if both checks pass
                            }
                          }}
                          inputProps={{
                            style: {
                              height: "18px",
                              paddingLeft: "10px",
                            },
                          }}
                          onChange={(e) => {
                            handleFieldChange(e.target.value, index, "bar_code_number");
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none", // Remove the border
                              },
                              "&:hover fieldset": {
                                border: "none", // Remove border on hover
                              },
                              "&.Mui-focused fieldset": {
                                border: "none", // Remove border when focused
                              },
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              boxShadow: "none",
                              outline: "none",
                            },
                          }}
                          value={items[index]?.batch?.bar_code_number}
                          type="text"
                          variant="outlined"
                        />
                        <button
                          className="bg-black h-full px-[1.5vmin] py-[1.5vmin]"
                          onClick={() => handleBarcodeButtonClick(index)}
                        >
                          <Maximize className="text-white" />
                        </button>
                      </div>
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][10] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "expiry_date");
                        }}
                        value={items[index]?.expiry_date}
                        type="date"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][11] = el; // Assign the ref if both checks pass
                          }
                        }}
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => handleCalculateAmount(index, e.target.value)}
                        value={calculatedAmountValue[index]?.calculated_amount || 0}
                        type="text"
                        variant="outlined"
                      />
                    </td>
                    <td
                      onClick={() => {
                        handleRemoveItem(index);
                      }}
                    >
                      <IconWrapper>
                        <Cross className="text-white z-10" />
                      </IconWrapper>
                    </td>
                  </tr>
                ))}
              </table>
              <button
                ref={addAnotherItemRef}
                className="text-black p-2 cursor-pointer pb-[20px]"
                onClick={() => {
                  setItems((prev) => [...prev, {}]);
                  setCalculatedAmountValue((prev) => [...prev, {}]);
                  // setDiscountTaxItems((prev) => [...prev, {}]);
                }}
              >
                + Add another item
              </button>
            </div>
            <div>
              <div className="w-full flex border-t-2 border-neutral-200">
                <div className="w-full">
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">SubTotal</h3>
                    <h3 className="font-semibold text-gray-500">{formData.sub_total || 0}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Discount</h3>
                    <h3 className="font-semibold text-gray-500">{formData.discount || 0}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                    <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(3).slice(0, -1) || 0}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                    <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(3).slice(0, -1) || 0}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">IGST @tax%</h3>
                    <h3 className="font-semibold text-gray-500">0.00</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Total</h3>
                    <h3 className="font-semibold text-gray-500">{formData?.grand_total || 0}</h3>
                  </div>
                  {/* <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Profit Margin</h3>
                    <h3 className="font-semibold text-gray-500">{profitMargin}</h3>
                  </div> */}
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Payment Options</h3>
                    <h3 className="font-semibold text-gray-500">
                      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white sm:flex">
                        {paymentOptions.map((option, index) => (
                          <li key={option.value} className="w-full">
                            <div className="flex items-center ml-2">
                              <input
                                id="horizontal-list-radio-id"
                                type="radio"
                                value={option.value}
                                onChange={(e) => {
                                  //Check if payment option is debit and if it is debit add pay_later payment_type
                                  let payment_type = "paid";
                                  if (e.target.value === "debit") {
                                    payment_type = "pay_later";
                                  }
                                  setFormData({ ...formData, payment_option: e.target.value, payment_type });
                                }}
                                checked={formData.payment_option === option.value}
                                name="list-radio"
                                className="w-4 h-4 text-blue-600"
                              />
                              <label for="horizontal-list-radio-id" className="w-full ms-2 px-1 text-base font-medium">
                                {option.label}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Description</h3>
                    <h3 className="font-semibold text-gray-500">
                      <textarea
                        className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
                        placeholder=""
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        value={formData.description}
                      ></textarea>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="w-full flex items-center justify-center my-4">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={disableSubmitButton}
                  ref={submitButton}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
                >
                  Create Purchase Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePurchaseInvoice;
