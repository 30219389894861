import React, { useState, useEffect } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch } from "react-redux";
import { updateExpense } from "../../../reducers/Slices/expenseSlice";
import toast from "react-hot-toast";

function EditExpense({ expense }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    id: expense?.id || 0,
    date: expense?.date || "",
    expense_name: expense?.expense_name || "",
    cost: expense?.cost || 0,
    paid_to: expense?.paid_to || "",
    category: expense?.category || "",
    note: expense?.note || "",
    business_profile: expense?.business_profile || 0,
  });
  const [showModal, setShowModal] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // Reset expense values on expense change
  useEffect(() => {
    setFormData({
      id: expense?.id,
      date: expense?.date,
      expense_name: expense?.expense_name,
      cost: expense?.cost,
      paid_to: expense?.paid_to,
      category: expense?.category,
      note: expense?.note,
      business_profile: expense?.business_profile,
    });
  }, [expense]);

  const handleSubmit = () => {
    handleEditExpense();
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleEditExpense = () => {
    setDisableSubmitButton(true);
    dispatch(updateExpense(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Expense editing failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleEditExpenseClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                    Date*
                  </label>
                  <input
                    type="date"
                    id="date"
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    value={formData.date}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="expense_name" className="block mb-2 text-sm font-medium text-gray-900">
                    Expense Name*
                  </label>
                  <input
                    type="text"
                    id="expense_name"
                    onChange={(e) => setFormData({ ...formData, expense_name: e.target.value })}
                    value={formData.expense_name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter expense name"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="cost" className="block mb-2 text-sm font-medium text-gray-900">
                    Cost*
                  </label>
                  <input
                    type="number"
                    id="cost"
                    value={formData.cost}
                    onChange={(e) => setFormData({ ...formData, cost: parseFloat(e.target.value) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter cost"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="paid_to" className="block mb-2 text-sm font-medium text-gray-900">
                    Paid To*
                  </label>
                  <input
                    type="text"
                    id="paid_to"
                    value={formData.paid_to}
                    onChange={(e) => setFormData({ ...formData, paid_to: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter payee"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900">
                    Category*
                  </label>
                  <input
                    id="category"
                    value={formData.category}
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  ></input>
                </div>
                <div className="mb-5">
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                    Note
                  </label>
                  <textarea
                    id="note"
                    rows="4"
                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.note}
                    placeholder="Enter note"
                  ></textarea>
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Expense
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        className="py-4 border-2 border-black h-[44px] w-full flex items-center justify-center rounded-full"
        onClick={handleEditExpenseClick}
      >
        Edit Expense
      </button>
    </>
  );
}

export default EditExpense;
