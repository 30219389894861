import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

//online store APIs
import {
  fetchStores,
  fetchMoreStores,
  createNewStore,
  updateStore,
  deleteStore,
  setSelectedStoreId,
} from "../../reducers/Slices/onlineStoreSlice";

// UI components
import IconWrapper from "../MicroComponents/IconWrapper";
import ButtonWrapper from "../MicroComponents/ButtonWrapper";
import { Search, Filter, Link, Settings } from "react-feather";

//Product APIs
import {
  fetchMoreProducts,
  fetchProducts,
  searchMoreProducts,
  searchProducts,
} from "../../reducers/Slices/productSlice";
import toast from "react-hot-toast";

const OnlineStore = () => {
  const dispatch = useDispatch();

  const stores = useSelector((state) => state.onlineStore.stores);
  const storesNext = useSelector((state) => state.onlineStore.stores.next);
  const selectedStoreId = useSelector((state) => state.onlineStore.selectedStoreId);

  const [hasMoreStores, setHasMoreStores] = useState(() => (storesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (storesNext) {
      setHasMoreStores(true);
    } else {
      setHasMoreStores(false);
    }
  }, [storesNext]);

  //fetch more expenses on scroll if available
  const fetchNextStores = () => {
    if (storesNext) {
      dispatch(fetchMoreStores(storesNext));
    }
  };

  //fetch stores on first render
  useEffect(() => {
    dispatch(fetchStores());
  }, [dispatch]);

  //Handle Search Store - pHaniver - due because no API

  //Fake Store Data
  // const fakeStoreData = [
  //   { store_name: "Store 1", store_link: "https:www.profitStore.com/1" },
  //   { store_name: "Store 2", store_link: "https:www.profitStore.com/2" },
  //   { store_name: "Store 3", store_link: "https:www.profitStore.com/3" },
  // ];

  const [showMenu, setShowMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");

  //Base string for API
  const [stringQuery, setStringQuery] = useState("/");

  const [selectedStore, setSelectedStore] = useState({});

  //side effect for on change of selected Store change SelectedProductIds

  //Side Effect to select First store on first render
  useEffect(() => {
    if (selectedStoreId) {
      setSelectedStore(stores.data.find((stores) => stores.id === selectedStoreId));
    } else {
      setSelectedStore(stores.data[0]);
    }
  }, [stores, selectedStoreId]);

  //Persistent Selected Store
  useEffect(() => {
    if (selectedStore) {
      dispatch(setSelectedStoreId(selectedStore.id));
    }
  }, [selectedStore, dispatch]);

  //Handle Filter Menu Show Operations
  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  //Handle Search Operations
  //Debounced SearchTerm
  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  //Run for fitler and search query
  useEffect(() => {
    setStringQuery(() => {
      let initialString = "/";

      //Guard Clause
      if (searchTerm.length === 0 && filter.length === 0) {
        return "/";
      }

      //Setting search term
      if (searchTerm.length > 0) {
        initialString += `?search=${searchTerm}`;
      }

      //Setting filter string
      if (searchTerm.length === 0 && filter.length > 0) {
        initialString += `?${filter}`;
      } else if (filter.length > 0) {
        initialString += `&${filter}`;
      }

      return initialString;
    });
  }, [filter, searchTerm]);

  //Handle Add Store
  const handleCreateNewStore = () => {
    dispatch(createNewStore({ product_ids: [] }))
      .then((action) => {
        if (action.payload) {
        } else {
          toast.error("sorry, new store cannot be created right now!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDeleteStore = (id) => {
    if (!window.confirm("do you want to delete this store")) {
      return;
    }

    dispatch(deleteStore({ id }))
      .then((action) => {
        if (action.payload) {
        } else {
          toast.error("sorry, store cannot be deleted");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Handle Link Button Click - on click copy link to clipboard
  const handleLinkClick = (link) => {
    navigator.clipboard.writeText(`https://store.theprofit.ai/${link}`);

    //Alert the copied text
    toast.success("Copied Store Link: https://store.theprofit.ai/" + link);
  };

  //Handle Product Selection and removal
  const handleProductSelection = (product, operation) => {
    if (!selectedStore) {
      toast.error("First Select A store!");
      return;
    }

    const alreadySelectedProductIds = selectedStore?.products?.map((product) => product.id);
    let selectedProductIds = [];
    // let messageSuccess = "";
    let messageFailure = "";

    if (operation === "product_addition") {
      selectedProductIds = [...alreadySelectedProductIds, product.id];
      // messageSuccess = "product added to store";
      messageFailure = "product addition to store failed!";
    } else {
      selectedProductIds = alreadySelectedProductIds.filter((id) => id !== product.id);
      // messageSuccess = "product removed from store";
      messageFailure = "product removal from store failed!";
    }

    dispatch(updateStore({ id: selectedStore.id, product_ids: selectedProductIds }))
      .then((action) => {
        if (action.payload) {
        } else {
          toast.error(messageFailure);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Product Operations

  //Products data and next funtion to fetch more
  const [showProducts, setShowProducts] = useState([]);
  const [showProductsNext, setShowProductsNext] = useState(null);

  const products = useSelector((state) => state.product.products.data);
  const productsNext = useSelector((state) => state.product.products.next);

  //Searched products and next function to fetch more
  const searchedProducts = useSelector((state) => state.product.searchedProducts.data);
  const searchedProductsNext = useSelector((state) => state.product.searchedProducts.next);

  const [hasMoreProducts, setHasMoreProducts] = useState(() => (showProductsNext ? true : false));

  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [showProductMenu, setShowProductMenu] = useState(false);

  const [processedProductsList, setProcessedProductsList] = useState([]);

  const [productFilter, setProductFilter] = useState("all");

  //side effect to set showProducts
  useEffect(() => {
    if (productSearchTerm && searchedProducts?.length > 0) {
      const SearchList = processedProductsList.filter((product) => {
        if (searchedProducts.find((element) => element.id === product.id)) {
          return true;
        } else {
          return false;
        }
      });

      const filteredList = SearchList.filter((product) => {
        if (productFilter === "all") {
          return true;
        } else if (productFilter === "selected_only") {
          return product.isSelected ? true : false;
        } else if (productFilter === "non_selected_only") {
          return !product.isSelected ? true : false;
        } else {
          return false;
        }
      });

      setShowProducts(filteredList);
      setShowProductsNext(searchedProductsNext);
    } else if (products.length > 0) {
      //Filter Products based on filter
      const filteredList = processedProductsList.filter((product) => {
        if (productFilter === "all") {
          return true;
        } else if (productFilter === "selected_only") {
          return product.isSelected ? true : false;
        } else if (productFilter === "non_selected_only") {
          return !product.isSelected ? true : false;
        } else {
          return false;
        }
      });

      setShowProducts(filteredList);
      setShowProductsNext(productsNext);
    }
  }, [
    searchedProducts,
    productSearchTerm,
    productsNext,
    searchedProductsNext,
    products,
    processedProductsList,
    productFilter,
  ]);

  //side effect Based on products fetched - add property - isSelected for selected product list
  useEffect(() => {
    const processedProducts = products.map((product) => {
      return { ...product, isSelected: !!selectedStore?.products?.find((element) => element.id === product.id) };
    });

    //Product only processed orders i.e omit service based products
    const productOnlyProcessedProducts = processedProducts?.filter((item) => item.is_service === "N");

    setProcessedProductsList(productOnlyProcessedProducts);
  }, [products, selectedStore?.products]);

  //Use effect to set hasMoreProducts based on value of next for react infinity scroll
  useEffect(() => {
    if (showProductsNext) {
      setHasMoreProducts(true);
    } else {
      setHasMoreProducts(false);
    }
  }, [showProductsNext]);

  //Fetching all products on first render
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  //side effect to get all products fetched due to selected Product List dependency.
  // useEffect(() => {
  //   if (showProductsNext) {
  //     dispatch(fetchMoreProducts(showProductsNext));
  //   }
  // }, [showProductsNext, productSearchTerm, dispatch]);

  //fetch more products on scroll if availabe - currently useless in context of online store because
  //all products are being fetched but here if needed in future changes.
  const fetchNextProducts = () => {
    if (!productSearchTerm) {
      if (showProductsNext) {
        dispatch(fetchMoreProducts(showProductsNext));
      }
    } else {
      if (showProductsNext) {
        dispatch(searchMoreProducts(showProductsNext));
      }
    }
  };

  //Handle Filter Menu Show Operations
  const handleShowProductMenu = (value) => {
    showProductMenu !== value ? setShowProductMenu(value) : setShowProductMenu(null);
  };

  //Debounced Product SearchTerm
  const debouncedSetProductSearchTerm = debounce((term) => {
    setProductSearchTerm(term);
  }, 300);

  //Side effect to search products
  useEffect(() => {
    dispatch(searchProducts(productSearchTerm));
  }, [productSearchTerm, dispatch]);

  return (
    <div className="h-full flex ">
      {/* Store List and Search */}
      <div className="h-full border-r-2 border-neutral-200 w-[30vw]">
        {/* Search Store, Filter & Add Store Button */}
        {/* <div className="border-b-2 border-neutral-200 flex p-[15px] gap-[5px]"> */}
        {/* Search Bar and Search Button */}
        {/* <div className="w-full flex">
            <input
              type="text"
              className="border-2 rounded-full h-[44px] w-full p-[10px]"
              placeholder="Search Vendor or Customer..."
              onChange={(e) => debouncedSetSearchTerm(e.target.value)}
            />
            <button className="h-[44px] -ml-[42px]">
              <IconWrapper>
                <Search className="text-white z-10" />
              </IconWrapper>
            </button>
          </div> */}

        {/* Filter Button and Functionality */}
        {/* <div className="relative">
            <button className="h-[44px]" onClick={() => handleShowMenu("settings")}>
              <IconWrapper>
                <Filter className="text-white z-10" />
              </IconWrapper>
            </button>
            <div
              id="dropdownInformation"
              onMouseLeave={() => handleShowMenu("settings")}
              className={`${
                showMenu !== "settings" ? "hidden" : ""
              } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
            >
              <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                <li>
                  <button
                    onClick={() => toast.success("store filter clicked")}
                    className="text-lg py-2 w-full border-b-2 border-neutral-200"
                  >
                    Store Filter 1
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => toast.success("store filter clicked")}
                    className="text-lg py-2 w-full border-b-2 border-neutral-200"
                  >
                    Store Filter 2
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => toast.success("store filter clicked")}
                    className="text-lg pt-2 w-full border-neutral-200"
                  >
                    Store Filter 3
                  </button>
                </li>
              </ul>
            </div>
          </div> */}

        {/* Add Store Button */}
        {/* <div className="">
            <ButtonWrapper eventCallback={handleCreateNewStore}>Add Store</ButtonWrapper>
          </div>
        </div> */}

        {/* Store List */}
        <div className="h-full max-h-[83vh]">
          <div className="flex border-b-2 py-[20px] px-[22px] items-center justify-between">
            <h3 className="text-lg  text-neutral-500 font-semibold">Stores</h3>
            <ButtonWrapper eventCallback={handleCreateNewStore}>Add Store</ButtonWrapper>
          </div>
          <h3 className="text-lg py-[20px] text-center  text-neutral-500 font-medium">Stores list</h3>
          <InfiniteScroll
            dataLength={stores.data.length}
            next={fetchNextStores}
            hasMore={hasMoreStores}
            height={"68vh"}
            loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
            endMessage={
              <p style={{ textAlign: "center", paddingTop: "10px" }}>
                <b>Stores List End</b>
              </p>
            }
          >
            <div className="px-[15px] flex flex-col gap-[15px]">
              {stores.data.length > 0 &&
                stores.data.map((store) => (
                  <div
                    className="relative flex justify-between border-2 border-neutral-200 px-[20px] py-[10px] rounded-full"
                    key={store?.unique_link_code}
                    onClick={() => setSelectedStore(store)}
                  >
                    <span>store {store?.id}</span>
                    <button
                      className="right-[0] top-[50%] -translate-y-[50%] absolute bg-black h-[5vh] w-[5vh] flex justify-center items-center rounded-full"
                      title={store.unique_link_code}
                      onClick={() => handleLinkClick(store.unique_link_code)}
                    >
                      <Link className="z-10 text-white" />
                    </button>
                  </div>
                ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>

      {/* Store info and product container */}
      <div className="h-full w-[70vw]">
        {/* Store Details */}
        <div className="border-b-2 border-neutral-200 h-[76px] flex justify-between px-[20px] items-center">
          <h3 className="text-3xl text-neutral-500">Store {selectedStore?.id}</h3>
          <div className="flex gap-[10px]">
            {/* <button
              className="flex gap-[10px] items-center text-lg px-[20px] py-[10px] border-2 border-black rounded-full hover:text-white hover:bg-black"
              onClick={() => toast.success("store settings was clicked")}
            >
              Store Settings
              <Settings className="h-[20px] w-[20px]" />
            </button> */}
            <button
              className="px-[20px] py-[10px] border-2 border-red-500 text-red-500 rounded-full text-lg"
              onClick={() => handleDeleteStore(selectedStore?.id)}
            >
              Delete Store
            </button>
          </div>
        </div>
        {/* Product Container */}
        <div className="h-[83vh] flex">
          {/* Product List and Product Search */}
          <div className="w-1/2 border-r-2 border-neutral-200">
            {/* productSearch and Product Filter */}
            <div className="flex p-[10px] gap-[10px] border-b-2 border-neutral-200">
              {/* Product Search Bar and Product Search Button */}
              <div className="w-full flex">
                <input
                  type="text"
                  className="border-2 rounded-full h-[44px] w-full p-[10px]"
                  placeholder="Search Products..."
                  onChange={(e) => debouncedSetProductSearchTerm(e.target.value)}
                />
                <button className="h-[44px] -ml-[42px]">
                  <IconWrapper>
                    <Search className="text-white z-10" />
                  </IconWrapper>
                </button>
              </div>
              {/* Filter Button and Functionality */}
              <div className="relative">
                <button className="h-[44px]" onClick={() => handleShowProductMenu("settings")}>
                  <IconWrapper>
                    <Filter className="text-white z-10" />
                  </IconWrapper>
                </button>
                <div
                  id="dropdownInformation"
                  onMouseLeave={() => handleShowProductMenu("settings")}
                  className={`${
                    showProductMenu !== "settings" ? "hidden" : ""
                  } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                >
                  <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                    <li>
                      <button
                        onClick={() => setProductFilter("all")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        All
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setProductFilter("selected_only")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        Selected Products Only
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setProductFilter("non_selected_only")}
                        className="text-lg pt-2 w-full border-neutral-200"
                      >
                        Non Selected Products Only
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Product List  */}
            <div>
              <h3 className="text-lg text-center text-neutral-500 my-[20px]">Products List</h3>
              <InfiniteScroll
                dataLength={showProducts.length}
                next={fetchNextProducts}
                hasMore={hasMoreProducts}
                height={"68vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Products List End</b>
                  </p>
                }
              >
                {showProducts.map((item, index) => (
                  <div
                    key={index}
                    className="border-neutral-200 border-2 hover:border-black focus:border-black p-2 rounded-lg bg-white cursor-pointer shadow mb-2 mx-[10px]"
                  >
                    <div className="relative flex text-sm items-center justify-between gap-2 pb-2 pl-2 ">
                      <div>
                        {/* pHaniver - Changed - {item.product_name} to hardcoded value */}
                        <h3 className="text-base font-semibold text-black">{item.product_name}</h3>
                        {/* pHaniver - Changed - h3 -> span, {item.product_type.category_name} -> Electronics hardcoded value*/}
                        <h3 className="text-black">
                          Brand: <span className="text-gray-500">{item.brand}</span>
                        </h3>
                        <h3 className="text-black">
                          Total Quantity: <span className="text-gray-500">{item.total_remaining_quantity || 0}</span>
                        </h3>
                      </div>
                      {item.isSelected ? (
                        <span className="font-bold px-[10px] py-[10px]">Selected</span>
                      ) : (
                        <button
                          className="px-[20px] py-[10px] bg-black text-white rounded-full"
                          onClick={() => {
                            handleProductSelection(item, "product_addition");
                          }}
                        >
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          {/* Selected Store Products List */}
          <div className="w-1/2">
            <div className="h-[66px] border-b-2 border-neutral-200 flex items-center justify-center">
              <h3>
                Total Product Selected: <span className="text-neutral-500">{selectedStore?.products?.length || 0}</span>
              </h3>
            </div>
            <h3 className="text-lg text-center text-neutral-500 my-[20px]">Selected Products List</h3>
            <div className="max-h-[67vh] overflow-y-scroll">
              {selectedStore?.products?.length > 0 &&
                selectedStore?.products?.map((item, index) => (
                  <div
                    key={index}
                    className="border-neutral-200 border-2 hover:border-black focus:border-black p-2 rounded-lg bg-white cursor-pointer shadow mb-2 mx-[10px]"
                  >
                    <div className="relative flex text-sm items-center justify-between gap-2 pb-2 pl-2 ">
                      <div>
                        {/* pHaniver - Changed - {item.product_name} to hardcoded value */}
                        <h3 className="text-base font-semibold text-black">{item?.product_name}</h3>
                        {/* pHaniver - Changed - h3 -> span, {item.product_type.category_name} -> Electronics hardcoded value*/}
                        <h3 className="text-black">
                          Brand: <span className="text-gray-500">{item?.brand}</span>
                        </h3>
                        <h3 className="text-black">
                          Total Quantity: <span className="text-gray-500">{item?.remaining_quantity}</span>
                        </h3>
                      </div>
                      <div>
                        <button
                          className="px-[20px] py-[10px] text-red-500 border-2 border-red-500 rounded-full"
                          onClick={() => handleProductSelection(item, "product_removal")}
                        >
                          Remove Product
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineStore;
