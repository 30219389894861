import React, { useState, useEffect } from "react";

const DurationInput = ({ totalSeconds, setTotalSeconds }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  function updateTotalSeconds(fieldName, value) {
    // Convert days, hours, and minutes to totalSeconds
    let daysInSeconds = (parseInt(days) || 0) * 86400;
    let hoursInSeconds = (parseInt(hours) || 0) * 3600;
    let minutesInSeconds = (parseInt(minutes) || 0) * 60;

    switch (fieldName) {
      case "day":
        setDays(() => value);
        daysInSeconds = (parseInt(value) || 0) * 86400;
        break;
      case "hour":
        setHours(() => value);
        hoursInSeconds = (parseInt(value) || 0) * 3600;
        break;
      case "min":
        setMinutes(() => value);
        minutesInSeconds = (parseInt(value) || 0) * 60;
        break;
      default:
        break;
    }

    const total = daysInSeconds + hoursInSeconds + minutesInSeconds; // 86400 seconds in a day

    setTotalSeconds(total);
  }

  useEffect(() => {
    // Convert totalSeconds to days, hours, and minutes
    const totalDays = Math.floor(totalSeconds / 86400); // 86400 seconds in a day
    const remainingSecondsAfterDays = totalSeconds % 86400;
    const totalHours = Math.floor(remainingSecondsAfterDays / 3600);
    const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;
    const totalMinutes = Math.floor(remainingSecondsAfterHours / 60);

    // Update only if values are different
    if (parseInt(days) !== totalDays || parseInt(hours) !== totalHours || parseInt(minutes) !== totalMinutes) {
      setDays(totalDays);
      setHours(totalHours);
      setMinutes(totalMinutes);
    }
  }, [totalSeconds]);

  return (
    <div className="flex flex-col gap-[20px]">
      <div className="flex flex-col gap-[10px]">
        <label htmlFor="days_input" className="text-neutral-500">
          Duration in Days:
        </label>
        <input
          type="number"
          id="days_input"
          className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
          value={days}
          onChange={(e) => {
            updateTotalSeconds("day", e.target.value);
          }}
          placeholder="Enter days"
        />
      </div>
      <div className="flex flex-col gap-[10px]">
        <label htmlFor="hours_input" className="text-neutral-500">
          Service Duration in Hours:
        </label>
        <input
          id="hours_input"
          type="number"
          className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
          value={hours}
          onChange={(e) => {
            updateTotalSeconds("hour", e.target.value);
          }}
          placeholder="Enter hours"
        />
      </div>
      <div className="flex flex-col gap-[10px]">
        <label htmlFor="minutes_input" className="text-neutral-500">
          Service Duration in Minutes:
        </label>
        <input
          type="number"
          id="minutes_input"
          className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
          value={minutes}
          onChange={(e) => {
            updateTotalSeconds("min", e.target.value);
          }}
          placeholder="Enter minutes"
        />
      </div>
    </div>
  );
};

export default DurationInput;
