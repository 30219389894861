import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSubscriptionPlans, activateFreeTrial, setBusinessDetails } from "../../reducers/Slices/userSlice";
import { isSubscriptionExpired } from "../../utils/checkSubscriptionExpiry";
import toast from "react-hot-toast";
import { http } from "../../service/http";
import { checkTokenExists } from "../../utils/session";
import { requestForToken } from "../../config/firebase";
import { subscribeFirebaseNotification, unsubscribeFirebaseNotification } from "../../reducers/Slices/appSlice";

function PlanSelection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [hadAPlan, setHadAPlan] = useState(false);
  const [planExpired, setPlanExpired] = useState(false);

  //Only use case to avoid called firebase device token twice during react development.
  const timeoutRef = useRef(null);

  // const subscriptionPlans = useSelector((state) => state.user.subscriptionPlans);
  const subscriptionExpiry = useSelector((state) => state.user.subscription_expiry);
  let subscriptionPlans = useSelector((state) => state.user.subscriptionPlans);

  // side effect to check if there is subscription_expiry
  // case 1 - there is no subscription expiry in business details then offer free plan
  // case 2 - there is a subscrption expiry in business details meaning currently there is plan running either trial or paid plam
  // case 3 - there is a subscription expiry and it is expired meaning plan has expired then offer plans
  useEffect(() => {
    if (typeof isSubscriptionExpired(subscriptionExpiry) === "boolean") {
      setHadAPlan(true);
    }

    if (isSubscriptionExpired(subscriptionExpiry)) {
      setPlanExpired(true);
    }
  }, [subscriptionExpiry]);

  useEffect(() => {
    dispatch(fetchSubscriptionPlans());
  }, [dispatch]);

  useEffect(() => {
    if (!checkTokenExists() || localStorage.getItem("business_profile") === "false") {
      navigate("/login");
    } else {
      handleProfileDetails();
    }
    // eslint-disable-next-line
  }, []);

  const handleProfileDetails = () => {
    http
      .get("user_profile/backend/business-all/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(setBusinessDetails({ ...res.data.data, phone: res.data?.user_profile_phone }));
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Send token to backend for notifications triggered from backend
  const subscribeTokenGeneratedByFirebase = (token) => {
    dispatch(subscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.setItem("device_token", token); // Save the token in localStorage\
          toast.success("Notifications Enabled!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Unsubscribe from notifications if notification permission is disabled.
  const unsubscribeTokenGeneratedByFirebase = (token) => {
    dispatch(unsubscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.removeItem("device_token", token); // Save the token in localStorage
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Firebase token config - get token on push notification
  useEffect(() => {
    // Clear any existing timeout to debounce the function call
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const getToken = async () => {
      // Check if the token is already stored in localStorage
      const existingToken = localStorage.getItem("device_token");

      // Request permission to send notifications
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if (existingToken) {
          return; // If token exists, no need to generate a new one
        }
        // Call the function to generate a new token
        const token = await requestForToken();
        if (token) {
          subscribeTokenGeneratedByFirebase(token);
        }
      } else {
        console.error("Notification permission denied.");
        //If notification permission is disabled then remove token.
        if (existingToken) {
          unsubscribeTokenGeneratedByFirebase(existingToken);
        }
      }
    };

    timeoutRef.current = setTimeout(() => {
      // Call your function here after the debounce period
      getToken();
    }, 1000); // 1 second

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  // const subscriptionPlans = [
  //   {
  //     id: 1,
  //     name: "monthly",
  //     price_without_gst: "600.00",
  //     price_with_gst: "708.00",
  //     gst_percent: "18.00",
  //     annual_cost: "7200.00",
  //     annual_cost_with_gst: "8496.00",
  //     discount: "0.00",
  //     savings_per_year: "0.00",
  //     duration_days: 30,
  //   },
  //   {
  //     id: 2,
  //     name: "quarterly",
  //     price_without_gst: "1500.00",
  //     price_with_gst: "1770.00",
  //     gst_percent: "18.00",
  //     annual_cost: "6000.00",
  //     annual_cost_with_gst: "7080.00",
  //     discount: "16.67",
  //     savings_per_year: "1200.00",
  //     duration_days: 90,
  //   },
  //   {
  //     id: 3,
  //     name: "annual",
  //     price_without_gst: "5500.00",
  //     price_with_gst: "6490.00",
  //     gst_percent: "18.00",
  //     annual_cost: "5500.00",
  //     annual_cost_with_gst: "6490.00",
  //     discount: "23.61",
  //     savings_per_year: "1700.00",
  //     duration_days: 365,
  //   },
  //   {
  //     id: 4,
  //     name: "5-year",
  //     price_without_gst: "23375.00",
  //     price_with_gst: "27583.00",
  //     gst_percent: "18.00",
  //     annual_cost: "4675.00",
  //     annual_cost_with_gst: "5517.00",
  //     discount: "35.07",
  //     savings_per_year: "2525.00",
  //     duration_days: 1825,
  //   },
  // ];

  //Plan labels
  const planLabels = {
    monthly: "Monthly Plan",
    quarterly: "Quarterly Plan",
    annual: "Annual Plan",
    "5-year": "5 Year Plan",
  };

  //Plan Meta line
  const planMetaLine = {
    monthly: "Ideal for short-term needs",
    quarterly: "Great for those who need more flexibility.",
    annual: "Best value for long-term commitment.",
    "5-year": "Ideal for long-term savings.",
  };

  //activate free trial functionality
  const handleActivateFreeTrial = () => {
    dispatch(activateFreeTrial())
      .then((action) => {
        if (action.payload) {
          navigate("/");
        } else {
          toast.error("Sorry, Cannot Activate Free Trail!");
        }
      })
      .catch((err) => {
        toast.error(`Error Occured: " ${err}`);
      });
  };

  const handleBuyPlan = (plan) => {
    if (!plan || !plan.id) return;

    navigate(`/checkout_page/${plan?.id}`);
  };

  return (
    <section id="pricing" className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-16">
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Select A Plan</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
            {hadAPlan === false && "Choose a plan that suits you best. Each plan offers unique features and savings."}
            {hadAPlan === true
              ? planExpired
                ? "It looks like your plan has expired! to continue using our services please consider purchasing a plan."
                : "You already have an active plan! Any purchases you make right now is going to be added to queue and will be effective after this plan is expired."
              : ""}
          </p>
        </div>
        <div className="flex flex-wrap -m-5">
          {subscriptionPlans?.map((plan) => (
            <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
              <div
                className={`p-6 rounded-lg border-2 ${
                  plan?.name !== "annual" ? "border-gray-300" : "border-indigo-500"
                } flex flex-col relative overflow-hidden`}
              >
                {plan?.name === "annual" && (
                  <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                    POPULAR
                  </span>
                )}
                <h2 className="text-sm tracking-widest title-font mb-1 font-medium">{planLabels[plan?.name]}</h2>
                <h1 className="text-5xl text-gray-900 mb-4 leading-none">₹{plan?.price_without_gst}</h1>
                <span className="text-xs text-gray-900 pb-4 border-b border-gray-200">*Prices without Tax</span>
                {/* <p className="text-gray-600 mb-2 flex justify-between">
                <span>With GST :</span>
                <span> ₹708</span>
              </p> */}
                <p className="text-gray-600 mt-2 mb-2 flex justify-between">
                  <span>Annual Cost :</span>
                  <span> ₹{plan?.annual_cost}</span>
                </p>
                {/* <p className="text-gray-600 mb-2 flex justify-between">
                <span>With GST Annual Cost :</span>
                <span>₹8,496</span>
              </p> */}
                <p className="text-gray-600 mb-6 flex justify-between">
                  <span>Discount From Monthly :</span>
                  <span className={`${plan?.name !== "monthly" ? "text-green-500 text-lg" : ""}`}>
                    {plan?.discount}%
                  </span>
                </p>
                <p className="text-gray-600 mb-6 flex justify-between">
                  <span>Savings per Year :</span>
                  <span className={`${plan?.name !== "monthly" ? "text-green-500 text-lg" : ""}`}>
                    ₹{plan?.savings_per_year}
                  </span>
                </p>
                <button
                  className={`${
                    selectedPlan?.name === plan?.name ? "text-white bg-black" : "border border-neutral-500 text-black"
                  } w-full py-[10px] px-[20px] rounded-sm`}
                  onClick={() => setSelectedPlan(plan)}
                >
                  {selectedPlan?.name === plan?.name ? "Selected" : "Select Plan"}
                </button>
                <p className="text-xs text-gray-500 mt-3">{planMetaLine[plan?.name]}</p>
              </div>
            </div>
          ))}
        </div>
        {/* Free Trial Button */}
        <div className="flex justify-center items-center mt-20 gap-[20px]">
          {selectedPlan && (
            <>
              <button
                className="bg-black rounded-full py-[10px] px-[20px] text-white"
                onClick={() => handleBuyPlan(selectedPlan)}
              >{`Buy ${planLabels[selectedPlan?.name]}`}</button>
              {hadAPlan === false && <span>Or</span>}
            </>
          )}
          {hadAPlan === false && (
            <button
              className="bg-black rounded-full py-[10px] px-[20px] text-white"
              onClick={() => {
                handleActivateFreeTrial();
              }}
            >
              Start 30 Days Free Trial
            </button>
          )}
        </div>
        <div className="text-gray-500 text-sm mt-10 text-center">
          <p>
            *By purchasing any plan right now, your subscription will be added to the queue. The billing period will
            commence after your free trial period has ended.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PlanSelection;
