import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import OnlineStore from "../../components/OnlineStore";

//Fetch all generated links
export const fetchStores = createAsyncThunk("onlineStore/fetchStores", async (data, extra) => {
  const res = await http.get(`/online_store/inventory-link-generate/${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more stores
export const fetchMoreStores = createAsyncThunk("onlineStore/fetchMoreStores", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//generate new link to create new store
export const createNewStore = createAsyncThunk("onlineStore/createNewStore", async (data, extra) => {
  const res = await http.post("/online_store/inventory-link-generate/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//update generated link
export const updateStore = createAsyncThunk("onlineStore/updateStore", async (data, extra) => {
  const res = await http.put(
    `/online_store/inventory-link-generate/${data.id}/`,
    { product_ids: data.product_ids },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//delete generatedLink
export const deleteStore = createAsyncThunk("onlineStore/deleteStore", async (data, extra) => {
  const res = await http.delete(`/online_store/inventory-link-generate/${data.id}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.status === 204) {
    return data.id;
  }
});

export const onlineStoreSlice = createSlice({
  name: "onlineStore",
  initialState: {
    stores: { data: [], next: "" },
    selectedStoreId: null,
  },
  reducers: {
    setSelectedStoreId(state, action) {
      state.selectedStoreId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStores.fulfilled, (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          data: action.payload.data,
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreStores.fulfilled, (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          data: [...state.stores.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(createNewStore.fulfilled, (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          data: [...state.stores.data, action.payload.data],
        },
      };
    });
    builder.addCase(updateStore.fulfilled, (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          data: state.stores.data.map((item) => {
            return item.id === action.payload.id ? action.payload : item;
          }),
        },
      };
    });
    builder.addCase(deleteStore.fulfilled, (state, action) => {
      return {
        ...state,
        stores: {
          ...state.stores,
          data: state.stores.data.filter((item) => item.id !== action.payload),
        },
      };
    });
  },
});

export const { setSelectedStoreId } = onlineStoreSlice.actions;
export default onlineStoreSlice.reducer;
