import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateVendor } from "../../../reducers/Slices/ledgerSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { searchZipcode } from "../../../reducers/Slices/userSlice";

//UI component Import
import { Edit2 } from "react-feather";
import toast from "react-hot-toast";

function EditVendor({ vendor }) {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [formData, setFormData] = useState(() => {
    return {
      id: vendor.id,
      vendor_name: vendor.name || "",
      address: vendor.address || "",
      phone_number: vendor.phone_number || "",
      zipcode: vendor.zipcode || "",
      gst_number: vendor.gst_number,
      //   state: vendor.state || "",
      //   city: vendor.city || "",
      email: vendor.email || "",
      favorite: vendor.favorite || false,
    };
  });
  const [showModal, setShowModal] = useState(false);

  const handleSearchZipcode = () => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code: action.payload.state_code,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateVendor();
    // setShowModal(!showModal);
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleUpdateVendor = () => {
    setDisableSubmitButton(true);
    dispatch(updateVendor(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Sorry, Vendor cannot be Edited!");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4  w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto" onSubmit={handleSubmit}>
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="Vendor_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Full Vendor Name*
                  </label>
                  <input
                    type="text"
                    id="vendor_name"
                    onChange={(e) => setFormData({ ...formData, vendor_name: e.target.value })}
                    value={formData.vendor_name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="name@profitai.com"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Mobile Number*
                  </label>
                  <input
                    type="number"
                    id="phone_number"
                    value={formData.phone_number}
                    onChange={(e) => setFormData({ ...formData, phone_number: e.target.value.slice(0, 10) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    min={0}
                    max={9999999999}
                    placeholder="Enter mobile number"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Zipcode*
                  </label>
                  <div className="relative z-0 flex gap-[10px] mb-5 group">
                    <input
                      type="zipcode"
                      onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                      id="zipcode"
                      value={formData.zipcode}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter zipcode"
                      required
                    />
                    <button
                      type="button"
                      onClick={handleSearchZipcode}
                      className="bg-black text-white px-[20px] py-[10px] rounded-lg"
                    >
                      Find
                    </button>
                  </div>
                </div>
                {/* <div className="flex mb-5 space-x-4">
              <div className="w-1/2 p-1">
                {" "}
                <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 ">
                  State
                </label>
                <input
                  type="state"
                  id="state"
                  value={formData.state}
                  onChange={(e) => setFormData({ ...formData, state: e.target.state })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter state"
                  required
                />
              </div>
              <div className="w-1/2 p-1">
                {" "}
                <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                  City
                </label>
                <input
                  type="city"
                  id="city"
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter city"
                  required
                />
              </div>
            </div> */}
                <form className="mb-5 mx-auto">
                  <label htmlFor="address" className="block mb-2 text-sm font-medium">
                    Address
                  </label>
                  <textarea
                    id="address"
                    rows="2"
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    className="block p-2.5 w-full text-sm   rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={formData.address}
                    placeholder="Enter address"
                  ></textarea>
                </form>
                <div className="mb-5">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    value={formData.email}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="name@profitai.com"
                    required
                  />
                </div>
                <div className="w-full flex items-center justify-center py-[20px]">
                  <button
                    type="submit"
                    disabled={disableSubmitButton}
                    // onClick={handleSubmit}
                    className="bg-black text-white px-[20px] py-[10px] rounded-full"
                  >
                    Click to Submit
                  </button>
                </div>
              </div>
            </form>{" "}
          </div>
        </div>
      </CustomModal>
      <button
        type="button"
        className="flex items-center font-medium gap-[6px] border-[1px] border-black text-md px-2 py-[2px] rounded-full ml-2"
        onClick={() => setShowModal(!showModal)}
      >
        Edit
        <Edit2 className="h-[15px] w-[15px]" />
      </button>
    </>
  );
}

export default EditVendor;
