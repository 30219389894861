import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import axios from "axios";

//Fetch specific product by ID
export const fetchProductByID = createAsyncThunk("product/fetchProductByID", async (id, extra) => {
  const res = await http.get(`/inventory/api/product/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch batches by product ID
export const fetchBatchesByProductID = createAsyncThunk("product/fetchBatchesByProductID", async (data, extra) => {
  const res = await http.get(`/inventory/api/products/${data.productId}/batches/${data?.filters ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return { batchData: res.data, productId: data.productId, searched: data?.filters ? true : false };
  }
});

export const fetchMoreBatchesProductID = createAsyncThunk("product/fetchMoreBatchesByProductID", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const fetchProducts = createAsyncThunk("product/fetchProducts", async (data, extra) => {
  const res = await http.get("/inventory/api/product/listcreate", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//fetchMoreProducts - for react infinity scroll to fetch more data
export const fetchMoreProducts = createAsyncThunk("product/fetchMoreProducts", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const searchProducts = createAsyncThunk("product/searchProducts", async (data, extra) => {
  const res = await http.get(`/inventory/api/product/search?search=${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// searchMoreProducts - for react infinity scroll to fetch more search data
export const searchMoreProducts = createAsyncThunk("product/SearchMoreProducts", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const createProduct = createAsyncThunk("product/createProduct", async (data, extra) => {
  const res = await http.post("inventory/api/product/listcreate", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return { ...res.data.data, batches: [] };
  }
});

export const updateProduct = createAsyncThunk("product/updateProduct", async (data, extra) => {
  const res = await http.put(`/inventory/api/product/all/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const deleteProduct = createAsyncThunk("product/deleteProduct", async (data, extra) => {
  const res = await http.delete(`/inventory/api/product/all/${data.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return data.id;
  }
});

//Deprecated - remove if 0 dependency
export const getProductTypes = createAsyncThunk("product/getProductTypes", async (data, extra) => {
  const res = await http.get("/master_menu/api/ProductType/create", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const fetchTopProducts = createAsyncThunk("product/fetchTopProducts", async (data, extra) => {
  const res = await http.get("/inventory/api/product/listcreate", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const salesAnalyticsByProductId = createAsyncThunk("product/salesAnalyticsByProductId", async (data, extra) => {
  const res = await http.get(`/invoice/api/invoice/monthly-sales-analytics?product_id=${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// Fetch Recommended products list
export const fetchProductRecommendedList = createAsyncThunk("product/recommendedList", async (data, extra) => {
  const res = await http.get("/inventory/api/product/purchase_recommend_list", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// Batches reducer
export const createBatch = createAsyncThunk("product/createBatch", async (data, extra) => {
  const res = await http.post("/inventory/api/product/batchescreate", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return { product_id: data.product_id, batches: res.data.data };
  }
});

export const updateBatch = createAsyncThunk("product/updateBatch", async (data, extra) => {
  const res = await http.put("/inventory/api/product/batchescreate", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return data;
  }
});

export const deleteBatch = createAsyncThunk("product/deleteBatch", async (data, extra) => {
  const res = await http.delete("/inventory/api/product/batchescreate", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  });
  if (res.data.status === "success") {
    return data;
  }
});

//Get product with or without batch by barcode - no addcase builder
export const fetchProductByBarcode = createAsyncThunk("product/fetchProductByBarcode", async (data, extra) => {
  const res = await http.get(`/inventory/api/product/barcode/${data}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    console.log(res.data.data, "this is the response data data");
    return res.data.data;
  }
});

//Generate barcode image (for thermal printer) - no addcase builder
export const generateBarcodeImage = createAsyncThunk("product/generateBarcodeImage", async (data, extra) => {
  const res = await http.get(`/inventory/api/barcode/generate/${data}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  //PNG barcode file
  return res;
});

export const productSlice = createSlice({
  name: "product",
  initialState: {
    product_analytics: {
      current_month_data: [],
      previous_month_data: [],
      x_labels: [],
    },
    products: {
      data: [
        {
          batches: [],
        },
      ],
      next: "",
    },
    top_products: [],
    batchesByProductId: { data: [], next: null },
    // product_types: [],
    productById: {},
    searchedProducts: { data: [], next: "" },
    productRecommendedList: [],
  },
  reducers: {
    getProducts: async (state, action) => {
      return state.products;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductByID.fulfilled, (state, action) => {
      const productFound = state.products.data.find((product) => product.id === action.payload.id);

      if (productFound) {
        return {
          ...state,
          productById: action.payload,
        };
      } else {
        return {
          ...state,
          productById: action.payload,
          products: { ...state.products, data: [...state.products.data, action.payload.data] },
        };
      }
    });
    builder.addCase(fetchBatchesByProductID.fulfilled, (state, action) => {
      //adding searched  batch in normal products array as well to fix majority invoice glitches and issues
      //not adding this in fetchMoreBatchesProductId because MUI autocomplete is limited to 10 anyways.
      let updatedProducts = state.products.data;

      if (action?.payload?.batchData?.data?.length > 0) {
        updatedProducts = state.products?.data.map((product) => {
          if (product?.id === action.payload.productId) {
            const batchesToAdd = action.payload.batchData.data.map((searchedBatch) => {
              const batchFound = product?.batches?.find((productbatch) => productbatch?.id === searchedBatch?.id);
              if (!batchFound) {
                return searchedBatch;
              }
              return null;
            });
            return { ...product, batches: [...product.batches, ...batchesToAdd.filter((batch) => batch)] };
          }
          return product;
        });
      }

      if (action.payload?.searched === true) {
        return {
          ...state,
          batchesByProductId: { data: action.payload.batchData.data, next: action.payload.batchData.next },
          products: { ...state.products, data: updatedProducts },
        };
      } else {
        return {
          ...state,
          batchesByProductId: { data: action.payload.batchData.data, next: action.payload.batchData.next },
        };
      }
    });
    builder.addCase(fetchMoreBatchesProductID.fulfilled, (state, action) => {
      return {
        ...state,
        batchesByProductId: {
          ...state.batchesByProductId,
          data: [...state.batchesByProductId.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload.data.map((item) => ({
            ...item,
            label: item.product_name,
          })),
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreProducts.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...state.products,
          data: [...state.products.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(searchProducts.fulfilled, (state, action) => {
      //adding searched products in normal products array as well to fix majority invoice glitches and issues
      //not adding this in searchMoreProducts because autocomplete is limited to 10 anyways.

      const productsToAdd = action.payload?.data?.map((searchedProduct) => {
        const productFound = state.products?.data?.find((product) => product?.id === searchedProduct?.id);
        if (!productFound) {
          return searchedProduct;
        }
        return null;
      });

      if (!productsToAdd) {
        return state;
      }

      return {
        ...state,
        searchedProducts: { data: action.payload.data, next: action.payload.next },
        products: { ...state.products, data: [...state.products.data, ...productsToAdd?.filter((product) => product)] },
      };
    });
    builder.addCase(searchMoreProducts.fulfilled, (state, action) => {
      if (!state.searchedProducts?.data) {
        return {
          ...state,
          searchedProducts: {
            data: [],
            next: "",
          },
        };
      }
      return {
        ...state,
        searchedProducts: {
          ...state.searchedProducts,
          data: [...state.searchedProducts.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(createProduct.fulfilled, (state, action) => {
      if (action.payload) {
        return { ...state, products: { ...state.products, data: [action.payload, ...state.products.data] } };
      }
    });
    // pHaniver - Temp Fix - added batches manually
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          products: {
            ...state.products,
            data: state.products.data.map((item) => {
              return item.id === action.payload.id ? { ...action.payload, batches: item.batches } : item;
            }),
          },
        };
      }
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      return {
        ...state,
        products: { ...state.products, data: state.products.data.filter((product) => product.id !== action.payload) },
      };
    });
    builder.addCase(getProductTypes.fulfilled, (state, action) => {
      return { ...state, product_types: action.payload.map((item) => ({ ...item, label: item.category_name })) };
    });
    builder.addCase(fetchTopProducts.fulfilled, (state, action) => {
      return {
        ...state,
        top_products: action.payload.map((item) => ({ ...item, name: item.product_name, amount: item.sales_price })),
      };
    });
    builder.addCase(salesAnalyticsByProductId.fulfilled, (state, action) => {
      return { ...state, product_analytics: action.payload };
    });
    builder.addCase(fetchProductRecommendedList.fulfilled, (state, action) => {
      return { ...state, productRecommendedList: action.payload };
    });
    builder.addCase(createBatch.fulfilled, (state, action) => {
      const productIndex = state.products.data.findIndex((product) => product.id === action.payload.product_id);
      if (productIndex !== -1) {
        const updatedProducts = state.products.data.map((product, index) => {
          if (index === productIndex) {
            return {
              ...product,
              batches: [...product?.batches, ...action.payload.batches],
            };
          }
          return product;
        });
        return { ...state, products: { ...state.products, data: updatedProducts } };
      }
    });

    builder.addCase(updateBatch.fulfilled, (state, action) => {
      const { batch_id, product_id, batch } = action.payload;
      const productIndex = state.products.data.findIndex((product) => product.id === product_id);

      if (productIndex !== -1) {
        const updatedProducts = state.products.data.map((product, index) => {
          if (index === productIndex) {
            const updatedBatches = product.batches.map((item) => (item.id === batch_id ? batch : item));
            return { ...product, batches: updatedBatches };
          }
          return product;
        });
        return { ...state, products: { ...state.products, data: updatedProducts } };
      }
      return state;
    });

    builder.addCase(deleteBatch.fulfilled, (state, action) => {
      const updatedProducts = state.products.data.map((product) => ({
        ...product,
        batches: product.batches.filter((batch) => batch.id !== action.payload.batch_id),
      }));
      return { ...state, products: { ...state.products, data: updatedProducts } };
    });
    builder.addCase(fetchProductByBarcode.fulfilled, (state, action) => {
      const product = action.payload;
      const batch = action.payload.batch;

      const productFound = state?.products?.data?.find((currentProduct) => currentProduct?.id === product?.id);

      if (!productFound) {
        return {
          ...state,
          products: { ...state.products, data: [...state.products.data, { ...product, batches: [batch] }] },
        };
      } else {
        return state;
      }
    });
  },
});

export default productSlice.reducer;
export const { getProducts, getBatchDetailsByProductId } = productSlice.actions;
