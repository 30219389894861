import React, { useState, useEffect } from "react";
// import "./SharedStyles.scss";

const CommonLists = ({ title, type, data, labels }) => {
  // const [seeAll, setSeeAll] = useState(false);
  const [list, setList] = useState(data);

  useEffect(() => {
    setList(data);
  }, [setList, data]);

  return (
    <>
      <div className="border-2 rounded-[20px] max-h-[40vh] overflow-y-scroll">
        <div className="text-center text-neutral-500 text-md py-[20px]">{title}</div>
        <div>
          <div className="px-[20px] flex w-full justify-between border-neutral-200 border-b-2">
            {labels?.map((label, index) => (
              <span key={index} className="text-neutral-500">
                {label}
              </span>
            ))}
          </div>
          {list.length === 0 && <div className="text-sm text-center py-[10px]">No data found!</div>}
          {list.map((item, index) => {
            return (
              <div key={index} className="flex mx-[20px] border-b-2 last:border-b-0 py-2 justify-around">
                <div className="flex-1 flex justify-start">{index + 1}.</div>
                <div className="flex-1 flex justify-center">
                  {" "}
                  {item.product_name.length > 15 ? item.product_name.slice(0, 15) + "..." : item.product_name}{" "}
                </div>
                <div className="flex-1 flex justify-end">{item.total_remaining_quantity}</div>
              </div>
            );
          })}
        </div>
        {/* <div
          className="list-see-all-btn"
          onClick={() => {
            setSeeAll(!seeAll);
          }}
        >
          {seeAll ? "See less" : "See more..."}
        </div> */}
      </div>
      {/* <div></div> */}
    </>
  );
};

export default CommonLists;
