import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleGlobalSearch } from "../../../reducers/Slices/appSlice";

const ProductCommonLists = ({ title, subtitle, data }) => {
  const dispatch = useDispatch();
  // const [seeAll, setSeeAll] = useState(false);
  const [list, setList] = useState(data);

  useEffect(() => {
    setList(data);
  }, [setList, data]);

  //   useEffect to change the height of the container
  // useEffect(() => {
  //   setList(data);
  // }, [seeAll, data]);

  return (
    <>
      <div className="border-2 rounded-[20px]">
        <div className="my-[10px]">
          {list.map((item, index) => {
            return (
              <Link to={`/inventory?product_name=${item.product_name}`}>
                <div key={index} className="flex mx-[20px] border-b-2 last:border-b-0 py-2">
                  <div className="">{index + 1}.</div>
                  <div className="ml-[50%] translate-x-[-60%]">
                    {" "}
                    {item.product_name.length > 15 ? item.product_name.slice(0, 15) + "..." : item.product_name}{" "}
                  </div>
                  {/* <div className=""> {name.amount} </div> */}
                </div>
              </Link>
            );
          })}
        </div>
        {/* <div
          className="list-see-all-btn"
          onClick={() => {
            setSeeAll(!seeAll);
          }}
        >
          {seeAll ? "See less" : "See more..."}
        </div> */}
      </div>
      {/* <div></div> */}
    </>
  );
};

export default ProductCommonLists;
