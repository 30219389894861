import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import RazorPayLogo from "../../assets/images/razorpay_logo.png";
import ProfitAILogo from "../../assets/images/TheProfitAi_logo.png";
import {
  checkPromoCode,
  createRazorpayOrder,
  fetchSubscriptionPlans,
  verifyRazorpayPayment,
} from "../../reducers/Slices/userSlice";
import { checkTokenExists } from "../../utils/session";
import { http } from "../../service/http";
import { setBusinessDetails } from "../../reducers/Slices/userSlice";

function CheckoutPage() {
  const { planId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const businessDetails = useSelector((state) => state.user);
  let subscriptionPlans = useSelector((state) => state.user.subscriptionPlans);

  //Select plan based on paramId
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  // Todo - show valid promocode above promocode section and remove button.
  const [validPromoCode, setValidPromoCode] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [allowCustomerDetailChange, setAllowCustomerDetailChange] = useState(false);

  //Customer details for RazorPay handler
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    email: "",
    contact: "",
  });

  //Temp customer details for RazorPay handler
  const [tempCustomerDetails, setTempCustomerDetails] = useState({
    name: "",
    email: "",
    contact: "",
  });

  useEffect(() => {
    dispatch(fetchSubscriptionPlans());

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!checkTokenExists() || localStorage.getItem("business_profile") === "false") {
      navigate("/login");
    } else {
      handleProfileDetails();
    }
    // eslint-disable-next-line
  }, []);

  const handleProfileDetails = () => {
    http
      .get("user_profile/backend/business-all/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(setBusinessDetails({ ...res.data.data, phone: res.data?.user_profile_phone }));
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (!businessDetails) {
      return;
    }

    const nextCustomerDetails = {
      name: businessDetails?.business_name, //your customer's name
      email: businessDetails?.email,
      contact: businessDetails?.phone?.slice(-10),
    };
    setCustomerDetails(nextCustomerDetails);
    setTempCustomerDetails(nextCustomerDetails);
  }, [businessDetails]);

  //Open RazorPay console
  const showRazorpay = async (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleBuyPlan = (plan) => {
    if (!plan || !plan.id) return;

    // Check if razorpay_payment_details already exists in local storage
    const existingPaymentDetails = localStorage.getItem("razorpay_payment_details");

    if (existingPaymentDetails) {
      const paymentData = JSON.parse(existingPaymentDetails);
      // Directly verify payment using the existing data
      handleVerifyPayment(paymentData);
      return;
    }

    dispatch(createRazorpayOrder({ plan_id: plan.id, code: validPromoCode }))
      .then((action) => {
        if (action.payload) {
          let { transaction } = action.payload;

          let options = {
            key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
            amount: transaction?.amount,
            currency: "INR",
            name: "The Profit AI",
            description: "Subscription Transaction",
            order_id: transaction?.order_id,
            handler: function (response) {
              const paymentData = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              };

              localStorage.setItem("razorpay_payment_details", JSON.stringify(paymentData));
              handleVerifyPayment(paymentData);
            },
            prefill: {
              name: customerDetails?.name,
              email: customerDetails?.email,
              contact: customerDetails?.contact,
            },
          };

          showRazorpay(options);
        }
      })
      .catch((err) => {
        toast.error("Order placement failed please try again!");
      });
  };

  const handleVerifyPayment = (data) => {
    dispatch(verifyRazorpayPayment({ response: data }))
      .then((action) => {
        if (action.payload) {
          toast.success("Payment Successful!! Redirecting to home screen");
          navigate("/");
        } else {
          toast.error("Payment verification failed. Please try again!");
        }
      })
      .catch((err) => {
        toast.error("An error occurred during payment verification. Please try again!");
      });
  };

  //todo - Promo code API implementation
  const handleApplyPromoCode = (plan) => {
    //Check Promocode using API and apply it if valid promocode also set final amount if valid else grandtotal calculated.
    if (!promoCode && !selectedPlan) {
      return;
    }

    dispatch(checkPromoCode({ code: promoCode, plan_id: plan?.id }))
      .then((action) => {
        if (action.payload) {
          setValidPromoCode(action.payload?.code);
          setDiscountedPrice(plan?.price_without_gst - action.payload?.discount_amount);
          toast.success(`${promoCode} Applied!`);
        } else {
          toast.error(`${promoCode} Promocode is either expired or invalid`);
        }
      })
      .catch((err) => {
        toast.error("An error occurred during payment verification. Please try again!");
      });
  };

  //Plan labels
  const planLabels = {
    monthly: "Monthly Plan",
    quarterly: "Quarterly Plan",
    annual: "Annual Plan",
    "5-year": "5 Year Plan",
  };

  //Use effect to get plan details based on planId param
  useEffect(() => {
    // const subscriptionPlans = [
    //   {
    //     id: 1,
    //     name: "monthly",
    //     price_without_gst: "600.00",
    //     price_with_gst: "708.00",
    //     gst_percent: "18.00",
    //     annual_cost: "7200.00",
    //     annual_cost_with_gst: "8496.00",
    //     discount: "0.00",
    //     savings_per_year: "0.00",
    //     duration_days: 30,
    //   },
    //   {
    //     id: 2,
    //     name: "quarterly",
    //     price_without_gst: "1500.00",
    //     price_with_gst: "1770.00",
    //     gst_percent: "18.00",
    //     annual_cost: "6000.00",
    //     annual_cost_with_gst: "7080.00",
    //     discount: "16.67",
    //     savings_per_year: "1200.00",
    //     duration_days: 90,
    //   },
    //   {
    //     id: 3,
    //     name: "annual",
    //     price_without_gst: "5500.00",
    //     price_with_gst: "6490.00",
    //     gst_percent: "18.00",
    //     annual_cost: "5500.00",
    //     annual_cost_with_gst: "6490.00",
    //     discount: "23.61",
    //     savings_per_year: "1700.00",
    //     duration_days: 365,
    //   },
    //   {
    //     id: 4,
    //     name: "5-year",
    //     price_without_gst: "23375.00",
    //     price_with_gst: "27583.00",
    //     gst_percent: "18.00",
    //     annual_cost: "4675.00",
    //     annual_cost_with_gst: "5517.00",
    //     discount: "35.07",
    //     savings_per_year: "2525.00",
    //     duration_days: 1825,
    //   },
    // ];

    if (!planId) {
      return;
    }

    if (!subscriptionPlans && !subscriptionPlans?.length === 0) {
      return;
    }

    //Todo - remove this and add buiness details api - temp here for building purposes
    const fetchCustomerDetails = () => {
      return {
        name: "John Doe",
        email: "john@example.com",
        contact: "1234567890",
      };
    };

    const customerDetails = fetchCustomerDetails();
    setCustomerDetails(customerDetails);

    setSelectedPlan(() => subscriptionPlans.find((plan) => plan.id === parseInt(planId)));
  }, [planId, subscriptionPlans]);

  const handleChangeBillingDetails = (e) => {
    e.preventDefault();
    setCustomerDetails(tempCustomerDetails);
    setAllowCustomerDetailChange(!allowCustomerDetailChange);
  };

  //Note - Why go the extra effort to - price without gst - price with gst? these values are charged rounded up.
  const subtotal = selectedPlan ? parseFloat(selectedPlan.price_without_gst) : 0;
  const discount = Math.abs(discountedPrice ? subtotal - parseFloat(discountedPrice) : 0);
  const tax = (
    discountedPrice
      ? discountedPrice * 0.18
      : parseFloat(selectedPlan?.price_with_gst) - parseFloat(selectedPlan?.price_without_gst)
  ).toFixed(2); // 18% GST rate
  const grandTotal = (
    discountedPrice ? parseFloat(discountedPrice) + parseFloat(tax) : subtotal + parseFloat(tax)
  ).toFixed(2);

  return (
    <div className="container mx-auto p-2 h-[100vh]">
      <div className="flex items-center justify-between">
        <img className="w-[160px]" src={ProfitAILogo} alt="TheProfitAI" />
        <Link to={"/plan_selection"}>
          <span className="underline text-neutral">Back To Plan Selection</span>
        </Link>
      </div>

      <div className="border-2 border-neutral-200 rounded-[20px] p-[15px]">
        <div className="flex gap-[10px]">
          {/* Customer Details and Plan Details */}
          <div className="p-4 w-[70%] border rounded-[20px]">
            <h2 className="text-xl font-semibold mb-4">Business Details</h2>
            {/* Business Details - Editable */}
            <div className="rounded-[20px] border p-[20px]">
              <form onSubmit={(e) => handleChangeBillingDetails(e)}>
                <div className="mb-4 flex items-center gap-[10px]">
                  <label className="block text-nowrap" htmlFor="name">
                    Business Name :
                  </label>
                  {allowCustomerDetailChange && (
                    <input
                      type="text"
                      id="name"
                      value={tempCustomerDetails?.name}
                      onChange={(e) => setTempCustomerDetails({ ...tempCustomerDetails, name: e.target.value })}
                      className="border p-2 rounded w-full"
                      placeholder="Enter your name"
                    />
                  )}
                  {!allowCustomerDetailChange && <span className="font-semibold">{customerDetails?.name}</span>}
                </div>
                <div className="mb-4 flex items-center gap-[10px]">
                  <label className="block text-nowrap" htmlFor="email">
                    Email :
                  </label>
                  {allowCustomerDetailChange && (
                    <input
                      type="email"
                      id="email"
                      value={tempCustomerDetails?.email}
                      onChange={(e) => setTempCustomerDetails({ ...tempCustomerDetails, email: e.target.value })}
                      className="border p-2 rounded w-full"
                      placeholder="Enter your email"
                    />
                  )}
                  {!allowCustomerDetailChange && <span className="font-semibold">{customerDetails?.email}</span>}
                </div>
                <div className="mb-4 flex items-center gap-[10px]">
                  <label className="block text-nowrap" htmlFor="contact">
                    Contact :
                  </label>
                  {allowCustomerDetailChange && (
                    <input
                      type="text"
                      id="contact"
                      value={tempCustomerDetails?.contact}
                      onChange={(e) => setTempCustomerDetails({ ...tempCustomerDetails, contact: e.target.value })}
                      className="border p-2 rounded w-full"
                      placeholder="Enter your contact number"
                    />
                  )}
                  {!allowCustomerDetailChange && <span className="font-semibold">+91-{customerDetails?.contact}</span>}
                </div>
                <button type="submit" className="px-[10px] py-[10px] rounded-md bg-black text-white my-[10px]">
                  {allowCustomerDetailChange ? "Submit" : "Change Details"}
                </button>
              </form>
              <p className="text-sm py-[10px] text-neutral-500">
                *Please note these detail changes are for billing only. You can permanently change business details
                after account creation.
              </p>
            </div>

            <h2 className="text-xl font-semibold mb-4 mt-4">Selected Plan</h2>
            {selectedPlan && (
              <div className="rounded-[20px] border p-[20px] flex flex-col gap-[10px]">
                <span>
                  <span>Plan Name: </span>
                  <span className="font-semibold">{planLabels[selectedPlan?.name]}</span>
                </span>
                <span>
                  <span>Price: </span>
                  <span className="font-semibold">₹{selectedPlan?.price_without_gst}</span>
                </span>
                <span>
                  <span>Duration: </span>
                  <span className="font-semibold">{selectedPlan?.duration_days} days</span>
                </span>
                <span>
                  <span>Annual Cost: </span>
                  <span className="font-semibold">₹{selectedPlan?.annual_cost}</span>
                </span>
                <p className="text-neutral-500 text-sm mt-[5px]">*These amounts are without taxes</p>
              </div>
            )}
          </div>

          {/* Pricing Details */}
          <div className="px-4 border border-neutral-400 rounded-[20px] w-[30%] flex flex-col justify-between h-min">
            <div className="pt-4">
              <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
              <div className="rounded-[20px] border bg-white border-neutral-400 p-[20px] ">
                <div>
                  <div className="flex justify-between mb-2">
                    <span>Subtotal:</span>
                    <span>₹{subtotal.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span>Discount:</span>
                    <span>- ₹{discount.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span>Tax (18% GST):</span>
                    <span>+ ₹{tax}</span>
                  </div>
                  <div className="flex justify-between mb-2 font-bold">
                    <span>Total:</span>
                    <span>₹{grandTotal}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Apply or Change PromoCode */}
            <div className="py-4">
              {!validPromoCode && (
                <div className="flex">
                  <input
                    type="text"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className="border my-[10px] py-2 px-[5px] xl:px-[15px] flex-1 rounded-l-xl border-neutral-500"
                    placeholder="Enter Promocode"
                    onKeyDown={(e) => e.key === "Enter" && handleApplyPromoCode(selectedPlan)}
                  />
                  <button
                    onClick={() => handleApplyPromoCode(selectedPlan)}
                    className="bg-black text-white px-[10px] xl:px-[20px] py-2 my-[10px] rounded-r-xl "
                  >
                    Apply
                  </button>
                </div>
              )}

              {validPromoCode && (
                <div className="flex justify-between pb-[10px] px-[10px]">
                  <span className="text-green-500 py-[10px]">{validPromoCode} Applied!</span>
                  <button
                    className="text-white h-[35px] w-[35px] text-lg bg-black rounded-full flex items-center justify-center"
                    onClick={() => {
                      setValidPromoCode(null);
                      setDiscountedPrice(null);
                    }}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Pay Button */}
        <div className="flex justify-center py-[30px]">
          <button
            className="bg-white hover:bg-black border-2 border-blue-800 px-[20px] py-[10px] text-black hover:text-white rounded-full flex items-center justify-center"
            disabled={!selectedPlan}
            onClick={() => handleBuyPlan(selectedPlan)}
          >
            Pay ₹{grandTotal} with <img className="h-[20px] ml-[10px]" src={RazorPayLogo} alt="RazorPay logo" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;
