import React from "react";

function Dashboard({ data }) {
  const { overall_gross_sales, overall_gross_profit, overall_tax, overall_net_profit } = data;

  return (
    <div className="h-[120px] flex items-center rounded-[10px] m-[20px] gap-[10px]">
      <div className="flex-1 rounded-[20px] p-[20px] border-[1px] border-neutral-200 shadow">
        <h3 className="text-center text-xl text-neutral-500">Total Sales</h3>
        <span className="text-center block mt-[10px] text-lg">₹{overall_gross_sales || 0}</span>
      </div>
      <div className="flex-1 rounded-[20px] p-[20px] border-[1px] border-neutral-200 shadow">
        <h3 className="text-center text-xl text-neutral-500">Taxes(GST)</h3>
        <span className="text-center block mt-[10px] text-lg">₹{overall_tax || 0}</span>
      </div>
      <div className="flex-1 rounded-[20px] p-[20px] border-[1px] border-neutral-200 shadow">
        <h3 className="text-center text-xl text-neutral-500">Gross Profit</h3>
        <span
          className={`text-center block mt-[10px] text-lg ${
            overall_gross_profit >= -1 ? "text-green-500" : "text-red-500"
          }`}
        >
          {overall_gross_profit >= -1 ? `₹${overall_gross_profit || 0}` : `-₹${Math.abs(overall_gross_profit) || 0}`}
        </span>
      </div>
      {/* pHaniver - TODO - style - red if negative , green if positive*/}
      <div className="flex-1 rounded-[20px] p-[20px] border-[1px] border-neutral-200 shadow">
        <h3 className="text-center text-xl text-neutral-500">Net P&L</h3>
        <span
          className={`text-center block mt-[10px] text-lg ${
            overall_net_profit >= -1 ? "text-green-500" : "text-red-500"
          }`}
        >
          {overall_net_profit >= -1 ? `₹${overall_net_profit || 0}` : `-₹${Math.abs(overall_net_profit) || 0}`}
        </span>
      </div>
    </div>
  );
}

export default Dashboard;
