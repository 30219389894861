import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const Inventory = ({ data }) => {
  const filteredData =
    data
      ?.filter((item) => item.value > 0) // Filter out entries with value 0
      ?.map((item, index) => ({
        label:
          item?.product__brand?.length > 15 ? item?.product__brand?.substring(0, 10) + "..." : item?.product__brand,
        value: item.value,
        id: index,
      }))
      ?.slice(0, 5) || [];

  return (
    <div className="h-[250px] w-[360px]">
      <span className="block py-[10px] text-center font-semibold">Inventory</span>
      <PieChart
        colors={["#002F5D", "#004B95", "#519DE9", "#A9ACA9"]}
        series={[
          {
            data: filteredData,
            highlightScope: { faded: "global", highlighted: "item" },
          },
        ]}
        height={180}
        width={350}
        margin={{ top: 10, right: 150, bottom: 10, left: 0 }} // Adjusted margins
        sx={{
          "& .MuiChart-tooltip": {
            maxWidth: 360,
          },
        }}
      />
    </div>
  );
};

export default Inventory;
