import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExpenses, fetchMoreExpenses } from "../../reducers/Slices/expenseSlice";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

//Functional Components
import AddExpense from "./ExpenseComponents/AddExpense";
import EditExpense from "./ExpenseComponents/EditExpense";
import RemoveExpense from "./ExpenseComponents/RemoveExpense";

//UI imports
import IconWrapper from "../MicroComponents/IconWrapper";
import { Search } from "react-feather";

const Expenses = () => {
  const dispatch = useDispatch();

  const expenses = useSelector((state) => state.expense.expenses.data);
  const expensesNext = useSelector((state) => state.expense.expenses.next);

  const [hasMoreExpenses, setHasMoreExpenses] = useState(() => (expensesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (expensesNext) {
      setHasMoreExpenses(true);
    } else {
      setHasMoreExpenses(false);
    }
  }, [expensesNext]);

  //fetch more expenses on scroll if available
  const fetchNextExpenses = () => {
    if (expensesNext) {
      dispatch(fetchMoreExpenses(expensesNext));
    }
  };

  useEffect(() => {
    dispatch(fetchExpenses());
  }, [dispatch]);

  const [selectedExpense, setSelectedExpense] = useState({});
  const [searchExpense, setSearchExpense] = useState("");

  // Debounce expense search to throttle api calls to backend
  const debouncedExpenseSearch = debounce((value) => setSearchExpense(value), 300);

  useEffect(() => {
    if (searchExpense.length > 0) {
      dispatch(fetchExpenses(`/?search=${searchExpense}`));
    } else {
      dispatch(fetchExpenses());
    }
  }, [searchExpense, dispatch]);

  //Default selected expense as first expense during first render
  useEffect(() => {
    setSelectedExpense(expenses[0]);
  }, [expenses]);

  return (
    <div className="flex h-[100vh-80px]">
      {/* employee list section*/}
      <div className="flex-1 max-w-[30%] border-r-2 border-neutral-200 h-[91.3vh]">
        <div className="flex gap-[10px] border-b-2 border-neutral-200 p-[15px]">
          {/* //Search Bar */}
          <div className="flex w-full">
            <input
              type="text"
              className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
              placeholder="Search Expense..."
              onChange={(e) => debouncedExpenseSearch(e.target.value)}
            />
            <button className="-ml-[40px]">
              <IconWrapper>
                <Search className="z-10 text-white"></Search>
              </IconWrapper>
            </button>
          </div>

          {/* add employee button */}
          <AddExpense />
        </div>
        {/* expense Container */}
        {expenses?.length === 0 && <div className="text-center py-10 font-semibold">No Entries found!</div>}
        {expenses?.length > 0 && (
          <div className="overflow-y-scroll">
            <InfiniteScroll
              dataLength={expenses.length}
              next={fetchNextExpenses}
              hasMore={hasMoreExpenses}
              height={"70vh"}
              loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
              endMessage={
                <p style={{ textAlign: "center", paddingTop: "10px" }}>
                  <b>Expenses List End</b>
                </p>
              }
            >
              <ul className="flex flex-col p-[15px] gap-[10px]">
                {expenses.map((expense, index) => (
                  <li
                    key={index}
                    className="w-full flex justify-between p-[12px] border-2 border-neutral-200 rounded-full cursor-pointer"
                    onClick={() => setSelectedExpense(expense)}
                  >
                    <span>
                      <span>{index + 1}.</span>
                      <span className="ml-[5px]">{expense.expense_name}</span>
                    </span>
                    <span>{expense.category}</span>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </div>
        )}
      </div>
      {!selectedExpense && (
        <div className="w-full max-w-[70%] text-xl flex items-center justify-center">
          Start adding entries to see this panel!
        </div>
      )}
      {selectedExpense && (
        <div className="flex-1 max-w-[70%] px-[20px] py-[10px]">
          <div>
            <h1 className="text-2xl">Expense Name</h1>
            <span className="text-neutral-500">{selectedExpense?.expense_name}</span>
          </div>
          <div className="mt-[20px]">
            <h3 className="text-lg">Category</h3>
            <span className="text-neutral-500">{selectedExpense?.category}</span>
          </div>
          <div className="mt-[10px]">
            <h3 className="text-lg">Cost</h3>
            <span className="text-neutral-500">{selectedExpense?.cost}</span>
          </div>
          <div className="mt-[10px]">
            <h3 className="text-lg">Paid To</h3>
            <span className="text-neutral-500">{selectedExpense?.paid_to}</span>
          </div>
          <div className="mt-[10px]">
            <h3 className="text-lg">Note</h3>
            <div>
              <p className="text-body text-neutral-500 max-w-[500px]">{selectedExpense?.note}</p>
            </div>
          </div>
          {/* Operation Button */}
          <div className="mt-[40px] flex max-w-[500px] gap-[10px]">
            <EditExpense expense={selectedExpense} />
            <RemoveExpense expense={selectedExpense} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Expenses;
