import React, { useState, useEffect } from "react";
import { humanDateFormat } from "../../utils/dateFormat";
// import { cancelInvoice } from "../../reducers/Slices/invoiceSlice";
import { deletePurchaseInvoice } from "../../reducers/Slices/purchaseInvoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchMorePurchaseInvoices } from "../../reducers/Slices/purchaseInvoiceSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import toast from "react-hot-toast";

function ShowInvoices({ selectedUser, invoices, isCancelled }) {
  const dispatch = useDispatch();

  const invoicesNext = useSelector((state) => state.purchaseInvoice.purchaseInvoices.next);
  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (invoicesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (invoicesNext) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [invoicesNext]);

  //fetch more expenses on scroll if available
  const fetchNextInvoices = () => {
    if (invoicesNext) {
      dispatch(fetchMorePurchaseInvoices(invoicesNext));
    }
  };

  const deleteInvoice = (id) => {
    if (window.confirm("are you sure you want to cancel this invoice?")) {
      dispatch(deletePurchaseInvoice({ id }))
        .then((action) => {
          if (action.payload) {
          } else {
            toast.error("Purchase Invoice cancellation failed");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
    <div className="w-full px-[20px] overflow-y-scroll">
      <h3 className="text-center text-2xl pb-[20px]">Invoice List</h3>
      <InfiniteScroll
        dataLength={invoices?.length}
        next={fetchNextInvoices}
        hasMore={hasMoreInvoices}
        height={"70vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px" }}>
            <b>Invoices List End</b>
          </p>
        }
      >
        {invoices?.length > 0 &&
          invoices?.map((invoice, index) => {
            return (
              <div
                key={index}
                className="flex border-2 border-neutral-200 rounded-[20px] p-[20px] mb-[20px] w-full justify-between items-start"
              >
                <div className="flex flex-col gap-[2px]">
                  <span className="text-lg">
                    Invoice Number: <span className="text-neutral-500">{invoice?.invoice_counter}</span>
                  </span>
                  <span className="text-lg">
                    Order Date:{" "}
                    <span className="text-neutral-500">
                      {invoice?.order_date_time ? humanDateFormat(invoice?.order_date_time) : "fetching date"}
                    </span>
                  </span>
                  <span className="text-lg">
                    Payment Type: <span className="text-neutral-500">{invoice?.payment_type}</span>
                  </span>
                  <span className="text-lg">
                    Payment Option: <span className="text-neutral-500">{invoice?.payment_option}</span>
                  </span>

                  <span className="text-lg">
                    Amount: <span className="text-neutral-500">{invoice?.grand_total}</span>
                  </span>
                </div>

                {!isCancelled && (
                  <button
                    className="px-[20px] border-2 border-red-500 text-red-500 py-[10px] rounded-full"
                    onClick={() => deleteInvoice(invoice?.id)}
                  >
                    Cancel Invoice
                  </button>
                )}

                {isCancelled && <span className="text-lg text-red-500">Cancelled</span>}
              </div>
            );
          })}
      </InfiniteScroll>
    </div>
  );
}

export default ShowInvoices;
