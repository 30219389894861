import React, { useEffect, useState } from "react";
// import "./ComponentStyles.scss";
import InvoiceDataTable from "./InvoiceDataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCreditNotes,
  fetchInvoices,
  fetchMoreCreditNotes,
  fetchMoreInvoices,
} from "../../../reducers/Slices/invoiceSlice";
import { fetchPurchaseInvoices, fetchMorePurchaseInvoices } from "../../../reducers/Slices/purchaseInvoiceSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import AddUser from "../../Invoices/AddUser";
import AddVendor from "../../Purchase/AddVendor";
import { MenuItem, Select } from "@mui/material";

import IconWrapper from "../../MicroComponents/IconWrapper";
import { Search } from "react-feather";
import { debounce } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
// import moment from 'moment';

const InvoiceContainer = ({ infoType }) => {
  const [search, setSearch] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);

  //Hot key to add Customer / Vendor depending on context sales / purchase
  useHotkeys("Ctrl + Alt + C, Ctrl + Option + C", () => {
    if (infoType === "vendor") {
      return;
    }
    setShowAddUser(!showAddUser);
  });

  useHotkeys("Ctrl + Alt + V, Ctrl + Option + V", () => {
    if (infoType === "customer") {
      return;
    }
    setShowAddUser(!showAddUser);
  });

  //Invoices and purchase invoices
  const invoices = useSelector((state) => state.invoice.invoices) || [];
  const creditNotes = useSelector((state) => state.invoice.creditNotes || []);
  const purchaseInvoices = useSelector((state) => state.purchaseInvoice.purchaseInvoices) || [];

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ month: "none", date_from: null, date_to: null, status: "" });
  const monthOption = [
    { label: "This Month", id: "none" },
    { label: "January", id: "1" },
    { label: "February", id: "2" },
    { label: "March", id: "3" },
    { label: "April", id: "4" },
    { label: "May", id: "5" },
    { label: "June", id: "6" },
    { label: "July", id: "7" },
    { label: "August", id: "8" },
    { label: "September", id: "9" },
    { label: "October", id: "10" },
    { label: "November", id: "11" },
    { label: "December", id: "12" },
  ];

  const [salesInvoiceType, setSalesInvoiceType] = useState("normal");
  const [statusTypeFilter, setStatusTypeFilter] = useState("all");

  useEffect(() => {
    if (infoType === "customer" && salesInvoiceType === "credit_note") {
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}`;
      dispatch(fetchCreditNotes(params));
    } else if (infoType === "customer") {
      //When infoType is customer run these string queries
      const params = `?is_purchase=0&search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}`;
      dispatch(fetchInvoices(params));
    } else if (infoType === "vendor") {
      //When infoType is vendors run these string queries
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}`;
      dispatch(fetchPurchaseInvoices(params));
    }
  }, [dispatch, search, filter, infoType, salesInvoiceType, statusTypeFilter]);

  // let debounceTimer;

  // const handleSetDebouncedSearch = (search) => {
  //   clearTimeout(debounceTimer);
  //   debounceTimer = setTimeout(() => {
  //     setSearch(search);
  //   }, 500);
  // };

  const setDebouncedSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  const handleLoadMore = () => {
    if (infoType === "customer" && salesInvoiceType === "credit_note") {
      dispatch(fetchMoreCreditNotes(creditNotes.next));
    } else if (infoType === "customer" && invoices.next) {
      dispatch(fetchMoreInvoices(invoices.next));
    } else if (infoType === "vendor" && purchaseInvoices.next) {
      dispatch(fetchMorePurchaseInvoices(purchaseInvoices.next));
    }
  };

  const handleFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div className="lg:min-w-[60vw] h-[calc(100vh-150px)]">
      <CustomModal isOpen={showAddUser} handleClose={() => setShowAddUser(false)}>
        {infoType === "customer" && <AddUser handleCloseModal={() => setShowAddUser(false)} currentCustomer={{}} />}
        {infoType === "vendor" && <AddVendor handleCloseModal={() => setShowAddUser(false)} currentVendor={{}} />}
      </CustomModal>
      <div className="font-semibold text-xl pb-[20px] pl-[10px]">Transactions</div>
      <div className="flex justify-between items-center">
        <div className="max-w-fit">
          <label for="default-search" className="sr-only">
            Search
          </label>
          <div className="flex items-center">
            <input
              type="search"
              onChange={(e) => setDebouncedSearch(e.target.value)}
              id="default-search"
              className="border-[1px] border-neutral-400 px-[20px] py-[8px] w-[350px] rounded-full focus:outline-none"
              placeholder="Search"
              required
            />
            <div className="-translate-x-[103%]">
              <IconWrapper>
                <Search className="z-10 text-white" />
              </IconWrapper>
            </div>
            {/* <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div> */}
          </div>
        </div>
        <button
          className="flex gap-[5px] bg-black text-white px-[20px] py-[10px] rounded-full"
          onClick={() => setShowAddUser(true)}
        >
          <span>+</span>
          <span className="capitalize">{`Add ${infoType}`}</span>
        </button>
      </div>
      <div className="w-full flex justify-between my-[20px]">
        <div className="flex gap-[10px] items-center justify-center">
          <div className="flex justify-center items-center">
            <Select
              value={filter.month}
              onChange={(e) => setFilter({ ...filter, month: e.target.value })}
              sx={{
                width: "auto",
                height: 40,
                boxShadow: "none",
                borderRadius: "999px",
                padding: "10px",
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "gray",
                  border: "1px solid gray",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "gray",
                  border: "1px solid gray",
                },
              }}
            >
              {/* <MenuItem value={filter.month}>This Month</MenuItem> */}
              {monthOption.map((i) => (
                <MenuItem value={i.id}>{i.label}</MenuItem>
              ))}
            </Select>
          </div>
          {infoType === "customer" && (
            <div>
              <Select
                value={salesInvoiceType}
                onChange={(e) => setSalesInvoiceType(e.target.value)}
                sx={{
                  width: "auto",
                  height: 40,
                  boxShadow: "none",
                  borderRadius: "999px",
                  padding: "10px",
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    border: "1px solid gray",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    border: "1px solid gray",
                  },
                }}
              >
                {/* <MenuItem value={filter.month}>This Month</MenuItem> */}
                <MenuItem value={"normal"}>Sales Invoice</MenuItem>
                <MenuItem value={"e_invoice"}>Sales E-Invoice</MenuItem>
                <MenuItem value={"credit_note"}>Credit Note</MenuItem>
              </Select>
            </div>
          )}
          {salesInvoiceType !== "credit_note" && (
            <div>
              <Select
                value={statusTypeFilter}
                onChange={(e) => setStatusTypeFilter(e.target.value)}
                sx={{
                  width: "auto",
                  height: 40,
                  boxShadow: "none",
                  borderRadius: "999px",
                  padding: "10px",
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    border: "1px solid gray",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    border: "1px solid gray",
                  },
                }}
              >
                {/* <MenuItem value={filter.month}>This Month</MenuItem> */}
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"paid"}>Paid Invoices</MenuItem>
                <MenuItem value={"remain_payment"}>Remaining Amount Invoices</MenuItem>
              </Select>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center">
          <div className="font-semibold mx-2">Between </div>
          <input
            type="date"
            className="border-2 py-[10px] px-[20px] rounded-full"
            max={filter.date_to}
            onChange={(e) => handleFilter("date_from", e.target.value)}
          />
          <div className="font-semibold mx-2">to</div>
          <input
            type="date"
            className="border-2 py-[10px] px-[20px] rounded-full"
            min={filter.date_from}
            onChange={(e) => handleFilter("date_to", e.target.value)}
          />
        </div>
      </div>
      <div>
        <InvoiceDataTable
          infoType={infoType}
          next={
            infoType === "customer"
              ? salesInvoiceType === "credit_note"
                ? creditNotes.next
                : invoices.next
              : purchaseInvoices.next
          }
          invoices={
            infoType === "customer"
              ? salesInvoiceType === "credit_note"
                ? creditNotes.data
                : invoices.data
              : purchaseInvoices.data
          }
          creditNote={salesInvoiceType !== "credit_note" ? true : false}
          fetchMore={handleLoadMore}
        />
      </div>
    </div>
  );
};

export default InvoiceContainer;
