import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import ProductCommonLists from "./GlobalSearchComponents/ProductCommonLists";
import CustomerCommonLists from "./GlobalSearchComponents/CustomerCommonLists";
import VendorCommonLists from "./GlobalSearchComponents/VendorCommonLists";

import { fetchGlobalSearchData, toggleGlobalSearch } from "../../reducers/Slices/appSlice";

//UI components
import { Search, X as Cross } from "react-feather";
import IconWrapper from "../MicroComponents/IconWrapper";

function GlobalSearch() {
  const dispatch = useDispatch();
  const { products, customers, vendors } = useSelector((state) => state.app.global_search);
  const [globalSearchTerm, setGlobalSearchTerm] = useState();

  const handleGlobalSearch = () => {
    if (globalSearchTerm?.length > 0) {
      dispatch(fetchGlobalSearchData(globalSearchTerm));
    }
  };

  //Debounced to throttle API call
  const debouncedSetGlobalSearchTerm = debounce((term) => {
    setGlobalSearchTerm(term);
  }, 500);

  //Auto search on user typing
  useEffect(() => {
    handleGlobalSearch();
    //eslint-disable-next-line
  }, [globalSearchTerm]);

  return (
    <>
      <div className="flex gap-2 py-[20px] px-[20px] border-b-2 border-netural-200">
        <label htmlFor="searchInput" className="sr-only">
          Search Term
        </label>
        <input
          id="searchInput"
          type="text"
          onChange={(event) => debouncedSetGlobalSearchTerm(event.target.value)}
          className="border-2 border-neutral-200 h-[40px] w-[600px] rounded-[10px] px-[10px]"
          placeholder="Search Products, Customers and Vendors"
        />

        <button onClick={() => handleGlobalSearch()}>
          <IconWrapper>
            <Search className="text-white z-10" />
          </IconWrapper>
        </button>

        <button onClick={() => dispatch(toggleGlobalSearch())}>
          <IconWrapper>
            <Cross className="text-white z-10" />
          </IconWrapper>
        </button>
      </div>

      {/* Global Search Container */}
      <div className="w-full h-[calc(100vh-162px)] flex flex-col xl:flex-row">
        <div className="flex-1 xl:border-r-2 border-b-2 xl:border-b-0 border-neutral-200 overflow-y-scroll">
          <h2 className="text-center py-[10px] border-b-2 border-neutral-200">Products</h2>
          {products.length > 0 && (
            <div className="px-[10px] pt-[10px]">
              <ProductCommonLists data={products} />
            </div>
          )}
        </div>

        <div className="flex-1 xl:border-r-2 border-b-2 xl:border-b-0 border-neutral-200 overflow-y-scroll">
          <h2 className="text-center py-[10px] border-b-2 border-neutral-200">Customers</h2>
          {customers.length > 0 && (
            <div className="px-[10px] pt-[10px]">
              <CustomerCommonLists data={customers} />
            </div>
          )}
        </div>

        <div className="flex-1 border-b-2 xl:border-b-0">
          <h2 className="text-center py-[10px] border-b-2 border-neutral-200 overflow-y-scroll">Vendors</h2>
          {vendors.length > 0 && (
            <div className="px-[10px] pt-[10px]">
              <VendorCommonLists data={vendors} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default GlobalSearch;
