import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchasedSubscriptionPlans, getInvoicePDFforPlanPurchase } from "../../reducers/Slices/userSlice";
import DetailContainer from "../MicroComponents/DetailContainer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { humanDateFormat } from "../../utils/dateFormat";
import toast from "react-hot-toast";
import { http } from "../../service/http";

function CurrentPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Side effect to fetch active plans on first render
  useEffect(() => {
    dispatch(fetchPurchasedSubscriptionPlans());
  }, [dispatch]);

  //User Data
  const userData = useSelector((state) => state.user);

  //Plan labels
  const planLabels = {
    monthly: "Monthly Plan",
    quarterly: "Quarterly Plan",
    annual: "Annual Plan",
    "5-year": "5 Year Plan",
  };

  const activePlans = useSelector((state) => state.user.activePlans);

  // const activePlans = [
  //   {
  //     transaction_id: 1,
  //     order_id: "order_HGfhg567FGH345",
  //     plan: {
  //       id: 1,
  //       name: "monthly",
  //       price_with_gst: "708.00",
  //       gst_percent: "18.00",
  //       duration_days: 30,
  //     },
  //     amount: "708.00",
  //     is_paid: true,
  //     created_at: "2024-08-09T12:34:56Z",
  //     updated_at: "2024-08-09T12:34:56Z",
  //     start: "2024-08-09T12:34:56Z",
  //     end: "2024-09-08T12:34:56Z",
  //   },
  //   {
  //     transaction_id: 2,
  //     order_id: "order_HGkjh879YUG562",
  //     plan: {
  //       id: 2,
  //       name: "quarterly",
  //       price_with_gst: "1770.00",
  //       gst_percent: "18.00",
  //       duration_days: 90,
  //     },
  //     amount: "1770.00",
  //     is_paid: true,
  //     created_at: "2024-08-05T14:22:11Z",
  //     updated_at: "2024-08-05T14:22:11Z",
  //     start: "2024-09-08T12:34:56Z",
  //     end: "2024-12-07T12:34:56Z",
  //   },
  //   {
  //     transaction_id: 3,
  //     order_id: "order_KKjh233GHSD243",
  //     plan: {
  //       id: 3,
  //       name: "annual",
  //       price_with_gst: "6490.00",
  //       gst_percent: "18.00",
  //       duration_days: 365,
  //     },
  //     amount: "6490.00",
  //     is_paid: true,
  //     created_at: "2024-07-10T09:44:23Z",
  //     updated_at: "2024-07-10T09:44:23Z",
  //     start: "2024-12-07T12:34:56Z",
  //     end: "2025-12-07T12:34:56Z",
  //   },
  // ];

  // Extract active plan and next upcoming plan
  const activePlan = activePlans?.[0] || null;
  const nextUpcomingPlan = activePlans?.[1] || null;

  //Handle upgrade plan
  const handleUpgradePlan = () => {
    navigate("/plan_selection");
  };

  const handleDownloadInvoicePDF = async () => {
    if (!activePlan?.id) {
      toast.error("Something went wrong! try again.");
      return;
    }

    console.log(activePlan, "this is the active plan");

    if (activePlan?.id) {
      try {
        const response = await http.get(`/transaction/purchased-subscription/${activePlan?.id}/invoice-pdf`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `theprofit_ai_invoice.png`; // Set the file name as needed
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to trigger the download
        document.body.removeChild(link); // Clean up by removing the link after the download
      } catch (error) {
        toast.error("Some error occurred while downloading plan invoice.");
      }
    }
  };

  //Current plan - plan name, valid from, valid till, view payment details, upcoming plan - plan name - upgrade button
  return (
    <div className="flex flex-col gap-[20px] p-[20px]">
      <div>
        <h1 className="pb-[20px] text-2xl font-semibold">Current Plan</h1>
        {activePlan ? (
          <div className="flex flex-col gap-[10px]">
            <DetailContainer title={"Plan Name:"} value={planLabels[activePlan?.plan?.name]} />
            <DetailContainer
              title={"Validity:"}
              value={`${moment(activePlan.start).format("D MMM YYYY")} - ${moment(activePlan.end).format(
                "D MMM YYYY"
              )}`}
            />
            {nextUpcomingPlan && (
              <DetailContainer title={"Upcoming Plan Name: "} value={planLabels[nextUpcomingPlan?.plan?.name]} />
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-[10px]">
            <DetailContainer title={"Plan Name:"} value={"Free Trial"} />
            {userData?.subscription_expiry && (
              <DetailContainer title={"Plan Expiry:"} value={`${humanDateFormat(userData?.subscription_expiry)}`} />
            )}

            {/* <DetailContainer
              title={"Validity:"}
              value={`${moment(activePlan.start).format("D MMM YYYY")} - ${moment(activePlan.end).format(
                "D MMM YYYY"
              )}`}
            /> */}
            {/* {nextUpcomingPlan && (
              <DetailContainer title={"Upcoming Plan Name: "} value={planLabels[nextUpcomingPlan?.plan?.name]} />
            )} */}
          </div>
        )}
      </div>

      {activePlan && (
        <div>
          <h2 className="pb-[20px] text-2xl font-semibold">Payment Details</h2>

          <div className="flex flex-col gap-[10px]">
            <DetailContainer title={"Plan Price:"} value={`₹${activePlan?.plan?.price_with_gst}`} />
            <DetailContainer title={"Amount Paid:"} value={`₹${activePlan?.amount}`} />
            <DetailContainer title={"Billing Every"} value={`${activePlan?.plan?.duration_days} days`} />
          </div>
        </div>
      )}

      <div className="w-full flex  gap-[10px] justify-center">
        <button
          className="w-fit px-[20px] py-[10px] bg-black hover:bg-stone-800 text-white rounded-full mt-[20px]"
          onClick={() => handleUpgradePlan()}
        >
          {activePlan ? "Upgrade Plan" : "Buy Plan"}
        </button>

        <button
          className="w-fit px-[20px] py-[10px] bg-black hover:bg-stone-800 text-white rounded-full mt-[20px]"
          onClick={() => handleDownloadInvoicePDF()}
        >
          Download Invoice
        </button>
      </div>
    </div>
  );
}

export default CurrentPlan;
